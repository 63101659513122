/* eslint-disable no-array-constructor */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-unused-vars */
import cookie from 'cookie';
import React from 'react';
import lodashGet from 'lodash/get';
import lodashCamelCase from 'lodash/camelCase';
import lodashStartCase from 'lodash/startCase';
import browserStore, { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { DateTime } from '@airtel-tv/utils/DateTime';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import {
    LOCAL_TILE_TYPES, RELATION_TYPES, LAZY_TILE_LIMIT_PORTRAIT, TILES_LIMIT, TILE_LAZY_TILE_LIMIT_TYPE_MAP, TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import LanguageProvider, { getOfflineLanguage, MetaLanguageProvider } from '../providers/LanguageProvider';
import {
    clearBrowserStorage, decodeForUrl, encodeForUrl, redirectToInNewTab, decodeBase64, checkWindowExist,
} from './WindowUtil';
import {
    DOWNLOAD_APP_ACTIONS, PROGRAM_TYPES, PROGRAM_TYPE_TITLE_MAPPING, PROGRAM_TYPES_CATEGORY, PROGRAM_TYPE_CATGEGORY_MAP, ROLE_TYPES,
} from '../constants/AppConst';
import LanguageCodesProvider from '../providers/LanguageCodesProvider';
import {
    downloadAppItunesGaEvent, downloadAppPlayStoreGaEvent, downloadAppMobileHeaderGaEvent,
} from './GaEvents';
import env from '../config';
import { NETWORK_QUALITY } from '../constants/NetworkConst';
import { BROWSER_LIST, OS_LIST } from '../constants/BrowserConst';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
const LANGUAGES_CODES = LanguageCodesProvider(); // Don't use this const as it has the default values present in code

export const replaceAll = (targetString, search, replacement) => targetString.replace(new RegExp(search, 'g'), replacement);

export function stringPadding(value, length = 2, placeholder = 0) {
    if (!value) {
        return value;
    }
    return value.toString().padStart(length, placeholder);
}

export function getDate(date = new Date(), format = 'YYYY-MM-DD HH:mm:ss') {
    // return moment(date).format(format);
    return new DateTime(date).format(format);
}

export const getShowTime = timestamp => new DateTime(timestamp).format('hh:mm a');

export const getTimeStamp = (date = new Date()) => date.getTime();

export const getStartTime = (date = new Date(), timeGap = 0) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(tempDate.getHours() - timeGap);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate.getTime();
};

export const getEndTime = (date = new Date(), timeGap = 1) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(tempDate.getHours() + timeGap);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate.getTime();
};

// Convert seconds to HH:mm:ss for player

function pad(num) {
    return (`0${num}`).slice(-2);
}

export function secondsTohhmmss(secs) {
    const playerTime = new DateTime('2015-01-01');
    playerTime.addUTCSeconds(secs);
    return playerTime.format('H:mm:ss');
}

export function secondsTohhhmmm(secs) {
    const durationHour = Math.floor(secondToHours(secs));
    const durationMin = secondToMinutes(secs);
    if (durationHour === 0 && durationMin === 0) {
        return (secs ? `${secs}s` : '');
    }
    return `${durationHour}h ${durationMin}m`;
}

export function secondToHours(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds / 3600; // eslint-disable-line no-mixed-operators
}

export function secondToMinutes(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds % 3600 / 60; // eslint-disable-line no-mixed-operators
}

export function getFullScreen(docElm) {
    if (!docElm) {
        return;
    }
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    }
    else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
    }
    else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
    }
    else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
    }
}

export const getPageIdFromProps = (match, appConfig) => {
    const {
        params: { pageTitle },
    } = match;
    const { pageIdTitleMap = {} } = appConfig;

    let pageId = null;

    if (pageTitle) {
        pageId = pageIdTitleMap[pageTitle];
    }
    else {
        pageId = appConfig.pages ? appConfig.pages[0].id : null; // first page is default page
    }

    return pageId;
};

export function hrefEscape() {
    return null;
}

export function removeFromArray(arr, target) {
    const targetIndex = arr.indexOf(target);
    arr.splice(targetIndex, 1);
}

export function getSourceName(value) {
    return value.split('/')[1];
}

export function isNumber(value) {
    const n = Number(value);
    return (value || value === 0) && !Number.isNaN(n);
}

export function isValidEmail(email) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line no-useless-escape
    return re.test(String(email).toLowerCase());
}

export function objectToArray(obj) {
    return Array.from(Object.keys(obj), k => obj[k]);
}

export function getSeasonTitle(seasonNum) {
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.SEASON_TITLE.replace(/{seasonNum}/g, seasonNum);
}

export const getMetaDescriptionGenre = ({ genreForMeta = '', actorForMeta = '' }) => {
    if (!genreForMeta || !actorForMeta) {
        return '';
    }
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.META_GENRE_MOVIE_DESCRIPTION.replace('{genres}', genreForMeta)
        .replace('{actors}', actorForMeta);
};

export const getPageSeoMeta = ({
    programType,
    contentTitle,
    contentDescription,
    tvShowDetails = {
        showName: '',
        seasonNumber: '',
        episodeNumber: '',
        episodeName: '',
        channelId: '',
        channelName: '',
        episodeDate: '',
    },
    genreForMeta = '',
    actorForMeta = '',
    releaseYear = '',
}) => {
    const LANGUAGES = LanguageProvider();

    let title = contentTitle;
    let description = contentDescription;
    let keywords = '';

    const {
        showName,
        seasonNumber,
        episodeNumber,
        episodeName,
        channelId,
        channelName,
        episodeDate,
    } = tvShowDetails;

    switch (programType) {
        case PROGRAM_TYPES.LIVETVCHANNEL: {
            title = LANGUAGES.META_TITLE_LIVE
                .replace(/{title}/g, contentTitle);

            description = LANGUAGES.META_LIVE_DESCRIPTION.replace(/{title}/g, contentTitle);

            keywords = LANGUAGES.META_LIVE_KEYWORDS.replace(/{title}/g, contentTitle);
            break;
        }
        case PROGRAM_TYPES.EPISODE:

        // has channel id : yes then it is a live tv episode
        {
            if (!channelId) {
                title = LANGUAGES.META_EPISODE_TITLE
                    .replace(/{showName}/g, showName)
                    .replace(/{seasonNum}/g, seasonNumber)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeName}/g, episodeName);

                description = LANGUAGES.META_EPISODE_DESCRIPTION
                    .replace(/{showName}/g, showName)
                    .replace(/{seasonNum}/g, seasonNumber)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeName}/g, episodeName);

                keywords = LANGUAGES.META_EPISODE_KEYWORDS
                    .replace(/{episodeName}/g, episodeName);
            }
            else {
                title = LANGUAGES.META_CATCHUP_EPISODE_TITLE
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{channelName}/g, channelName)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeDate}/g, episodeDate);


                description = LANGUAGES.META_CATCHUP_EPISODE_DESCRIPTION
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{episodeDate}/g, episodeDate);

                description = LANGUAGES.META_CATCHUP_EPISODE_KEYWORDS
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{episodeDate}/g, episodeDate);
            }


            break;
        }
        case PROGRAM_TYPES.SEASON: {
            title = LANGUAGES.META_SEASON_TITLE
                .replace(/{showName}/g, showName)
                .replace(/{seasonNum}/g, seasonNumber);

            description = LANGUAGES.META_SEASON_DESCRIPTION
                .replace(/{showName}/g, showName)
                .replace(/{seasonNum}/g, seasonNumber);


            break;
        }

        case PROGRAM_TYPES.TVSHOW: {
            title = LANGUAGES.META_TV_SHOW_SEO_TITLE
                .replace(/{showName}/g, showName);

            description = LANGUAGES.META_TV_SHOW_SEO_DESCRIPTION
                .replace(/{showName}/g, showName)
                .replace(/{channelName}/g, channelName);

            keywords = LANGUAGES.META_TV_SHOW_KEYWORDS
                .replace(/{showName}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.LIVETVSHOW: {
            title = LANGUAGES.META_CATCHUP_SHOW_TITLE
                .replace(/{showName}/g, contentTitle)
                .replace(/{channelName}/g, channelName);

            description = LANGUAGES.META_CATCHUP_SHOW_DESCRIPTION
                .replace(/{showName}/g, contentTitle);

            keywords = LANGUAGES.META_CATCHUP_SHOW_KEYWORDS
                .replace(/{showName}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.LIVETVMOVIE: {
            title = LANGUAGES.META_CATCHUP_MOVIE_TITLE
                .replace(/{title}/g, contentTitle)
                .replace(/{channelName}/g, channelName);

            description = LANGUAGES.META_CATCHUP_MOVIE_DESCRPTION
                .replace(/{title}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.MOVIE: {
            const modifiedTitle = releaseYear ? `${contentTitle} ${releaseYear}` : contentTitle;
            title = LANGUAGES.META_MOVIES_SEO_TITLE.replace('{title}', modifiedTitle);
            description = replaceAll(LANGUAGES.META_MOVIES_SEO_DESCRIPTION, '{title}', contentTitle) + getMetaDescriptionGenre({
                genreForMeta,
                actorForMeta,
            });
            keywords = replaceAll(LANGUAGES.META_MOVIE_KEYWORDS, '{title}', contentTitle);
            break;
        }


        default:
            title = LANGUAGES.META_TITLE.replace('{title}', contentTitle).replace(
                '{type}',
                PROGRAM_TYPE_TITLE_MAPPING[programType] || '',
            );
            description = replaceAll(LANGUAGES.META_MOVIES_DESCRIPTION, '{title}', contentTitle);
            keywords = replaceAll(LANGUAGES.META_MOVIE_KEYWORDS, '{title}', contentTitle);
    }

    return {
        title,
        description,
        keywords,
    };
};


export const getDimensionMeta = ({ pageTitle: PROGRAM_TYPE, genres = '', lang = '' }) => {
    const META_LANGUAGES = MetaLanguageProvider();

    const keyObj = {
        [PROGRAM_TYPE]: PROGRAM_TYPE,
        LANGUAGE: lang,
        GENRE: genres,
    };
    const key = Object.keys(keyObj).filter(item => keyObj[item]).join('_');
    const value = `${lang} ${genres}`.trim();
    const title = META_LANGUAGES[`META_DIMENSION_SEO_${key}_TITLE`] ? META_LANGUAGES[`META_DIMENSION_SEO_${key}_TITLE`] : META_LANGUAGES.META_DIMENSION_SEO_TITLE;
    const description = META_LANGUAGES[`META_DIMENSION_SEO_${key}_DESCRIPTION`] ? META_LANGUAGES[`META_DIMENSION_SEO_${key}_DESCRIPTION`] : META_LANGUAGES.META_DIMENSION_SEO_DESCRIPTION;

    return {
        title: replaceAll(title || '', '{dimensionPageMeta}', value) || '',
        description: replaceAll(description || '', '{dimensionPageMeta}', value) || '',
    };
};

export const getDimensionPageContent = ({
    pageTitle: PROGRAM_TYPE, genres = '', lang = '', defaultTitle = '',
}) => {
    const META_LANGUAGES = MetaLanguageProvider();

    const keyObj = {
        [PROGRAM_TYPE]: PROGRAM_TYPE,
        LANGUAGE: lang,
        GENRE: genres,
    };
    const key = Object.keys(keyObj).filter(item => keyObj[item]).join('_');
    const value = `${lang} ${genres}`.trim();

    let content = META_LANGUAGES[`META_DIMENSION_SEO_${key}_CONTENT`] ? META_LANGUAGES[`META_DIMENSION_SEO_${key}_CONTENT`] : META_LANGUAGES.META_DIMENSION_SEO_PAGE_CONTENT;
    content = (content || '')
        .replace(/{dimensionPageMeta}/g, value);
    return content;
};

export const getCDPFooterContent = ({
    programType, actorsForFooter, channelPartnerName, channelUrl, contentTitle, languageMap, genreMap, duration, downloadable, baseUrl,
}) => {
    const title = contentTitle;

    let actorLinks = [];
    let directorLinks = [];
    let languageLinks = [];
    let genreLinks = [];
    actorsForFooter.map((item, index) => {
        if ((item?.roleType || '').toLowerCase() === ROLE_TYPES.DIRECTOR) {
            directorLinks.push(`<a href=${baseUrl}${item.actorUrl}>${item.displayTitle}</a>`);
        }
        else {
            actorLinks.push(`<a href=${baseUrl}${item.actorUrl}>${item.displayTitle}</a>`);
        }
    });
    const popularActorLinks = actorLinks.slice(0, 2).join(', ');
    actorLinks = actorLinks.join(', ');
    directorLinks = directorLinks.join(', ');
    languageMap.map((item) => {
        languageLinks.push(`<a href=${baseUrl}${item.url}>${item.name}</a>`);
    });

    genreMap.map((item) => {
        genreLinks.push(`<a href=${baseUrl}${item.url}>${item.name}</a>`);
    });

    languageLinks = languageLinks.join(', ');
    genreLinks = genreLinks.join(', ');

    const channelLink = `<a href=${channelUrl}>${channelPartnerName}</a>`;
    downloadable = downloadable ? 'Yes' : 'No';

    const META_LANGUAGES = MetaLanguageProvider();

    // const LANGUAGES = {
    //     META_CONTENT_MOVIE_DESCRIPTION: '<div class=\"desc-col\"><b>About {title} Movie</b><br><br><div>At Airtel Xstream, you get a chance to discover millions of new content in various genres & one such movie is {title}. The best thing about streaming movies here is that you can download {title} full movie starring {actorLinks} in HD quality. To ensure that every viewer has the best quality movie experience we continuously shortlist and update HD films in the playlist. So, get your snacks and drinks ready to watch the best movies like {title} movie online on {channelLink} available only on Airtel Xstream.</div><br><br><div>Check out the amazing work of your favorite artists like {popularActorLinks} & many more! In addition, you can also explore movies in different languages like <a href=https://www.airtelxstream.in/movies/tamil/collection?lang=ta&pageTitle=MOVIE>Tamil Movies</a>, <a href=https://www.airtelxstream.in/movies/telugu/collection?lang=te&pageTitle=MOVIE>Telugu Movies</a>, <a href=https://www.airtelxstream.in/movies/english/collection?lang=en&pageTitle=MOVIE>English Movies</a>, and many more only on Airtel Xstream. Also, check out your favourite content on our <a href=https://www.airtelxstream.in/channel>partner channels</a> for exclusive originals, web series, <a href=https://www.airtelxstream.in/tv-shows>TV shows</a>, and short films. Happy Xstreaming!</div></div><div class=\"desc-col\"><br><br>Cast: {actorLinks}<br>Director: {directorLinks}<br>Genre: {genreLinks}<br>Language: {languageLinks}<br>Duration: {duration}<br>Partner Channel: {channelLink}<br>Available for Download: {availableForDownload}<br></div>',
    //     META_CONTENT_TVSHOW_DESCRIPTION: '<div class=\"desc-col\"> <b>About {title} TV Show</b><br><br><div>At Airtel Xstream, you get a chance to discover millions of new content in various genres & one such TV Show is {title}. The best thing about streaming movies here is that you can watch {title} starring {actorLinks} in HD quality. To ensure that every viewer has the best quality movie experience we continuously shortlist and update HD TV Shows in the playlist. So, get your snacks and drinks ready to watch the best TV Shows like {title} TV Show online on {channelLink} available only on Airtel Xstream.</div><br><br><div>Check out the amazing work of your favorite artists like {popularActorLinks} & many more! In addition, you can also explore TV Shows in different languages like <a href=https://www.airtelxstream.in/tv-shows/tamil/collection?lang=ta&pageTitle=TVSHOW>Tamil TV Shows</a>, <a href=https://www.airtelxstream.in/tv-shows/telugu/collection?lang=te&pageTitle=TVSHOW>Telugu TV Shows</a>, <a href=https://www.airtelxstream.in/tv-shows/english/collection?lang=en&pageTitle=TVSHOW>English TV Shows</a>, and many more only on Airtel Xstream. Also, check out your favourite content on our <a href=https://www.airtelxstream.in/channel>partner channels</a> for exclusive originals, web series, <a href=https://www.airtelxstream.in/movies>Movies</a>, and short films. Happy Xstreaming!</div></div><div class=\"desc-col\"><br><br>Cast: {actorLinks}<br>Director: {directorLinks}<br>Genre: {genreLinks}<br>Language: {languageLinks}<br>Duration: {duration}<br>Partner Channel: {channelLink}<br>Available for Download: {availableForDownload}<br></div></div>'
    // }
    // const language = {
    //     META_CONTENT_MOVIE_DESCRIPTION: "<div id=\"id_cp_footer\" data-string-html=\"{stringHtml}\"><b>About {title} Movie</b><br/><br/><div>At Airtel Xstream, you get a chance to discover millions of new content in various genres & one such movie is {title}. The best thing about streaming movies here is that you can download {title} full movie starring {actorLinks} in HD quality. To ensure that every viewer has the best quality movie experience we continuously shortlist and update HD films in the playlist. So, get your snacks and drinks ready to watch the best movies like {title} movie online on {channelLink} available only on Airtel Xstream.</div><br/><br/><div>Check out the amazing work of your favorite artists like {popularActorLinks} & many more! In addition, you can also explore movies in different languages like <a href=https://www.airtelxstream.in/movies/tamil/collection?lang=ta&pageTitle=MOVIE>Tamil Movies</a>, <a href=https://www.airtelxstream.in/movies/telugu/collection?lang=te&pageTitle=MOVIE>Telugu Movies</a>, <a href=https://www.airtelxstream.in/movies/english/collection?lang=en&pageTitle=MOVIE>English Movies</a>, and many more only on Airtel Xstream. Also, check out your favourite content on our <a href=https://www.airtelxstream.in/channel>partner channels</a> for exclusive originals, web series, <a href=https://www.airtelxstream.in/tv-shows>TV shows</a>, and short films. Happy Xstreaming!</div><br/><br/>Cast: {actorLinks}<br/>Director: {directorLinks}<br/>Genre; {genreLinks}<br/>Language: {languageLinks}<br/>Duration: {duration}<br/>Partner Channel: {channelLink}<br/>Available for Download: {availableForDownload}<br/></div>",

    //     //'<div><b>About {title} Movie</b><br/><br/><div>At Airtel Xstream, you get a chance to discover millions of new content in various genres & one such movie is {title}. The best thing about streaming movies here is that you can download {title} full movie starring {actorLinks} in HD quality. To ensure that every viewer has the best quality movie experience we continuously shortlist and update HD films in the playlist. So, get your snacks and drinks ready to watch the best movies like {title} movie online on {channelLink} available only on Airtel Xstream.</div><br/><br/><div>Check out the amazing work of your favorite artists like {popularActorLinks} & many more! In addition, you can also explore movies in different languages like <a href=\'https://www.airtelxstream.in/movies/tamil/collection?lang=ta&pageTitle=MOVIE\'>Tamil Movies</a>, <a href=https://www.airtelxstream.in/movies/telugu/collection?lang=te&pageTitle=MOVIE>Telugu Movies</a>, <a href=https://www.airtelxstream.in/movies/english/collection?lang=en&pageTitle=MOVIE>English Movies</a>, and many more only on Airtel Xstream. Also, check out your favourite content on our <a href=https://www.airtelxstream.in/channel>partner channels</a> for exclusive originals, web series, <a href=https://www.airtelxstream.in/tv-shows>TV shows</a>, and short films. Happy Xstreaming!</div><br/><br/>Cast: {actorLinks}<br/>Director: {directorLinks}<br/>Genre; {genreLinks}<br/>Language: {languageLinks}<br/>Duration: {duration}<br/>Partner Channel: {channelLink}<br/>Available for Download: {availableForDownload}<br/></div>',
    //     META_CONTENT_TVSHOW_DESCRIPTION: "<div id=\"id_cp_footer\"><b>About {title} TV Show</b><br/><br/><div>At Airtel Xstream, you get a chance to discover millions of new content in various genres & one such TV Show is {title}. The best thing about streaming movies here is that you can watch {title} starring {actorLinks} in HD quality. To ensure that every viewer has the best quality movie experience we continuously shortlist and update HD TV Shows in the playlist. So, get your snacks and drinks ready to watch the best TV Shows like {title} TV Show online on {channelLink} available only on Airtel Xstream.</div><br/><br/><div>Check out the amazing work of your favorite artists like {popularActorLinks} & many more! In addition, you can also explore TV Shows in different languages like <a href=https://www.airtelxstream.in/tv-shows/tamil/collection?lang=ta&pageTitle=TVSHOW>Tamil TV Shows</a>, <a href=https://www.airtelxstream.in/tv-shows/telugu/collection?lang=te&pageTitle=TVSHOW>Telugu TV Shows</a>, <a href=https://www.airtelxstream.in/tv-shows/english/collection?lang=en&pageTitle=TVSHOW>English TV Shows</a>, and many more only on Airtel Xstream. Also, check out your favourite content on our <a href=https://www.airtelxstream.in/channel>partner channels</a> for exclusive originals, web series, <a href=https://www.airtelxstream.in/movies>Movies</a>, and short films. Happy Xstreaming!</div><br/><br/>Cast: {actorLinks}<br/>Director: {directorLinks}<br/>Genre; {genreLinks}<br/>Language: {languageLinks}<br/>Duration: {duration}<br/>Partner Channel: {channelLink}<br/>Available for Download: {availableForDownload}<br/></div>"
    //     //'<div><b>About {title} TV Show</b><br/><br/><div>At Airtel Xstream, you get a chance to discover millions of new content in various genres & one such TV Show is {title}. The best thing about streaming movies here is that you can watch {title} starring {actorLinks} in HD quality. To ensure that every viewer has the best quality movie experience we continuously shortlist and update HD TV Shows in the playlist. So, get your snacks and drinks ready to watch the best TV Shows like {title} TV Show online on {channelLink} available only on Airtel Xstream.</div><br/><br/><div>Check out the amazing work of your favorite artists like {popularActorLinks} & many more! In addition, you can also explore TV Shows in different languages like <a href=https://www.airtelxstream.in/tv-shows/tamil/collection?lang=ta&pageTitle=TVSHOW>Tamil TV Shows</a>, <a href=https://www.airtelxstream.in/tv-shows/telugu/collection?lang=te&pageTitle=TVSHOW>Telugu TV Shows</a>, <a href=https://www.airtelxstream.in/tv-shows/english/collection?lang=en&pageTitle=TVSHOW>English TV Shows</a>, and many more only on Airtel Xstream. Also, check out your favourite content on our <a href=https://www.airtelxstream.in/channel>partner channels</a> for exclusive originals, web series, <a href=https://www.airtelxstream.in/movies>Movies</a>, and short films. Happy Xstreaming!</div><br/><br/>Cast: {actorLinks}<br/>Director: {directorLinks}<br/>Genre; {genreLinks}<br/>Language: {languageLinks}<br/>Duration: {duration}<br/>Partner Channel: {channelLink}<br/>Available for Download: {availableForDownload}<br/></div>',
    // };
    let content = META_LANGUAGES[`META_CONTENT_${programType}_DESCRIPTION`] || '';

    const contentStringMap = {
        actorLinks: actorLinks ? /{actorLinks}/g : /Cast: {actorLinks}<br>/g,
        genreLinks: genreLinks ? /{genreLinks}/g : /Genre: {genreLinks}<br>/g,
        languageLinks: languageLinks ? /{languageLinks}/g : /Language: {languageLinks}<br>/g,
        duration: duration ? /{duration}/g : /Duration: {duration}<br>/g,
        channelLink: channelLink ? /{channelLink}/g : /Partner Channel: {channelLink}<br>/,
        availableForDownload: downloadable ? /{availableForDownload}/g : /Available for Download: {availableForDownload}<br>/,
        directorLinks: directorLinks ? /{directorLinks}/ : /Director: {directorLinks}<br>/,
    };

    content = content
        .replace(/{title}/g, title)
        .replace(/{popularActorLinks}/g, popularActorLinks)
        .replace(contentStringMap.actorLinks, actorLinks)
        .replace(contentStringMap.directorLinks, directorLinks)
        .replace(contentStringMap.channelLink, channelLink)
        .replace(contentStringMap.availableForDownload, downloadable)
        .replace(contentStringMap.duration, duration)
        .replace(contentStringMap.languageLinks, languageLinks)
        .replace(contentStringMap.genreLinks, genreLinks);
    return content;
};

// export const getMetaTitleArtist = (artistTitle = '') => {
//     if (artistTitle !== '') {
//         const LANGUAGES = LanguageProvider();
//         return LANGUAGES.META_TITLE_ARTIST.replace('{title}', artistTitle);
//     }
//     return null;
// };

// export const getMetaDescriptionArtist = (artistTitle = '') => {
//     if (artistTitle !== '') {
//         const LANGUAGES = LanguageProvider();
//         return LANGUAGES.META_DESCRIPTION_ARTIST.replace(/{title}/g, artistTitle);
//     }
//     return null;
// };

export const getMetaTitleArtist = (artistTitle = '', onlyMovies = false) => {
    if (artistTitle !== '') {
        const META_LANGUAGES = MetaLanguageProvider();
        const key = onlyMovies ? 'META_SEO_TITLE_ARTIST_MOVIES' : 'META_SEO_TITLE_ARTIST';
        return replaceAll(META_LANGUAGES[key] || '', '{title}', artistTitle);
    }
    return null;
};

export const getMetaDescriptionArtist = (artistTitle = '', onlyMovies = false) => {
    if (artistTitle !== '') {
        const META_LANGUAGES = MetaLanguageProvider();
        const key = onlyMovies ? 'META_SEO_DESCRIPTION_ARTIST_MOVIES' : 'META_SEO_DESCRIPTION_ARTIST';
        return replaceAll(META_LANGUAGES[key] || '', '{title}', artistTitle);
    }
    return null;
};

export const getArtistFooterContent = (artistTitle = '', onlyMovies = false) => {
    if (artistTitle !== '') {
        const META_LANGUAGES = MetaLanguageProvider();
        const key = onlyMovies ? 'META_SEO_CONTENT_ARTIST_MOVIES' : 'META_SEO_CONTENT_ARTIST';
        return replaceAll(META_LANGUAGES[key] || '', '{title}', artistTitle);
    }
    return null;
};

export const getPlaceholderFromLocal = (key) => {
    const OFFLINELANGUAGES = getOfflineLanguage();
    return OFFLINELANGUAGES[key];
};

export const getLatestChromeBrowser = () => {
    const LANGUAGES = LanguageProvider();
    const OFFLINELANGUAGES = getOfflineLanguage();
    const chromeVersion = LANGUAGES.CHROME_LATEST_VERSION ? LANGUAGES.CHROME_LATEST_VERSION : OFFLINELANGUAGES.CHROME_LATEST_VERSION;
    return +chromeVersion;
};

export const getMetaLayout = (title = '') => {
    const LANGUAGES = LanguageProvider();
    if (title !== '') {
        return LANGUAGES.META_LAYOUT_PAGE.replace('{title}', title);
    }
    return LANGUAGES.META_DEFAULT_PAGE;
};

export const getMetaTitleSchedulePage = (title = '') => {
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.META_SCHEDULE_TITLE.replace('{title}', title);
};

export const getMetaDescriptionSchedulePage = (title = '') => {
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.META_SCHEDULE_DESCRIPTION.replace('{title}', title);
};

export const removeConsecutiveRepeatedChar = (target, char) => target.filter((item, pos, arr) => pos === 0 || (arr[pos - 1] !== char && char) || item !== arr[pos - 1]).join('');

export const trimCaptionsForUrl = (targetString = '', separator = '-', defaultString = 'default') => {
    const specialCharacters = [
        '!',
        '"',
        '#',
        '$',
        ' % ',
        ' & ',
        "'",
        '(',
        ')',
        '*',
        '+',
        ',',
        '\\-',
        '.',
        '/',
        ':',
        ';',
        '<',
        '=',
        '>',
        '?',
        '@',
        '\\[',
        '\\]',
        '^',
        '_',
        '`',
        '{',
        '|',
        '}',
        '~',
        "'",
        '–',
    ];

    const regexEscapeCharacters = new RegExp(`[${specialCharacters.join('|')}]`);

    let encoded = encodeForUrl(
        removeConsecutiveRepeatedChar(
            targetString
                .trim()
                .toLowerCase()
                // .split((/\.|\/| |\(|\)|:|,|!|+|,|'|<|>|#|%|{|}|^|~|[|]|$|_|(|)|;|?|@|=|/))
                .split(regexEscapeCharacters) // src: https://stackoverflow.com/a/25266512/4221433
                .join(separator)
                .split(''),
            separator,
        ),
    ) || defaultString;
    if (encoded[encoded.length - 1] === separator) {
        encoded = encoded.slice(0, encoded.length - 1); // remove last '-'
    }
    if (encoded[0] === separator) {
        encoded = encoded.slice(1, encoded.length); // remove first '-'
    }
    return encoded;
};

export const encodeContentId = (contentId) => {
    if (!contentId) {
        return '';
    }

    if (contentId[0] === contentId[0].toLowerCase()) {
        return contentId; // already lowercase
    }

    const splittedContentId = contentId.split('_');

    if (splittedContentId.length < 2) {
        return contentId;
    }

    splittedContentId[0] = splittedContentId[0].toLowerCase();
    splittedContentId[1] = splittedContentId[1].toLowerCase();

    return (
        splittedContentId
            .join('_')
            // replace first 2 _ with -
            .replace('_', '-') // replace first underscore to '-'
            .replace('_', '-')
    ); // replace first underscore to '-'
};

export const decodeContentId = (contentId) => {
    if (!contentId) {
        return '';
    }

    if (contentId[0] === contentId[0].toUpperCase()) {
        return contentId; // already uppercase
    }

    const splittedContentId = contentId.split('_');

    if (splittedContentId.length < 1) {
        return contentId;
    }

    const splittedEncodeContentId = splittedContentId[0].split('-');

    if (splittedEncodeContentId.length < 2) {
        return contentId;
    }

    splittedEncodeContentId[0] = splittedEncodeContentId[0].toUpperCase();
    splittedEncodeContentId[1] = splittedEncodeContentId[1].toUpperCase();
    splittedContentId[0] = splittedEncodeContentId.join('_');
    const result = splittedContentId.join('_');
    return result;
};

export const urlStringToCaption = str => decodeForUrl(replaceAll(str, '-', ' '));

export const convertToNumber = (str) => {
    const num = Number(str);
    return Number.isNaN(num) ? 0 : num;
};

export const clearAllBrowserStorage = () => {
    clearBrowserStorage();
    browserStore.clearAll();
};

export const toTitleCase = str => lodashStartCase(lodashCamelCase(str));

export const firstLetterUpper = (str) => {
    if (!str || str.length < 1) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getWeek = (airDate) => {
    const date = new Date(airDate);
    return date.toString().split(' ')[0];
};

export const getDayMonth = (airDate) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const date = new Date(airDate);
    return `${date.getDate()} ${monthNames[date.getMonth()]}`;
};

export const handlePasteNumericData = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const clipboardText = clipboardData.getData('Text');

    const pattern = new RegExp(/^\d+$/);
    const isTextNumber = pattern.test(clipboardText);

    if (!isTextNumber) {
        e.preventDefault();
    }
};

export const cookieStringToObject = cookieString => cookie.parse(cookieString);

export const convertToJSON = (value) => {
    try {
        return JSON.parse(value);
    }
    catch (error) {
        return value;
    }
};

export const decryptSecretKey = ({ secretKey, authToken }) => {
    if (!secretKey || !authToken) {
        return null;
    }


    const decryptionKey = authToken.slice(0, 16);

    const decryptedKey = CryptoUtil.AESDecrypt({
        key: decryptionKey,
        value: secretKey,
    });

    return decryptedKey;
};


export const isItemObject = item => (typeof (item) === 'object');

export const onInstallClick = ({ clickItem, isPlayerError = false, appConfig }) => {
    // const isMobile = DeviceUtil.isMobile();
    const refLinks = lodashGet(appConfig, 'refLinks', {});
    const sharableContent = lodashGet(appConfig, 'sharableContent', {});
    switch (clickItem) {
        case DOWNLOAD_APP_ACTIONS.COMMON_APP_DOWNLOAD:
            // if (isMobile) {
            //     redirectTo(SHARABLE_CONTENT.DOWNLOAD_APP_DEEP_LINK);
            // }
            //  else {
            redirectToInNewTab(sharableContent.DOWNLOAD_APP_DEEP_LINK);
            //  }

            if (!isPlayerError) {
                downloadAppMobileHeaderGaEvent();
            }

            // if (isPlayerError) {
            //     downloadAppPlayBackErrorGaEvent();
            // }
            // else {
            //     downloadAppMobileHeaderGaEvent();
            // }
            break;

        case DOWNLOAD_APP_ACTIONS.DOWNLOAD_APP_STORE:
            // if (isMobile) {
            //     redirectTo(REF_LINKS.ITUNES_LINK);
            // }
            // else {
            redirectToInNewTab(refLinks.ITUNES_LINK);
            // }

            downloadAppItunesGaEvent();
            break;

        case DOWNLOAD_APP_ACTIONS.DOWNLOAD_PLAY_STORE:
            redirectToInNewTab(refLinks.PLAY_STORE_LINK);
            downloadAppPlayStoreGaEvent();
            break;
        case DOWNLOAD_APP_ACTIONS.CP_UNSUPPORTED_REDIRECT:
            redirectToInNewTab(sharableContent.CP_APP_REDIRECT_DEEP_LINK);
            break;
        default:
    }
};


export const findSelectedCP = (subscribedPlans, cpId, subscriptionType, planID) => {
    if (subscriptionType) {
        return subscribedPlans.find(plan => plan.cpId.startsWith(cpId) || cpId.startsWith(plan.cpId));
    }
    return subscribedPlans.find(plan => (plan.cpId.startsWith(cpId) || cpId.startsWith(plan.cpId)) && plan.plan.planId === parseInt(planID));
};
export const filterChannelAndRecharge = (subscribedPlans) => {
    const plans = {
        false: [],
        true: [],
    };
    subscribedPlans.forEach((plan) => {
        if (plan.meta && plan.meta.offerType === 'PREMIUM') {
            return;
        }
        if (plan.meta && plan.meta.offerType === 'CLAIM_MOBILEPACK') {
            plans.true.push(plan);
        }
        else {
            plans.false.push(plan);
        }
    });
    return plans;
};

export const filterPlans = (subscribedPlans) => {
    const plans = {
        false: [],
        true: [],
    };
    subscribedPlans.forEach((plan) => {
        if (plan.meta && plan.meta.offerType === 'CLAIM_MOBILEPACK') {
            plans.true.push(plan);
        }
        else {
            plans.false.push(plan);
        }
    });
    return plans;
};

export const isObjEmpty = (obj) => {
    let isEmpty = false;
    const type = typeof obj;

    isEmpty = isEmpty || !obj;
    isEmpty = isEmpty || (type === 'undefined'); // if it is undefined
    isEmpty = isEmpty || (obj === null); // if it is null
    isEmpty = isEmpty || (type === 'string' && (obj === '')); // if the string is empty
    isEmpty = isEmpty || (obj === false || obj === 0); // if boolean value returns false
    isEmpty = isEmpty || (Array.isArray(obj) && obj.length === 0); // if array is empty
    isEmpty = isEmpty || (type === 'object' && Object.keys(obj).length === 0); // if object is empty

    return isEmpty;
};

export const isStringEmpty = str => (!str || str.length === 0);
export const disableScrollOnWheel = (e) => {
    if (e && e.target) {
        e.target.blur();
    }
};


export const getCurrentProgram = (programs, time = getTimeStamp()) => {
    const program = programs.find(item => item.startTime <= time && item.endTime >= time);

    return program || null;
};

export const getCurrentProgramIndex = (programs, time = getTimeStamp()) => {
    const programIndex = programs.findIndex(item => item.startTime <= time && item.endTime >= time);

    return programIndex !== -1 ? programIndex : null;
};

export const createMockEPG = (epgList, epgStartTime, epgEndTime) => {
    const finalEpglist = [];

    let currentStartTime = epgStartTime;
    if (epgList.length) {
        currentStartTime = epgList[0].startTime < currentStartTime ? epgList[0].startTime : currentStartTime;
    }


    for (let index = 0; index < epgList.length;) {
        const { startTime, endTime } = epgList[index];

        const result = { ...epgList[index] };

        if (currentStartTime < startTime) {
            result.startTime = currentStartTime;
            result.endTime = startTime;
            result.mocked = true;
        }
        else {
            result.startTime = startTime;
            result.endTime = endTime;
            index += 1;
        }

        currentStartTime = result.endTime;

        finalEpglist.push(result);
    }

    if (
        finalEpglist
        && finalEpglist.length >= 1
        && finalEpglist[finalEpglist.length - 1].endTime < epgEndTime
    ) {
        finalEpglist.push({
            mocked: true,
            startTime: finalEpglist[finalEpglist.length - 1].endTime,
            endTime: epgEndTime,
        });
    }
    else if (finalEpglist.length < 1) {
        finalEpglist.push({
            mocked: true,
            startTime: epgStartTime,
            endTime: epgEndTime,
        });
    }

    return finalEpglist;
};

export const getTitleByProgramType = (contentDetails) => {
    if (contentDetails.programType) {
        switch (contentDetails.programType) {
            case PROGRAM_TYPES.EPISODE:
                return {
                    title: contentDetails.tvShowName || '',
                    episodeTitle: contentDetails.title || '',
                };
            case PROGRAM_TYPES.PROGRAM:
                return {
                    episodeTitle: contentDetails.tvChannelName || '',
                    title: contentDetails.title || '',
                };

            default:
                return {
                    title: contentDetails.title || '',
                    episodeTitle: null,
                };
        }
    }
    return null;
};


export const truncateWithEllipses = (text = '', max = 0) => text.slice(0, max - 1) + (text.length > max ? '...' : '');

export const parseLiveTvCooke = (cookieString) => {
    if (cookieString) {
        const cookies = cookieStringToObject(cookieString);
        const playbackUrlCookies = cookies;
        let cloudFrontPolicy = cookies['CloudFront-Policy'];
        if (cloudFrontPolicy) {
            cloudFrontPolicy = replaceAll(cloudFrontPolicy, '_', '=');

            const decodedCloudFrontPolicy = decodeBase64(cloudFrontPolicy);
            const cloudFrontPolicyJSON = convertToJSON(decodedCloudFrontPolicy);
            const cookieExpire = lodashGet(cloudFrontPolicyJSON, 'Statement[0].Condition.DateLessThan.AWS:EpochTime', Infinity);

            return {
                cookieExpire,
                playbackUrlCookies,
            };
        }
    }

    return {
        cookieExpire: null,
        playbackUrlCookies: null,
    };
};

export const getTileFromRelationType = (relationType) => {
    switch (relationType) {
        case RELATION_TYPES.PEOPLE:
            return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
        case RELATION_TYPES.TVSHOW:
            return LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
        default:
            return LOCAL_TILE_TYPES.PORTRAIT_LARGE;
    }
};

export const getTileFromProgramType = (programType, relationType, pageId = '', webPageId = null) => {
    switch (programType) {
        case PROGRAM_TYPES.TVSHOW:
            switch (relationType) {
                case RELATION_TYPES.PEOPLE:
                    if (webPageId && pageId === webPageId.ARTIST_DETAIL) {
                        return TILE_TYPES.RELATED_CONTENT_RAIL;
                    }
                    return LOCAL_TILE_TYPES.LANDSCAPE_CARD_TILE_GRID;
                default:
                    return LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
            }

        default:
            switch (relationType) {
                case RELATION_TYPES.PEOPLE:
                    if (webPageId && pageId === webPageId.ARTIST_DETAIL) {
                        return TILE_TYPES.RELATED_CONTENT_RAIL;
                    }
                    return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
                default:
                    return LOCAL_TILE_TYPES.PORTRAIT_LARGE;
            }
    }
};


export function getClosestElement(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector
            || Element.prototype.mozMatchesSelector
            || Element.prototype.msMatchesSelector
            || Element.prototype.oMatchesSelector
            || Element.prototype.webkitMatchesSelector
            || function matcheSelector(s) {
                // eslint-disable-next-line react/no-this-in-sfc
                const matches = (this.document || this.ownerDocument).querySelectorAll(
                    s,
                );

                let i = matches.length - 1;

                while (i >= 0 && matches.item(i) !== this) {
                    i -= 1;
                }
                return i > -1;
            };
    }

    // Get the closest matching element
    let elemProp = elem;
    for (elemProp; elemProp && elemProp !== document; elemProp = elemProp.parentNode) {
        if (elemProp.matches(selector)) {
            return elemProp;
        }
    }
    return null;
}

export function removeFalsy(obj) {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== '') {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
}

export const getHostFromUrl = (url = '') => {
    const arr = url.split('/');
    if (arr.length > 2) {
        return `${arr[0]}//${arr[2]}`;
    }
    return null;
};

/**
 * Get a random integer between `min` and `max`.
 *
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);


export const percentCalculation = (total, value) => parseFloat((parseFloat(total) * parseFloat(value)) / 100);

export const subtractSmallFromBig = (n, n2) => {
    if (n > n2) {
        return n - n2;
    }

    return n2 - n;
};

export const getUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0; // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-bitwise, no-mixed-operators
    return v.toString(16);
});

export const getAppVersion = () => {
    const { maj, min, patch } = env.appVersion;
    return `${maj}.${min}.${patch}`;
};

export const getBuildNumber = () => env.buildNumber;

export const getNetworkQuality = () => NETWORK_QUALITY.AWFUL;

export const getDeviceType = () => env.deviceType;

export const getAppId = () => env.appId;

export const getLangfromCode = ({ lang = '' }) => {
    const languageCodes = LanguageCodesProvider();
    return languageCodes[lang];
};


export function getSid(sid, response) {
    const redirectionUrl = lodashGet(response, 'data.redirectionUrl', '');
    const lodashSid = lodashGet(response, 'data.sid', '');
    return sid || lodashSid || (redirectionUrl && redirectionUrl.split('payment-option/')[1].split('/')[0]);
}

export const isStandalone = () => {
    if (checkWindowExist()) {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
    return false;
};

export const calculateProgressPercentage = ({
    continueWatchingData,
    id,
}) => {
    let progressPercentage = 0;
    if (continueWatchingData && continueWatchingData[id]) {
        const { contentResponse: { durSec }, lastWatchedPosition } = continueWatchingData[id];

        if (lastWatchedPosition && durSec) {
            progressPercentage = (lastWatchedPosition / durSec) * 100;
        }
    }

    return progressPercentage;
};

export const findTileLimit = ({
    windowWidth,
    tileType,
}) => {
    const tileMap = TILE_LAZY_TILE_LIMIT_TYPE_MAP[tileType] || LAZY_TILE_LIMIT_PORTRAIT;
    const widthKey = Object.keys(tileMap);
    for (let i = 0; i < widthKey.length; i++) {
        const mapWidth = widthKey[i];
        if (mapWidth > windowWidth) {
            return tileMap[mapWidth];
        }
    }

    return TILES_LIMIT;
};

export const inDoubleDigits = (str) => {
    const padString = '0';
    const length = 2;
    let newStr = '';
    while (str.length < length) {
        newStr = padString + str;
        return newStr;
    }
    return str;
};

export const tvShowTitle = (contentDetails) => {
    const LANGUAGE = LanguageProvider();
    const { tvShowName, title } = contentDetails;
    const seasonId = lodashGet(contentDetails, 'seasonId', '');
    let episodeTitle = '';
    if (seasonId) {
        const { episodeNum, episodeSeasonNum } = contentDetails;
        episodeTitle = (episodeNum && episodeSeasonNum) ? `S${inDoubleDigits(episodeSeasonNum.toString())}E${inDoubleDigits(episodeNum.toString())}` : '';
    }
    else {
        const { airDate } = contentDetails;
        episodeTitle = new DateTime(airDate).format('DD MMMM');
    }

    return tvShowName ? LANGUAGE.CONTINUE_WATCHING_TITLE
        .replace(/{tvShowName}/g, tvShowName)
        .replace(/{episodeDetails}/g, episodeTitle) : title;
};

export const continueWatchingTileTitle = (contentDetails) => {
    const { programType, title } = contentDetails;
    if (programType !== PROGRAM_TYPES.EPISODE) {
        return title;
    }

    switch (programType) {
        case PROGRAM_TYPES.EPISODE:
            return tvShowTitle(contentDetails);
        default:
            return title;
    }
};

export const isArrayofObjectsEqual = (a, b) => {
    if (a === b) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; ++i) {
        if (a[i].key !== b[i].key) {
            return false;
        }
    }
    return true;
};

export const packageIdForListingPage = id => (id ? `${id}LISTING` : '');

export const stringifyInfinityValuesObject = (key, val) => {
    if (val === Infinity) {
        return 'Infinity';
    }
    if (val === -Infinity) {
        return '-Infinity';
    }
    return val;
};

export const parseInfinityValuesObject = (key, val) => {
    if (val === 'Infinity') {
        return Infinity;
    }
    if (val === '-Infinity') {
        return -Infinity;
    }
    return val;
};

export const getTileType = ({
    pageTitle, explicitTileType, cpId = null, preferredArtwork,
}) => {
    // web specific

    let tileType = null;
    if (preferredArtwork) {
        switch (preferredArtwork) {
            case CONTENT_IMAGE_TYPES.PORTRAIT:
            case CONTENT_IMAGE_TYPES.PORTRAIT_HD:
                tileType = LOCAL_TILE_TYPES.PORTRAIT_SMALL;
                break;
            case CONTENT_IMAGE_TYPES.LANDSCAPE:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_43:
                tileType = LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
                break;
            default:
                tileType = LOCAL_TILE_TYPES.PORTRAIT_LARGE;
        }
        return tileType;
    }
    switch (pageTitle) {
        case PROGRAM_TYPES.MOVIE:
        case PROGRAM_TYPES.LIVETVMOVIE:
            tileType = LOCAL_TILE_TYPES.PORTRAIT_LARGE;
            break;
        case PROGRAM_TYPES.LIVETVCHANNEL:
            tileType = LOCAL_TILE_TYPES.CHANNEL_CARD_TILE_GRID;
            break;
        case PROGRAM_TYPES.LIVETVSHOW:
        case PROGRAM_TYPES.TVSHOW:
        case PROGRAM_TYPES.SEASON:
        case PROGRAM_TYPES.EPISODE:
        case PROGRAM_TYPES.VIDEO:
        case PROGRAM_TYPES.TRAILER:
            tileType = LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
            break;
        case PROGRAM_TYPES.PACKAGE:
            // for distinguishing between explore by rail and partner channel rail
            if (explicitTileType === TILE_TYPES.CONTENT_PARTNER_RAIL || cpId) {
                tileType = LOCAL_TILE_TYPES.CHANNEL_PARTNER_RAIL;
            }
            else {
                tileType = LOCAL_TILE_TYPES.CUSTOM_EXPLORE;
            }
            break;
        case LOCAL_TILE_TYPES.LOCAL_TILE:
            tileType = LOCAL_TILE_TYPES.LOCAL_TILE;
            break;
        default:
            tileType = LOCAL_TILE_TYPES.PORTRAIT_LARGE;
    }
    return tileType;
};

// Find language rn from languageFilters key in appConfig
export const findLanguageFromCode = (code, languageFilters) => {
    const key = Object.keys(languageFilters).find(lan => lan === code);
    return key && languageFilters[key].rn ? languageFilters[key].rn : null;
};

export const getContentType = ({ contentDetails }) => {
    const { programType, channelId } = contentDetails;
    if (channelId && programType === PROGRAM_TYPES.EPISODE) {
        return PROGRAM_TYPES_CATEGORY.CATCHUP;
    }
    return PROGRAM_TYPE_CATGEGORY_MAP[programType];
};


export const gtag_report_conversion = ({ conversionData = '', url }) => {
    // web specific
    if (env.env === 'production') {
        const callback = function () {
            if (typeof (url) !== 'undefined') {
                window.location = url;
            }
        };
        window.gtag('event', 'conversion', {
            send_to: conversionData,
            event_callback: callback,
        });
        return false;
    }
};

export const generateCaptcha = (captchaLength) => {
    const alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
    let code = '';
    for (let i = 0; i < captchaLength; i++) {
        code += alpha[Math.floor(Math.random() * alpha.length)];
    }
    return code;
};
export function filterSubscribedPlans(subscribedPlans) {
    if (!subscribedPlans) {
        return [];
    }
    const filteredPlans = subscribedPlans.filter(plan => shouldDisplay(plan) && !plan.notVisible);
    return filteredPlans || [];
}

export const getXStreamDetail = subscribedPlans => (subscribedPlans || []).find(plans => plans.meta && plans.meta.offerType === 'PREMIUM');


export function filterXtreamPremium(subscribedPlans) {
    if (!subscribedPlans) {
        return [];
    }
    const xStreamPremiumPlan = subscribedPlans.filter(plan => plan.meta && plan.meta.offerType === 'PREMIUM' && !plan.notVisible);
    return xStreamPremiumPlan || [];
}
export const getCpDetailFromConfig = (cpDetails, cpId) => cpDetails.find(cp => cp.cpId === cpId);

const shouldDisplay = (plan) => {
    if (
        plan.meta
      && typeof plan.meta.displayUnderSubscribedPack !== 'undefined'
      && plan.meta.displayUnderSubscribedPack === false
    ) {
        return false;
    }
    return true;
};


const nth = function (d) {
    if (d > 3 && d < 21) {
        return 'th';
    }
    switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

export const formatType = {
    LONG: 'long',
    SHORT: 'short',
};
export const getDateWithTitle = (timestamp, format) => {
    const fortnightAway = new Date(timestamp);
    const date = fortnightAway.getDate();
    const month = fortnightAway.toLocaleString('en', { month: format });
    return `${date}${nth(date)} ${month} ${fortnightAway.getFullYear()}`;
};


export const characterToUpperCase = (text) => {
    const arr = text.split(' ');
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    const str2 = arr.join(' ');
    return str2;
};

export const getPrefferedPartnerList = (prefferedPartnerPlan) => {
    const list = (prefferedPartnerPlan.length > 0 && prefferedPartnerPlan[0] && prefferedPartnerPlan[0].offers) || [];
    const prefferedPartnerPlanList = list.length > 0 ? list.map(a => a.cp) : [];
    return prefferedPartnerPlanList;
};

export const getAudioLanguages = ({ languageMap, contentDetails: { languages } }, openPopUp) => {
    const langArray = [];
    languageMap.forEach((lang) => {
        if (languages && languages.includes(lang.lan.toLowerCase())) {
            langArray.push(lang.n);
        }
    });
    return (
        <>
            { langArray.slice(0, 3).map((lang, i) => (
                <>
                    <span>{lang}</span>
                    {langArray.length > 1 && i >= 0 && i < 2 && (
                        <span>
                            ,
                            {' '}
                        </span>
                    )}
                </>
            )) }
            {langArray.length > 3 && (
                <span
                    className="extra-lang"
                    onClick={openPopUp}
                >
                    {' '}
                    +
                    {langArray.length - 3}
                    {' '}
                    more
                </span>
            )}
        </>
    );
};

export const getRouteKey = value => Object.keys(ROUTE_PATHS).find(key => ROUTE_PATHS[key] === value);

export const getUniqueId = () => {
    const random = getRandomInt(0, 100000000);
    return `${random}-${new Date().getTime()}`;
};

export const getPlaySessionId = () => {
    const uniqueId = getUniqueId();
    const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
    const { userSessionId = '' } = analyticsMeta;
    // const userSessionId = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_USER_SESSION_ID);
    return `${userSessionId}-${uniqueId}`;
};

export const setPlaySessionId = (playSessionId) => {
    let analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
    analyticsMeta = {
        ...analyticsMeta,
        analyticsPlaySessionId: playSessionId,
    };
    sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_META, analyticsMeta);
};

export const getAnalyticsSessionMeta = contentId => ({
    contentId,
    userSessionId: getUniqueId(),
});

export const setAnalyticsMeta = (contentId) => {
    const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
    let meta;
    if (!analyticsMeta) {
        meta = getAnalyticsSessionMeta(contentId);
        sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_META, meta);
    }
    else {
        const { contentId: sessionContentId } = analyticsMeta;
        meta = analyticsMeta;
        if (sessionContentId !== contentId) {
            meta = getAnalyticsSessionMeta(contentId);
            sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_META, meta);
        }
    }
    return meta;
};


export function getDeviceResolution() {
    if (checkWindowExist()) {
        return {
            width: window.screen.width,
            height: window.screen.height,
            resolution: `${window.screen.width}x${window.screen.height}`,
        };
    }
    return {};
}

export const getPortraitorLandscape = () => {
    const mq = window.matchMedia('(orientation: portrait)');
    return mq.matches ? 'portrait' : 'landscape';
};

export const getTrailerPlaybackDetails = (trailerRefId, trailerUrl) => ({
    [`${trailerRefId}`]: {
        playback: {
            playUrl: trailerUrl,
        },
        contentId: trailerRefId,
        contentType: 'TRAILER',
    },
});

export const convertRailTitle = title => title && title.toLowerCase().replace(/\s/gi, '-') || '';

export const showScrollButtons = (totalTileWidth) => {
    // web specific

    if (!checkWindowExist()) {
        return;
    }
    const { availWidth } = window && window.screen;
    return (availWidth - 160) / totalTileWidth < 1;
};

export const isPlatformSupported = (browser, os) => {
    // web specific
    if (browser === BROWSER_LIST.SAFARI || os === OS_LIST.IOS) {
        return false;
    }
    return true;
};


export default {
    isStandalone,
    getTimeStamp,
    percentCalculation,
    getRandomInt,
    removeFalsy,
    getClosestElement,
    parseLiveTvCooke,
    getTrailerPlaybackDetails,
    convertToJSON,
    toTitleCase,
    stringPadding,
    secondToHours,
    secondToMinutes,
    hrefEscape,
    removeFromArray,
    isValidEmail,
    getSourceName,
    objectToArray,
    pad,
    getMetaTitleArtist,
    getMetaDescriptionArtist,
    getMetaLayout,
    removeConsecutiveRepeatedChar,
    trimCaptionsForUrl,
    replaceAll,
    urlStringToCaption,
    convertToNumber,
    clearAllBrowserStorage,
    handlePasteNumericData,
    decryptSecretKey,
    getWeek,
    getDayMonth,
    isItemObject,
    onInstallClick,
    isObjEmpty,
    disableScrollOnWheel,
    getCurrentProgram,
    createMockEPG,
    getTitleByProgramType,
    truncateWithEllipses,
    getTileFromRelationType,
    getPageSeoMeta,
    encodeContentId,
    decodeContentId,
    getHostFromUrl,
    getUuid,
    getAppVersion,
    getBuildNumber,
    getNetworkQuality,
    getDeviceType,
    getAppId,
    calculateProgressPercentage,
    continueWatchingTileTitle,
    isArrayofObjectsEqual,
    findTileLimit,
    getMetaDescriptionGenre,
    findLanguageFromCode,
    packageIdForListingPage,
    getTileType,
    stringifyInfinityValuesObject,
    parseInfinityValuesObject,
    firstLetterUpper,
    getLangfromCode,
    getMetaTitleSchedulePage,
    getMetaDescriptionSchedulePage,
    getContentType,
    gtag_report_conversion,
    generateCaptcha,
    getPrefferedPartnerList,
    getAudioLanguages,
    getRouteKey,
    convertRailTitle,
    showScrollButtons,
};

import lodashGet from 'lodash/get';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { FocusManagementKeyAction, FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { publish } from './Pubsub';
import {
    PUBLISH_EVENTS,
} from '../constants/AppConst';

const {
    ACTIVE_SIDEBAR,
} = FocusManagementClassNames;

let SpatialNavigation = {};
if (checkWindowExist()) {
    SpatialNavigation = window.SpatialNavigation;
}
export const uninitFocus = () => {
    SpatialNavigation?.uninit();
};
export const initFocus = () => {
    SpatialNavigation?.init();
};
export const initializeFocus = (config) => {
// Define navigable elements (anchors and elements with "focusable" class).

    // SpatialNavigation?.init();
    SpatialNavigation?.add(config);

    // Make the *currently existing* navigable elements focusable.
    SpatialNavigation?.makeFocusable();

    // Focus the first navigable element.
    SpatialNavigation?.focus();
};

export const setConfig = (sectionId, config) => {
    SpatialNavigation?.set(sectionId, config);
};

export const focusSelector = (selector = '', options = {}) => {
    const {
        focusByReact = false,
        targetByClass = false,
        silentFocus = false,
    } = options || {};
    if (selector) {
        if (focusByReact) {
            publish(PUBLISH_EVENTS.SET_FOCUS_ELEMENT, {
                selector,
                targetByClass,
            });
        }
        else {
            SpatialNavigation?.focus(selector, silentFocus);
        }
    }
};

export const moveFocus = (direction = 'right', selector = '') => SpatialNavigation?.focus(direction, selector);

export const removeSelector = (selector) => {
    SpatialNavigation?.remove(selector);
};

export const disableFocus = (selector) => {
    SpatialNavigation?.disable(selector);
};

export const intitializeAppFocus = () => {
    SpatialNavigation?.focus();
};

export const addSelector = (config) => {
    SpatialNavigation?.add(config);
    // Make the *currently existing* navigable elements focusable.
    SpatialNavigation?.makeFocusable();
};
export const isSideBarFocused = () => lodashGet(document, 'activeElement.classList', []).contains('nav-link');

export const isElementFocused = () => lodashGet(document, 'activeElement.classList', []);

export const moveSelector = (direction, selector) => {
    SpatialNavigation?.move(direction, selector);
};

export const getTileFocusProps = (props) => {
    const {
        tilePosition = 0,
        railPosition = 0,
        tilesLength = 0,
        isList = false,
        scrollPositionHorizontal = null,
        scrollParentHorizontal = false,
        scrollToWindowTopLeft = true,
        tileId = '#tile',
        horizontalSrollSafeOffset = 0,
    } = props;
    let {
        dataSnLeft = null,
        dataSnRight = null,
    } = props;
    const previousSibling = isList ? undefined : '.nav-link.active';
    const nextTile = `${tileId}-${railPosition}-${tilePosition + 1}`;
    const previousTile = `${tileId}-${railPosition}-${tilePosition - 1}`;

    if (!dataSnLeft) {
        dataSnLeft = tilePosition === 0 ? previousSibling : previousTile;
    }
    if (!dataSnRight) {
        dataSnRight = tilePosition !== tilesLength - 1 ? nextTile : `${FocusManagementKeyAction.NO_TARGET}`;
    }

    return {
        isList,
        scrollintoview: true,
        scrollPositionHorizontal: scrollPositionHorizontal || isList ? 'nearest' : 'center',
        scrollParentHorizontal,
        scrollToWindowTopLeft: isList ? false : scrollToWindowTopLeft,
        tilePosition,
        railPosition,
        'data-sn-left': dataSnLeft,
        'data-sn-right': dataSnRight,
        horizontalSrollSafeOffset,
    };
};

export const SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION = {
    CURRENT: 'CURRENT',
    PARTNER_CHANNEL: 'PARTNER_CHANNEL',
};

export const EVENT_NAME = {
    SUBSCRIPTION_COMPLETE: 'PURCHASE',
    CLAIM_SUCCESS: 'SPEND_CREDITS',
};

export const BRANCH_IO_MIN_VERSION = {
    ANDROID: '1.62.0',
};

export const CHANNEL_PARTNER = {
    XSTREAMPREMIUM: 'XSTREAMPREMIUM',
};

export default {
    SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION,
    BRANCH_IO_MIN_VERSION,
    CHANNEL_PARTNER,
};

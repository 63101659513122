import lodashGet from 'lodash/get';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import {
    SCREEN_ORENTATIONS, MOBILE_WIDTH, MOBILE_LANDSCAPE_WIDTH, MOBILE_LANDSCAPE_HEIGHT, TABLET_LARGE_WIDTH,
} from '../constants/AppConst';
import { BROWSER_LIST } from '../constants/BrowserConst';
import { getUniqueId } from '@airtel-tv/utils/GlobalUtil';

export const checkWindowExist = () => {
    if (typeof window !== 'undefined') {
        return true;
    }
    return false;
};

export const isOnline = () => {
    if (checkWindowExist()) {
        return window.navigator.onLine;
    }
    return true;
};

export const isNativeShareAvailable = () => {
    if (checkWindowExist()) {
        return window.navigator.share !== undefined;
    }
    return false;
};

export const sharedClick = (e, title, shareText, url) => {
    e.preventDefault();
    if (checkWindowExist()) {
        if (isNativeShareAvailable()) {
            window.navigator.share({
                title,
                text: shareText,
                url,
            }).catch(error => console.error('Error sharing', error));
        }
    }
};

export function decodeBase64(value) {
    if (checkWindowExist()) {
        try {
            return window.atob(value);
        }
        catch (e) {
            return null;
        }
    }
    return null;
}

export function base64ToArrayBuffer(base64) {
    if (checkWindowExist()) {
        if (window && window.atob) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i += 1) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        }
    }

    return null;
}

export function stringToBase64(str) {
    if (checkWindowExist()) {
        if (window && window.btoa) {
            return window.btoa(String.fromCharCode.apply(null, new Uint8Array(str)));
        }
    }
    return null;
}

export function arrayBufferToBase64(buffer) {
    if (checkWindowExist()) {
        if (window && window.btoa) {
            return btoa(String.fromCharCode.apply(null, buffer));
        }
    }
    return null;
}

// eslint-disable-next-line prefer-spread
export const stringToUint8Array = str => Array.apply(null, new Uint8Array(str));

export const hasEMESupport = () => {
    if (checkWindowExist()) {
        if (window) {
            const eme = 'MediaKeys' in window || 'WebKitMediaKeys' in window || 'MSMediaKeys' in window;
            if (eme) {
                return true;
            }
            return false;
        }
    }
    return false;
};

export const screenOrientationToLandscape = () => {
    if (checkWindowExist()) {
        if (window) {
            if (!window.screen) {
                return false;
            }

            const orientation = window.screen.orientation || window.screen.msLockOrientation || window.screen.mozLockOrientation;

            if (orientation) {
                orientation.lock(SCREEN_ORENTATIONS.LANDSCAPE)
                    .then(() => true)
                    .catch((e) => {
                        console.debug(e);
                    });
            }
            return false;
        }
    }
    return false;
};

export const screenOrientationToNeutral = () => {
    if (checkWindowExist()) {
        if (window) {
            if (!window.screen) {
                return false;
            }

            const orientation = window.screen.orientation || window.screen.msLockOrientation || window.screen.mozLockOrientation;

            if (orientation) {
                return orientation.unlock();
            }
            return false;
        }
    }
    return false;
};

// document object
export function checkFullScreen() {
    if (checkWindowExist() && window) {
        return window.document.isFullScreen
            || window.document.fullscreenElement
            || window.document.webkitFullscreenElement
            || window.document.mozFullScreenElement
            || window.document.msFullscreenElement;
    }

    return false;
}

export function exitFullScreen() {
    if (checkWindowExist() && window) {
        if (window.document.exitFullscreen) {
            window.document.exitFullscreen();
        }
        else if (window.document.mozCancelFullScreen) { /* Firefox */
            window.document.mozCancelFullScreen();
        }
        else if (window.document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            window.document.webkitExitFullscreen();
        }
        else if (window.document.msExitFullscreen) { /* IE/Edge */
            window.document.msExitFullscreen();
        }
    }
    return false;
}

export function inputFocusFontManager(inputs) {
    if (checkWindowExist() && window) {
        if (inputs) {
            const elements = Array.from(Object.keys(inputs), k => inputs[k]);

            for (let index = 0; index < elements.length; index++) {
                const element = elements[index];
                if (element === window.document.activeElement) {
                    element.style.fontSize = '22px';
                }
                else {
                    const textLength = element.value.length;
                    if (textLength < 1) {
                        element.style.fontSize = '18px';
                    }
                }
            }
        }
    }
    return false;
}

export function raiseEvent(element, eventName, eventData) {
    if (checkWindowExist() && window) {
        let event; // The custom event that will be created

        if (window.document.createEvent) {
            event = window.document.createEvent('HTMLEvents');
            event.initEvent(eventName, false, false);
        }
        else {
            event = window.document.createEventObject();
            event.eventType = eventName;
        }

        event.eventName = eventName;
        event.data = eventData;

        if (window.document.createEvent) {
            element.dispatchEvent(event);
        }
        else {
            element.fireEvent(`on${event.eventType}`, eventData);
        }
    }
    return false;
}

// export const copyToClipboard = (url) => {
//     if (root) {
//         const textField = root.document.createElement('textarea');
//         textField.innerText = url;
//         root.document.body.appendChild(textField);
//         textField.select();
//         root.document.execCommand('copy');
//         textField.remove();
//     }
//     return false;
// };

export const getWindowInnerWidth = () => ((checkWindowExist() && window) ? window.innerWidth : 0);

export const mediaForMobile = () => getWindowInnerWidth() <= MOBILE_WIDTH
    || (getWindowInnerWidth() <= MOBILE_LANDSCAPE_WIDTH
        && window.innerHeight <= MOBILE_LANDSCAPE_HEIGHT);

export const mediaForMaxTabScreen = () => getWindowInnerWidth() <= TABLET_LARGE_WIDTH;

export const copyToClipboard = (url) => {
    if (checkWindowExist() && window) {
        let textField;
        try {
            textField = window.document.createElement('textarea');
            textField.setAttribute('readonly', true);
            textField.setAttribute('contenteditable', true);
            textField.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
            textField.value = url;
            textField.innerText = url;
            window.document.body.appendChild(textField);
            textField.focus();
            textField.select();

            const range = document.createRange();
            range.selectNodeContents(textField);

            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            textField.setSelectionRange(0, textField.value.length);
            window.document.execCommand('copy');
        }
        finally {
            textField.remove();
        }
    }
    return false;
};

export const getLocationPathNameWithQuery = () => {
    if (checkWindowExist() && window) {
        return window.location.pathname + window.location.search;
    }
    return '';
};

export const getElementById = (elementId) => {
    if (checkWindowExist() && window) {
        return window.document.getElementById(elementId);
    }
    return false;
};

export const getElementsByClassName = (node) => {
    if (checkWindowExist() && window) {
        return window.document.getElementsByClassName(node);
    }
    return false;
};

export const getElementByTagName = (name) => {
    if (checkWindowExist() && window) {
        return window.document.getElementsByTagName(name);
    }
    return false;
};

export const retry = () => {
    if (checkWindowExist() && window) {
        // not clearing cache
        // CacheUtil.clearAllCache();

        window.location.reload();
    }
    return false;
};

export const getEvent = () => {
    if (checkWindowExist() && window) {
        return window.event;
    }
    return false;
};

export const openPopupWindow = (url, title) => {
    if (checkWindowExist() && window) {
        const w = 520;
        const h = 520;
        const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
        return window.open(encodeURI(url), title, `location=yes, status=yes,scrollbars=yes, width=${w}, height=${h}, top=${y}, left=${x}`);
    }
    return false;
};

export const getCurrentUrl = () => {
    if (checkWindowExist() && window) {
        return window.document.URL;
    }
    return false;
};

export const openTab = (url, blank = '') => {
    if (checkWindowExist() && window) {
        if (blank === '_blank') {
            return window.open(url, blank);
        }
        return window.open(url);
    }
    return false;
};

export const getInnerHeight = () => {
    if (checkWindowExist()) {
        return window.innerHeight;
    }
    return null;
};

export const addListenerToEvent = (eventName, callback) => {
    if (checkWindowExist() && window) {
        return window.addEventListener(eventName, callback);
    }
    return false;
};

export const removeListenerFromEvent = (eventName, callback) => {
    if (checkWindowExist() && window) {
        return window.removeEventListener(eventName, callback);
    }
    return false;
};

export const documentAddEventListener = (eventName, callback, bool) => {
    if (checkWindowExist() && window) {
        return window.document.addEventListener(eventName, callback, bool);
    }
    return false;
};

export const documentRemoveEventListener = (eventName, callback, bool) => {
    if (checkWindowExist() && window) {
        return window.document.removeEventListener(eventName, callback, bool);
    }
    return false;
};

export const pictureInPictureEnabled = () => {
    if (checkWindowExist() && window) {
        return window.document.pictureInPictureEnabled;
    }
    return false;
};

export const exitPictureInPicture = () => {
    if (checkWindowExist() && window) {
        return window.document.exitPictureInPicture();
    }
    return false;
};

export const checkIfPlayInPip = (videoTag) => {
    if (checkWindowExist() && window) {
        // console.log(window.document.getElementsByTagName('video')[0].webkitPresentationMode);
        let isPipPlaying = !!window.document.pictureInPictureElement; // for chrome
        if (!isPipPlaying && videoTag) {
            isPipPlaying = videoTag.webkitPresentationMode === 'picture-in-picture'; // for safari
        }

        return isPipPlaying;
    }

    return false;
};

export const closePip = async (browser, videoTag) => {
    switch (browser) {
        case BROWSER_LIST.CHROME:
            try {
                await exitPictureInPicture();
            }
            catch (error) {
                console.error(error);
            }
            break;

        case BROWSER_LIST.SAFARI:
            if (videoTag && (typeof videoTag.webkitSetPresentationMode === 'function')) {
                // eslint-disable-next-line no-param-reassign
                videoTag.webkitSetPresentationMode('inline');
            }
            break;
        default:
    }
};

export const checkDocumentHidden = () => {
    if (checkWindowExist() && window.document) {
        return window.document.hidden;
    }
    return false;
};

export const getWindowLocation = () => {
    if (checkWindowExist() && window) {
        return window.location;
    }
    return false;
};

export const getWindowNavigator = () => {
    if (checkWindowExist() && window) {
        return window.navigator;
    }
    return false;
};

export const hasWindow = () => {
    if (checkWindowExist() && window) {
        return typeof window === 'object';
    }
    return false;
};

export const hasReduxDeveloperTools = () => {
    if (checkWindowExist() && window) {
        return !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
    return false;
};

export const getReduxDeveloperTools = () => {
    if (checkWindowExist() && window) {
        return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
    return null;
};

export const isDocumentExists = () => {
    if (checkWindowExist() && window) {
        return !!window.document;
    }
    return false;
};

export const getDocument = () => {
    if (checkWindowExist() && window) {
        return window.document;
    }
    return null;
};

export const getDocumentBody = () => {
    if (checkWindowExist() && window) {
        return window.document.body;
    }
    return false;
};

export const sendMail = (subject = '', body = '', to = '') => `mailto:${to}?subject=${subject}&body=${body}`;

export const scrollToTop = () => {
    if (checkWindowExist()) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'auto',
        });
    }
};

export const redirectTo = (url, sendEvent) => {
    if (checkWindowExist() && window) {
        if (sendEvent) {
            sendEvent(url);
        }
        window.location.href = url;
    }
};

export const openUrlInSameTab = (url, sendEvent) => {
    if (checkWindowExist() && window) {
        if (sendEvent) {
            sendEvent(url);
        }
        window.open(url, '_self');
    }
};

export const redirectToInNewTab = (url) => {
    if (checkWindowExist() && window) {
        window.open(url, '_blank');
    }
};

export const clearBrowserStorage = () => {
    if (checkWindowExist() && window) {
        window.localStorage.clear();
        window.sessionStorage.clear();
    }
};

/**
 * returns Current root Source Name
 */
export const getCurrentWindowSourceName = () => {
    if (checkWindowExist() && window) {
        return window.location.href;
    }
    return '';
};

export const encodeForUrl = (value) => {
    if (checkWindowExist() && window.encodeURIComponent) {
        return window.encodeURIComponent(value);
    }
    return value;
};

export const decodeForUrl = (value) => {
    if (checkWindowExist() && window && window.decodeURIComponent) {
        try {
            return window.decodeURIComponent(value);
        }
        catch (error) {
            // console.error('decode uri component', value, error);
        }
    }
    return value;
};

/**
 * Removes hover effects for Mobile
 */
export const removeMobileHover = () => {
    if (checkWindowExist() && window) {
        try { // prevent exception on browsers not supporting DOM styleSheets properly
            window.document.styleSheets.forEach((si) => {
                const styleSheet = window.document.styleSheets[si];
                if (styleSheet.rules) {
                    for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (styleSheet.rules[ri].selectorText) {
                            if (styleSheet.rules[ri].selectorText.match(':hover')) {
                                styleSheet.deleteRule(ri);
                            }
                        }
                    }
                }
            });
        }
        catch (ex) {
            return null;
        }
    }
    return null;
};

export const checkScreenHasTouch = () => {
    if (checkWindowExist() && window) {
        try {
            const { ontouchstart } = window.document.documentElement;
            const { maxTouchPoints, msMaxTouchPoints } = window.navigator;
            return ontouchstart || maxTouchPoints > 0 || msMaxTouchPoints > 0;
        }
        catch (error) {
            return false;
        }
    }
    return false;
};

export const getLocationPathName = () => {
    if (!window) {
        return '';
    }
    return window.location.pathname;
};

export function getWindowValue(path) {
    if (checkWindowExist()) {
        return lodashGet(window, path);
    }
    return null;
}

export const shareWhatsApp = (whatsAppUrl) => {
    if (checkWindowExist() && window) {
        window.location = whatsAppUrl;
    }
    return false;
};

export const createIntersectionObserverApi = (callback, delay, threshold, rootMargin = '0px') => {
    if (checkWindowExist()) {
        return new IntersectionObserver(callback, {
            root: null,
            delay,
            rootMargin,
            threshold,
        });
    }
    return null;
};

export function removeChildElements(elementId) {
    if (checkWindowExist()) {
        if (window.document.createRange) {
            // removing all children
            const range = document.createRange();
            range.selectNodeContents(document.getElementById(elementId));
            range.deleteContents();
        }
    }
}
export function checkAndAddScript(src, type = 'script') {
/**
 * check if script or link doesn't exist then add to html
 * @param {string} type =['script', 'link'].
 */
    if (checkWindowExist()) {
        const tags = window.document.getElementsByTagName(type);
        const tagsrc = type === 'script' ? 'src' : 'href';
        for (let i = tags.length; i--;) {
            if (tags[i][tagsrc] === src) {
                return null;
            }
        }
        const htmlElement = window.document.createElement(type);
        if (type === 'link') {
            htmlElement.rel = 'stylesheet';
        }
        else {
            htmlElement.async = true;
        }
        htmlElement[tagsrc] = src;
        document.body.appendChild(htmlElement);
    }
    return null;
}

export function loadGA() {
    if (checkWindowExist()) {
        const script = document.createElement('script');
        const Initscript = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-934825417';
        script.async = true;
        window.document.body.appendChild(script);

        Initscript.text = 'window.dataLayer = window.dataLayer || []; '
        + 'function gtag(){dataLayer.push(arguments);}'
        + "gtag('js', new Date());"
        + "gtag('config', 'AW-934825417');";

        window.document.body.appendChild(Initscript);
    }
}


export default {
    getLocationPathName,
    redirectToInNewTab,
    base64ToArrayBuffer,
    stringToBase64,
    arrayBufferToBase64,
    hasEMESupport,
    screenOrientationToLandscape,
    screenOrientationToNeutral,
    checkFullScreen,
    exitFullScreen,
    inputFocusFontManager,
    raiseEvent,
    copyToClipboard,
    getLocationPathNameWithQuery,
    getElementById,
    getElementsByClassName,
    retry,
    getEvent,
    openPopupWindow,
    shareWhatsApp,
    getCurrentUrl,
    openTab,
    addListenerToEvent,
    removeListenerFromEvent,
    documentAddEventListener,
    pictureInPictureEnabled,
    exitPictureInPicture,
    getWindowLocation,
    checkWindowExist,
    getWindowNavigator,
    hasWindow,
    hasReduxDeveloperTools,
    getReduxDeveloperTools,
    isDocumentExists,
    getDocumentBody,
    sendMail,
    getDocument,
    isOnline,
    scrollToTop,
    redirectTo,
    getCurrentWindowSourceName,
    encodeForUrl,
    decodeForUrl,
    removeMobileHover,
    checkScreenHasTouch,
    getWindowInnerWidth,
    documentRemoveEventListener,
    getWindowValue,
    decodeBase64,
    getInnerHeight,
    createIntersectionObserverApi,
    removeChildElements,
    mediaForMobile,
    mediaForMaxTabScreen,
    stringToUint8Array,
    getElementByTagName,
    checkIfPlayInPip,
    checkAndAddScript,
};

import ReactGA from 'react-ga4';


// export const userVisitGaEvent = visitCount => ReactGA.event({
//     category: 'user_visit',
//     action: 'visit_count',
//     label: `${visitCount}`,
// });

// export const authPopupDisplayGaEvent = ({ programTypeTitle }) => ReactGA.event({
//     category: 'login',
//     action: 'popup_view',
//     label: programTypeTitle,
// });

// export const generateOtpGaEvent = ({ programTypeTitle }) => ReactGA.event({
//     category: 'login',
//     action: 'enter number',
//     label: programTypeTitle,
// });

// export const validateOtpGaEvent = ({ programTypeTitle }) => ReactGA.event({
//     category: 'login',
//     action: ' enter OTP ',
//     label: programTypeTitle,
// });

// export const successfulLoginCountGaEvent = loginCount => ReactGA.event({
//     category: 'login',
//     action: 'login_count',
//     label: `${loginCount}`,
// });

// export const downloadAppPlayBackErrorGaEvent = () => ReactGA.event({
//     category: 'download_app',
//     action: 'Error',
//     label: 'Playback Error',
// });

// export const playGaEvent = contentId => ReactGA.event({
//     category: 'player',
//     action: 'play',
//     label: contentId,
// });

// export const pauseGaEvent = contentId => ReactGA.event({
//     category: 'player',
//     action: 'pause',
//     label: contentId,
// });

// export const playDragSeekBarGaEvent = contentId => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: `drag_seekbar-${contentId}`,
// });

// export const playForwardBackwardGaEvent = contentId => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: `+30/-30sec-${contentId}`,
// });

// export const fullScreenToggleGaEvent = contentId => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: `click on fullscreen button-${contentId}`,
// });

// export const muteUnmuteGaEvent = () => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: 'muteUnmute',
// });

// export const changeVolumeLevelGaEvent = () => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: 'muteUnmute',
// });

// export const playerSettingButtonClickGaEvent = () => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: 'Click on settings button',
// });

// export const playbackQualityButtonClickGaEvent = () => ReactGA.event({
//     category: 'player',
//     action: 'control_bar',
//     label: 'Click on quality button',
// });

// // export const selectPlaybackQualityGaEvent = () => ReactGA.event({
// //     category: 'player',
// //     action: 'control_bar',
// //     label: 'Select any playback quality',
// // });

// export const railCtaClickGaEvent = ctaText => ReactGA.event({
//     category: 'related',
//     action: 'rail-view-more-click',
//     label: `${ctaText}`,
// });

// export const clickedOnViewMoreDescriptionGaEvent = clickText => ReactGA.event({
//     category: 'description_pane',
//     action: 'click',
//     label: clickText,
// });

// export const clickedOnShareGaEvent = () => ReactGA.event({
//     category: 'description_pane',
//     action: 'click',
//     label: 'click On Share',
// });

// export const clickedOnCastGaEvent = () => ReactGA.event({
//     category: 'description_pane',
//     action: 'click',
//     label: 'click cast',
// });

// export const footerClickGaEvents = clickedText => ReactGA.event({
//     category: 'footer',
//     action: 'footer_click',
//     label: `${clickedText}_click`,
// });

// export const primaryNavClickGaEvents = clickedItem => ReactGA.event({
//     category: 'primary_nav',
//     action: 'icon_click',
//     label: `${clickedItem}_click`,
// });

// export const recentSearchClickGaEvent = query => ReactGA.event({
//     category: 'search',
//     action: 'recent_search_click',
//     label: `${query}_click`,
// });

// export const searchQuerySubmitGaEvent = query => ReactGA.event({
//     category: 'search',
//     action: 'search_submit_click',
//     label: `${query}_submit`,
// });

// export const tileClickGaEvent = id => ReactGA.event({
//     category: 'content_tiles',
//     action: 'tile_click',
//     label: `${id}_clicked`,
// });

// export const appLaunchGaEvent = isStandalone => ReactGA.event({
//     category: 'app',
//     action: 'app_launch',
//     label: isStandalone ? 'pwa' : 'browser',
// });

export const successfulLoginGaEvent = ({ programTypeTitle }) => ReactGA.event({
    category: 'login',
    action: 'login_success',
    label: programTypeTitle,
});

export const downloadAppMobileHeaderGaEvent = () => ReactGA.event({
    category: 'download_app',
    action: 'Header',
    label: 'If page is mobile',
});

export const downloadAppItunesGaEvent = () => ReactGA.event({
    category: 'download_app',
    action: 'header/footer/itunes',
    label: 'clicked app store download button',
});

export const downloadAppPlayStoreGaEvent = () => ReactGA.event({
    category: 'download_app',
    action: 'header/footer/playstore',
    label: 'clicked play store download button',
});

export const playStartGaEvent = contentId => ReactGA.event({
    category: 'player',
    action: 'play_start',
    label: contentId,
});

export const playbackErrorGaEvent = ({ errorcode }) => ReactGA.event({
    category: 'player',
    action: 'playback_error',
    label: `${errorcode}`,
});

export const contentNotFoundGaEvent = ({ contentId, programTypeTitle }) => ReactGA.event({
    category: '404',
    action: programTypeTitle,
    label: contentId,
});

export const contentOtherGaEvent = ({ contentId, programTypeTitle }) => ReactGA.event({
    category: 'other',
    action: programTypeTitle,
    label: contentId,
});

export const offerLandingGaEvent = ({ phoneNumber = 'nonloggedin'}) => ReactGA.event({
    category: phoneNumber,
    action: 'Offers_Landing',
    phoneNumber,
    label: phoneNumber,
});


export const offersClaimClickGaEvent = ({ phoneNumber = 'nonloggedin' }) => ReactGA.event({
    category: phoneNumber,
    action: 'Offers_Claim_Click',
    phoneNumber,
    label: phoneNumber,
});


export const offerOtpGaEvent = ({ phoneNumber = 'nonloggedin' }) => ReactGA.event({
    category: phoneNumber,
    action: 'OffersOtpPopup',
    phoneNumber,
    label: phoneNumber,
});

export const offerClaimPopUpVisibleGaEvent = ({ phoneNumber = 'nonloggedin', offerType }) => ReactGA.event({
    category: offerType,
    action: 'OfferClaimPopUpVisible',
    label: phoneNumber,
    phoneNumber,
});

export const offerClaimPopUpClickGaEvent = ({ phoneNumber = 'nonloggedin', offerType }) => ReactGA.event({
    category: offerType,
    action: 'OfferClaimPopUpClick',
    label: phoneNumber,
    phoneNumber,
});

export default {
    downloadAppMobileHeaderGaEvent,
    successfulLoginGaEvent,
    playbackErrorGaEvent,
    playStartGaEvent,
    downloadAppItunesGaEvent,
    downloadAppPlayStoreGaEvent,
    contentNotFoundGaEvent,
    contentOtherGaEvent,
    offerLandingGaEvent,
    offersClaimClickGaEvent,
    offerOtpGaEvent,
    offerClaimPopUpVisibleGaEvent,
    offerClaimPopUpClickGaEvent,
    // appLaunchGaEvent,
    // generateOtpGaEvent,
    // validateOtpGaEvent,
    // playGaEvent,
    // pauseGaEvent,
    // fullScreenToggleGaEvent,
    // muteUnmuteGaEvent,
    // playerSettingButtonClickGaEvent,
    // playbackQualityButtonClickGaEvent,
    // clickedOnViewMoreDescriptionGaEvent,
    // clickedOnShareGaEvent,
    // clickedOnCastGaEvent,
    // railCtaClickGaEvent,
    // footerClickGaEvents,
    // primaryNavClickGaEvents,
    // recentSearchClickGaEvent,
    // searchQuerySubmitGaEvent,
    // tileClickGaEvent,
    // downloadAppPlayBackErrorGaEvent,
    // authPopupDisplayGaEvent,
};

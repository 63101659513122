import { useCallback } from 'react';

const ArrowRefCallbackUtil = (callBackFn) => {
    const setRef = useCallback((node) => {
        function handleArrows() {
            if (node) {
                setRef.current = node;
                callBackFn(setRef);
            }
        }
        handleArrows();
    }, [
        callBackFn,
    ]);

    return [
        setRef,
    ];
};

export default ArrowRefCallbackUtil;

import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '../constants/AppConst';


class UserInfoUtil {
    static getUserVisitCount() {
        return browserStore.get(LOCAL_STORE_KEYS.VISIT_COUNT) || 0;
    }

    static setUserVisitCount({ visitCount = 1 }) {
        browserStore.set(LOCAL_STORE_KEYS.VISIT_COUNT, visitCount);
    }

    static getSuccessfulLoginCount() {
        return browserStore.get(LOCAL_STORE_KEYS.LOGIN_COUNT) || 0;
    }

    static setSuccessfulLoginCount({ loginCount = 1 }) {
        browserStore.set(LOCAL_STORE_KEYS.LOGIN_COUNT, loginCount);
    }

    static getUserLanguage() {
        return browserStore.get(LOCAL_STORE_KEYS.USER_LANG);
    }

    static setUserLanguage(lang = []) {
        browserStore.set(LOCAL_STORE_KEYS.USER_LANG, lang);
    }
}


export default UserInfoUtil;

import React, { lazy, Suspense } from 'react';
import Loadable from 'react-loadable';
// import LoaderComponent from '../../modules/shared/components/loader/LoaderComponent';


const AsyncContinueWatchingRailContainer = Loadable({
    loader: () => import('../../modules/rail/containers/ContinueWatchingRailContainer' /* webpackChunkName: "AsyncContinueWatchingRailContainer" */),
    loading: () => <div/>,
});

const AsyncWatchlistRailContainer = Loadable({
    loader: () => import('../../modules/rail/containers/WatchlistRailContainer' /* webpackChunkName: "AsyncWatchlistRailContainer" */),
    loading: () => <div/>,
});

const AsyncFooterComponentRail = Loadable({
    loader: () => import('../../modules/rail/components/FooterComponentRail' /* webpackChunkName: "AsyncFooterComponentRail" */),
    loading: () => <div/>,
});

const AsyncGridRoundSectionRailComponent = Loadable({
    loader: () => import('../../modules/rail/components/GridRoundSectionRailComponent' /* webpackChunkName: "AsyncGridRoundSectionRailComponent" */),
    loading: () => <div/>,
});

const AsyncNormalRailComponent = Loadable({
    loader: () => import('../../modules/rail/components/NormalRailComponent' /* webpackChunkName: "AsyncNormalRailComponent" */),
    loading: () => <div/>,
});

const AsyncAddSectionRailComponent = Loadable({
    loader: () => import('../../modules/rail/components/AddSectionRailComponent' /* webpackChunkName: "AsyncAddSectionRailComponent" */),
    loading: () => <div/>,
});

export const LazyContinueWatchingRailContainer = props => (
        <AsyncContinueWatchingRailContainer {...props} />
);

export const LazyWatchlistRailContainer = props => (
        <AsyncWatchlistRailContainer {...props} />
);

export const LazyFooterComponentRail = props => (
        <AsyncFooterComponentRail {...props} />
);

export const LazyGridRoundSectionRailComponent = props => (
        <AsyncGridRoundSectionRailComponent {...props} />
);
export const LazyNormalRailComponent = props => (
        <AsyncNormalRailComponent {...props} />
);
export const LazyAddSectionRailComponent = props => (
        <AsyncAddSectionRailComponent {...props} />
);

export default {
    LazyContinueWatchingRailContainer,
    LazyWatchlistRailContainer,
    LazyFooterComponentRail,
    LazyGridRoundSectionRailComponent,
    LazyNormalRailComponent,
    LazyAddSectionRailComponent,
};

import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '../constants/AppConst';

export default class SearchUtil {
    static saveResentSearchQueries(queries) {
        return;

        browserStore.set(LOCAL_STORE_KEYS.SEARCH_QUERIES, (queries || []));
    }

    static getRecentSearches() {
        return [];

        try {
            return browserStore.get(LOCAL_STORE_KEYS.SEARCH_QUERIES) || [];
        }
        catch (e) {
            return [];
        }
    }

    static clearSearchQueries() {
        browserStore.remove(LOCAL_STORE_KEYS.SEARCH_QUERIES);
    }

    static getSearchKeyForCategory(query, category) {
        return `${query}_${category}`;
    }
}

export const ANALYTICS_ACTIONS = {
    ICON_CLICK: 'icon_click',
    SEARCH_CLICK: 'search_click',
    RECENT_SEARCH: 'recent',
    SUBMIT_MOB: 'submit_mob',
    SUBMIT_RTN: 'submit_rtn',
    SUBMIT_HAPPYCODE: 'submit_happycode',
    CLICK: 'click',
    MORE_CLICK: 'more_click',
    COMMON_APP_DOWNLOAD: 'download_tool_tip',
    DOWNLOAD_APP_STORE: 'download_app_store',
    DOWNLOAD_PLAY_STORE: 'download_play_store',
    LIVE_BUTTON_CLICK: 'live_button_click',
    EXIT_FULL_SCREEN: 'exit_full_screen',
    FULL_SCREEN: 'full_screen',
    SEEK_FORWARD: 'seek_forward',
    SEEK_BACKWARD: 'seek_backward',
    TILE_CLICK: 'tile_click',
    ADD_TO_WATCHLIST_CLICK: 'add_to_watchlist',
    REMOVE_FROM_WATCHLIST_CLICK: 'remove_from_watchlist',
    BUY_PLAN_CLICK: 'buy_plan_click',
    RIBBON_SUBSCRIBE: 'subscribe',
    RIBBON_UPGRADE: 'upgrade',
    CLOSE: 'close',
    RAIL_CTA_CLICK: 'rail_cta_click',
    TAB_CLICK: 'tab_click',
    BANNER_CLICK: 'banner_click',
    BANNER_CTA_CLICK: 'banner_cta_click',
};

export const PLAY_STATUS_FIRE_TIME = 30;

export const ANALYTICS_ASSETS = {
    MORE_MENU_ICON: 'more_menu_icon',
    LANGUAGE_MENU_ICON: 'language_menu_icon',
    SEARCH_POPUP: 'search_popup',
    SEARCH_INPUT: 'search_input',
    AUTOSUGGEST: 'autosuggest',
    SEARCH_KEYPAD: 'search_keypad',
    SEARCH_SUGGESTED: 'suggested',
    AUTOSUGGEST_CLICK: 'autosuggest_click',
    SEARCH_TAB: 'search_tab',
    SEARCH_ICON: 'search_icon',
    MOBILE_INPUT_SOURCE_NAME: 'enter_mob',
    FAQ: 'faq',
    PRIVACY_POLICY: 'privacy_policy',
    TERMS_OF_USE: 'terms_of_use',
    PREFERRED_PARTNER_POPUP: 'preferred_pc_popup',
    YOU_TAB: 'you_tab',
    UNLOCK: 'unlock',
    CLAIM_NOW: 'claim_now',
    CLAIM_YOU: 'claim_you',
    BACK: 'back',
    NOT_NOW: 'not_now',
    CONTINUE: 'continue',
    SUBSCRIBE: 'subscribe',
    I_WILL_DO_IT_LATER: 'i_will_do_it_later',
    START_WATCHING: 'start_watching',
    UNLCOK_MORE_OFFERS: 'unlock_other_offers',
    GO_TO_HOMEPAGE: 'go_to_homepage',
    PREFERRED_PARTNER_OFFERS_PAGE: 'ppc_offers_page',

    PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE: 'channel_selection_page',
    PREFERRED_PARTNER_GRATIFICATION_SCREEN: 'ppc_gratification_screen',
    PREFERRED_PARTNER_CONFIRMATION_POPUP: 'ppc_confirmation_popup',
    PREFERRED_PARTNER_REWARDS_POPUP: 'ppc_rewards_popup',

    XSTREAM_PREMIUM_LANDING_PAGE: 'xstream_premium_landing_page',
    XSTREAM_PREMIUM_MARKETING_PAGE: 'xstream_premium_marketing_page',
    SUBSCRIBE_NOW: 'subscribe_now',
    SUBSCRIBE_NOW_LOGIN: 'subscribe_now_login',
    EXPLORE_NOW: 'explore_now',
    WEB: 'WEB',
    MID_ROLL: 'MID_ROLL',
    PRE_ROLL: 'PRE_ROLL',
    LIVE: 'LIVE',
    NULL: 'null',
    PARTNER_CHANNEL: 'Partner Channel',
    SUBSCRIBED_PACKS: 'Subscribed Packs',
    SUBSCRIPTION_DETAILS_PAGE: 'subscription_details_page',
    AIRTELTV: 'airteltv',

    ACTIVE_PLANS: 'my_plans_screen',
    NO_SUBSCRIPTION_CONTAINER: 'no_subscription_page',
    PLANS_AND_OFFERS: 'plans_and_offers',
    MY_PLANS_PAGE: 'my_plans_page',
    EXPLORE_PLANS_PAGE: 'explore_plans_page',
    ALA_CARTE_LISTING_PAGE: 'ala_carte_listing_page',
    XMP_CLAIM_PAGE: 'xmp_claim_page',
    OFFERS_LISTING_PAGE: 'offers_listing_page',
    XMP_CLAIM_SUCCESS: 'xmp_claim_success_page',
    NO_PLANS_AND_OFFERS_PAGE: 'no_plans_and_offers_page',
    SUBSCRIPTION_SUCCESS_PAGE: 'subscription_success_page',
    SUBSCRIPTION_FAILURE_PAGE: 'subscription_failure_page',
    PLAN_DETAIL_PAGE: 'plan_detail_page',
    SWITCH_TO_AIRTEL: 'switch_to_airtel',
    SUBSCRIPTION_ACTIVATED: 'subscription_activated',
    SUBSCRIPTION_FAILURE: 'subscription_failure',
    CONTENT_DETAIL_PAGE: 'content_detail_page',

    TRAILER_END_POPUP_EVENT: 'trailer_end_buy_plan',
    TRAILER_END_POPUP_CLOSED: 'trailer_end_popup_closed',
    TRAILER_BUY_PLAN: 'trailer_buy_plan',
    SUBSCRIPTION_RIBBON: 'subscription_ribbon',
    OPEN_APP_DEEP_LINK: 'open_app_deeplink',
    DTH_SUBSCRIBE: 'direct_subscription',
    SEE_ALL: 'see_all',
    FMF_HARDBLOCK_PAGE: 'fmf_hardblock_page',
    FMF_MYPLANS_RENEWAL: 'fmf_myplans_renewal',

    RECENT_SEARCH: 'search_recent',
    CLEAR_RECENT: 'clear_recent',
    CLEAR_AND_REENTER: 'clear_and_reenter',
    SEARCH: 'search',
};

export const PLAYER_ANALYTICS_ACTIONS = {
    PLAY_INIT_ACTION: 'play_init',
    PLAY_ACTION: 'play',
    PAUSE_ACTION: 'pause',
};

export const PIP_ANALYTICS_ACTIONS = {
    PLAY: 'pip_in',
    PIP_OUT: 'pip_out',
    PLAY_ACTION: 'pip_play',
    PAUSE_ACTION: 'pip_pause',
    IN_WEB: 'in_web',
    OUT_WEB: 'out_web',
};

export const PLAYER_CLICK_EVENTS = {
    MUTE: 'mute',
    UNMUTE: 'unmute',
    PLAYBACK_SETTINGS: 'player_playback_settings',
    FULL_SCREEN: 'fullscreen',
    HALF_SCREEN: 'halfscreen',
    NEXT_EPISODE: 'next_episode',
};

export default {
    ANALYTICS_ACTIONS,
    ANALYTICS_ASSETS,
    PLAYER_ANALYTICS_ACTIONS,
    PLAY_STATUS_FIRE_TIME,
};

import { DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import variables from '../modules/shared/scss/abstracts/_variables.scss';

export const LANGUAGES = {
    ENGLISH: 'en',
    HINDI: 'hi',
    THAILAND: 'th',
};

export const XSTREAM_PREMIUM = {
    COMING_SOON: 'Coming Soon',
};

export const MAX_LANGUAGE_SELECTION_LIMIT = 4;

export const LANGUAGE_CONTAINER_TYPE = {
    OLD: 'OLD',
    NEW: 'NEW',
};

export const PUBLISH_EVENTS = {
    PURGE_LAYOUT: 'PURGE_LAYOUT',
};


export const TIZEN_KEYCODES = {
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    OK_BUTTON: 13,
    RETURN_BUTTON: 10009,
};

export const ELEMENT_ID = {
    CDP_BANNER: 'cdp-banner',
    PLAY_CONTENT_BUTTON: 'play-content-button',
    MOBILE_PLAY_CONTENT_BUTTON: 'mobile-play-content-button',
    BANNER_VOLUME_BUTTON: 'banner-volume-button',
    ADD_TO_WATCHLIST: 'add-to-watchlist',
    MOBILE_ADD_TO_WATCHLIST: 'mobile-add-to-watchlist',
    WATCH_TRAILER_BUTTON: 'watch-trailer-button',
    MOBILE_WATCH_TRAILER_BUTTON: 'mobile-watch-trailer-button',
    CONTENT_GENRE: 'content-genre',
    BANNER_CONTENT_GENRE: 'banner-content-genre',
    MOBILE_BANNER_CONTENT_GENRE: 'mobile-banner-content-genre',
    NO_OF_SEASONS: 'number-of-seasons',
    AUDIO_LANGUAGES: 'audio-languages',
    RELEASE_YEAR: 'content-release-year',
    BANNER_CONTENT_TITLE: 'banner-content-title',
    MOBILE_BANNER_CONTENT_TITLE: 'mobile-banner-content-title',
    BANNER_CONTENT_DESCRIPTION: 'banner-content-description',
    MOBILE_BANNER_CONTENT_DESCRIPTION: 'mobile-banner-content-description',
    BANNER_AUDIO_LANGUAGES: 'banner-audio-languages',
    MOBILE_BANNER_AUDIO_LANGUAGES: 'mobile-banner-audio-languages',
    CONTENT_SHARE_BUTTON: 'content-share-button',
    MOBILE_CONTENT_SHARE_BUTTON: 'mobile-content-share-button',
    PLAN_DESCRIPTION: 'plan-description',
    MOBILE_PLAN_DESCRIPTION: 'mobile-plan-description',
    BANNER_RELEASE_YEAR: 'banner-content-release-year',
    BANNER_CONTENT_LANGUAGE: 'banner-content-language',
    HEADER_WEB: 'header-web',
    CONTINUE_WATCHING_TEXT: 'continue-watching-text',
    WATCHLIST_TITLE_TEXT: 'Watchlist-watching-text',
    RAIL_TITLE: 'rail-title',
    RAIL_ID: 'rail-id',
    RENEW_BANNER: 'renew-banner',
    HEADER_CONTAINER: 'header_container',
};

export const DROPDOWN_NAMES = {
    LANGUAGE: 'Language',
    TYPE: 'Type',
    GENRE: 'Genres',
    SORT: 'Sort',
    NONE: 'None',
    ALL: 'All',
};


export const APP_HOOK_VISIBLE_AFTER_VIS_COUNT = 3;

export const ROLE_TYPES = {
    DIRECTOR: 'director',
    ACTOR: 'actor',
};

export const PROGRAM_TYPES = {
    HOME: 'HOME',
    CHANNEL: 'CHANNEL',
    MOVIE: 'MOVIE',
    MOVIETAB: 'MOVIETAB',
    SEASON: 'SEASON',
    SHORTMOVIE: 'SHORTMOVIE',
    VIDEO: 'VIDEO',
    EPISODE: 'EPISODE',
    SERIES: 'SERIES',
    SPORTS: 'SPORTS',
    TVSHOW: 'TVSHOW',
    TVSHOWTAB: 'TVSHOWTAB',
    CHANNELTAB: 'CHANNELTAB',
    OTHER: 'OTHER',
    PEOPLE: 'PEOPLE',
    LIVE: 'LIVE',
    LIVETVSHOW: 'LIVETVSHOW',
    LIVETVCHANNEL: 'LIVETVCHANNEL',
    LIVETVCHANNELTAB: 'LIVETVCHANNELTAB',
    LIVETV: 'LIVETV',
    LIVETVMOVIE: 'LIVETVMOVIE',
    TRAILER: 'TRAILER',
    AD: 'AD',
    NEWS: 'NEWS',
    MATCH: 'MATCH',
    PROGRAM: 'PROGRAM',
    CATCHUP_EPISODE: 'CATCHUP_EPISODE',
    PACKAGE: 'PACKAGE',
    EVENT: 'EVENT',
    PROMO: 'PROMO',
    TVSERIES: 'TVSeries',
    TVSEASON: 'TVSeason',
};

export const PROGRAM_TYPES_CATEGORY = {
    VOD: 'vod',
    CATCHUP: 'catchup',
    LIVE: 'live',
};

export const DIMENSION_PAGE_MAP = {
    MOVIE: 'movies',
    LIVETVMOVIE: 'movies',

    TVSHOW: 'tv-shows',
    EPISODE: 'tv-shows',
    LIVETV: 'livetv-channels',
    PROGRAM: 'livetv-channels',

    LIVETVSHOW: 'tv-shows',
    LIVETVCHANNEL: 'livetv-channels',

    VIDEO: 'video',
};

export const DIMENSION_URL_TO_TYPE = {
    live: 'LIVETVCHANNEL',
    tv_shows: 'TVSHOW',
    movies: 'MOVIE',
    videos: 'VIDEO',
};

export const DIMENSIONS_FOR_CALL_MAP = {
    CHANNELS: `${PROGRAM_TYPES.LIVETVCHANNEL}`,
    MOVIE: `${PROGRAM_TYPES.MOVIE},${PROGRAM_TYPES.LIVETVMOVIE}`,
    LIVETVMOVIE: `${PROGRAM_TYPES.MOVIE},${PROGRAM_TYPES.LIVETVMOVIE}`,
    VIDEO: `${PROGRAM_TYPES.VIDEO}`,
    TVSHOW: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    EPISODE: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    SEASON: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,

    LIVETVSHOW: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    LIVETVCHANNEL: PROGRAM_TYPES.LIVETVCHANNEL,
    LIVETV: PROGRAM_TYPES.LIVETVCHANNEL,
};

export const DIMENSIONS_SUPPORTED_MAP = {
    MOVIE: PROGRAM_TYPES.MOVIE,
    LIVETVMOVIE: PROGRAM_TYPES.MOVIE,
    VIDEO: PROGRAM_TYPES.VIDEO,

    TVSHOW: PROGRAM_TYPES.TVSHOW,
    EPISODE: PROGRAM_TYPES.TVSHOW,
    SEASON: PROGRAM_TYPES.TVSHOW,

    LIVETVSHOW: PROGRAM_TYPES.TVSHOW,
    LIVETVCHANNEL: PROGRAM_TYPES.LIVETVCHANNEL,
    PROGRAM: PROGRAM_TYPES.LIVETV,
};

export const DIMENSION_PAGE_CONTENT_LIMIT = {
    GOOGLE_BOT: 200,
    USER: 30,
};

export const DROPDOWN_CONTENT_NAME_LIMIT = 15;

export const ICON_POSITION = {
    RIGHT_150: '150px',
    RIGHT_80: '80px',
};

export const TAB_POSITION = {
    TOP_268: '268px',
    TOP_62: '62px',
};

export const DROPDOWN_NAMES_LIMIT = {
    MOBILE: 10,
    DESKTOP: 15,
};

export const SCREEN_SIZES = {
    IPAD_SIZE: 768,
    MOBILE_MEDIUM: 577,
    MOBILE_SMALL: 481,
    MOBILE_MINI: 321,
};

export const DROP_DOWN_LIMIT = 6;

export const DIMENSION_SORT_OPTIONS = {
    NONE: 'NONE',
    ALPHABETIC_ASCENDING: 'ALPHABETIC_ASCENDING',
    ALPHABETIC_DESCENDING: 'ALPHABETIC_DESCENDING',
    NEWLY_ADDED: 'NEWLY_ADDED',
};

export const DIMENSION_SORT_DISPLAY = {
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING]: 'A-Z',
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING]: 'Z-A',
    [DIMENSION_SORT_OPTIONS.NEWLY_ADDED]: 'Recent',
    [DIMENSION_SORT_OPTIONS.NONE]: 'None',
    [DROPDOWN_NAMES.ALL]: 'All',
};

export const DIMENSION_SORT_API_OPTION = {
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING]: 'asc',
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING]: 'desc',
    [DIMENSION_SORT_OPTIONS.NEWLY_ADDED]: 'desc',
};

export const DIMENSION_PROGRAMTYPE_MAP = {
    [PROGRAM_TYPES.MOVIE]: 'Movies',
    [PROGRAM_TYPES.TVSHOW]: 'TV Shows',
    [PROGRAM_TYPES.LIVETVCHANNEL]: 'Channels',
    [PROGRAM_TYPES.LIVETV]: 'Live TV',
    [PROGRAM_TYPES.VIDEO]: 'Video',
};

export const PLAYBACK_CHECK = {
    BROWSER: 'BROWSER',
    TV: 'TV',
};

export const PROGRAM_TYPE_CATGEGORY_MAP = {
    [PROGRAM_TYPES.CHANNEL]: 'live',
    [PROGRAM_TYPES.MOVIE]: 'vod',
    [PROGRAM_TYPES.SEASON]: 'vod',
    [PROGRAM_TYPES.SHORTMOVIE]: 'vod',
    [PROGRAM_TYPES.VIDEO]: 'vod',
    [PROGRAM_TYPES.EPISODE]: 'vod',
    [PROGRAM_TYPES.SERIES]: 'vod',
    [PROGRAM_TYPES.SPORTS]: 'vod',
    [PROGRAM_TYPES.TVSHOW]: 'vod',
    [PROGRAM_TYPES.OTHER]: 'vod',
    [PROGRAM_TYPES.PEOPLE]: 'vod',
    [PROGRAM_TYPES.LIVE]: 'live',
    [PROGRAM_TYPES.LIVETVSHOW]: 'catchup',
    [PROGRAM_TYPES.LIVETVCHANNEL]: 'live',
    [PROGRAM_TYPES.LIVETVMOVIE]: 'catchup',
    [PROGRAM_TYPES.TRAILER]: 'vod',
    [PROGRAM_TYPES.NEWS]: 'live',
    [PROGRAM_TYPES.MATCH]: 'live',
    [PROGRAM_TYPES.PROGRAM]: 'live',
    [PROGRAM_TYPES.CATCHUP_EPISODE]: 'catchup',
};

export const PROGRAM_TYPE_TITLE_MAPPING = {
    MOVIE: 'movies',

    LIVE: 'live',
    LIVETVSHOW: 'livetv-channels',
    LIVETVCHANNEL: 'livetv-channels',
    LIVETVMOVIE: 'livetv-channels',
    CATCHUP_EPISODE: 'livetv-channels',

    SEASON: 'tv-shows',
    EPISODE: 'tv-shows',
    TVSHOW: 'tv-shows',

    CHANNEL: 'channel',
    CHANNEL_PARTNER_PAGE: 'channel',

    TRAILER: 'trailer',
    AD: 'ad',
    NEWS: 'news',
    MATCH: 'match',
    PROGRAM: 'program',
    SHORTMOVIE: 'short-movies',
    VIDEO: 'videos',
    SERIES: 'series',
    SPORTS: 'sports',
    OTHER: 'videos',
    PEOPLE: 'people',
    PROMOTION_PAGE: 'event',
};

export const COMPONENT_TYPE = {
    ACCORDIAN: 'accordian',
    HTML: 'html',
    ANCHOR: 'a',
    LINK: 'link',
    ANALYTIC: 'analytic',
    PROFILE: 'profile',
};

export const IMAGE_PATHS = {
    ARTIST_PAGE_DEFAULT: '/static/image-icon-default.svg',
    DEFAULT_CAST: '/static/cast4.png',
    BUY_SUBSCRIPTION: '/static/astronaut.svg',
    HELP_DESK: '/static/error/notify-helpdesk.svg',
    MANAGE_DEVICES: '/static/error/manage-your-devices.svg',
    SITE_LOGO: '/static/xstream_play_logo.svg',
    SITE_LOGO_NEW: '/static/airtel-logo-new.svg',
    GO_PREMIUM_LOGO: '/static/go_premium_logo.svg',
    SITE_DARK_LOGO: '/static/xstream_play_logo.svg',
    UNLIMITED_ENTERTAINMENT: '/static/introduction-slider/slider-1.svg',
    BEST_IN_CLASS_VIEWING_EXPERIENCE: '/static/introduction-slider/slider-2.svg',
    AIRTEL_TV_ANYTIME_ANYWHERE: '/static/introduction-slider/slider-3.svg',
    PLAYER_ERROR: '/static/error/player-error.svg',
    ERROR_PAGE_OPSS: '/static/error-page/error-loading.svg',
    ERROR_PAGE_404: '/static/error-page/error-404.svg',
    DEFAULT_PLAYER_BACKGROUND: 'https://image.airtel.tv/grandslam/content/2024_10_3/placeholder-player.jpg',
    [ERROR_CODES.ATV262]: 'static/error/not-found.svg',
    [ERROR_CODES.ATV410]: 'static/error/not-found.svg',
    FOOTER_PLAY_STORE: '/static/button-playstore.svg',
    FOOTER_APP_STORE: '/static/button-appstore.svg',
    PLAYER_PLACEHODER: '/static/placeholder-player.jpg',
    APP_FLAYER_CLOSE_BTN: '/static/ic_actionbar_close.svg',
    UPGRADE_TO_PREMIUM: 'static/upgrade-to-premium.svg',
    [DEFAULT_NAV.HOME]: '/static/icons/192x192/Airtel-Logo-192x192.png',
    [PROGRAM_TYPE_TITLE_MAPPING.LIVETVCHANNEL]: '/static/icons/192x192/Airtel-Logo-192x192.png',
    [PROGRAM_TYPE_TITLE_MAPPING.MOVIE]: '/static/icons/192x192/Airtel-Logo-192x192.png',
    [PROGRAM_TYPE_TITLE_MAPPING.TVSHOW]: '/static/icons/192x192/Airtel-Logo-192x192.png',
    WHITE_CLOSE_BUTTON: '/static/close.svg',
    WATCHLIST_ICONS_ADD: '/static/add-watchlist-icon.svg',
    WATCHLIST_ICONS_REMOVE: '/static/remove-watchlist-icon.svg',
    HELP_SUPPORT_ICONS_ADD: '/static/more-menu/help-icon.svg',
    LOGOUT_ICONS_ADD: '/static/more-menu/logout-icon.svg',
    DOWNLOAD_ICONS_ADD: '/static/more-downloads.svg',
    FEEDBACK_ICONS_ADD: '/static/more-menu/feedback-icon.svg',
    WATCHLIST_EMPTY_STATE_ICONS: '/static/watchlist-empty.svg',
    CHEVERON_ICONS: '/static/cheveron.svg',
    CLOSE_BUTTON_OVERLAY: '/static/dimensionPages/closeButton.svg',
    TICK_MARK: '/static/dimensionPages/TickMark.svg',
    SORT_BUTTON: '/static/dimensionPages/sortButton.svg',
    CEHVRON_DROPDOWN: '/static/dimensionPages/DropDownChevron.svg',
    SELECTED_FIELD: '/static/dimensionPages/selectedField.svg',
    BANNER_LEFT: '/static/chevron-left.svg',
    BANNER_RIGHT: '/static/chevron-right.svg',
    BANNER_LEFT_NEW: '/static/left-arrow-icon.svg',
    BANNER_RIGHT_NEW: '/static/right-arrow-icon.svg',
    LIVE_TAG: '/static/schedule_rails_asset/live.svg',
    RAIL_TITLE_ARROW: '/static/schedule_rails_asset/title-link.svg',
    TILE_LINK_ARROW: '/static/schedule_rails_asset/white-arrow.svg',
    RIGHT_BUTTON: '/static/schedule_rails_asset/next-button.svg',
    LEFT_BUTTON: '/static/schedule_rails_asset/prev-button.svg',
    ALERT_BELL: '/static/schedule_page_asset/alertbell.svg',
    LANGUAGE_ICON: '/static/nav-icons/top-nav-language-icon.svg',
    PROFILE_ICON: '/static/nav-icons/profile-icon.svg',
    PROFILE_ICON_NEW: '/static/nav-icons/profile-icon-new.svg',
    APP_HOOK_INSTALL: '/static/nav-icons/top-nav-download-icon.svg',
    SEARCH_ICON: '/static/nav-icons/top-nav-search-icon.svg',
    HOME_ICON: '/static/nav-icons/home-icon.svg',
    BOTTOM_NAV_SEARCH_ICON: '/static/nav-icons/bottom-nav-search-icon.svg',
    MENU_ICON: '/static/nav-icons/menu-icon.svg',
    YOU_ICON: '/static/nav-icons/you-icon.svg',
    CANCEL_ICON: '/static/search/cancel.svg',
    RECENT_SEARCH_ICON: '/static/search/recent.svg',
    TRENDING_SEARCH_ICON: '/static/search/trending-search.svg',
    VOLUME_ZERO_ICON: '/static/volume-button/volume-zero-kc.svg',
    VOLUME_LOW_ICON: '/static/volume-button/volume-low-kc.svg',
    VOLUME_HIGH_ICON: '/static/volume-button/volume-high-kc.svg',
    BACK_ARROW: '/static/player-back-new.svg',
    DONE_ICON: '/static/done-icon.svg',
    LINE_ICON: '/static/line-icon.svg',
    NEXT_EPISODE: '/static/next-episode-new.svg',
    SETTINGS: '/static/settings-new-player.svg',
    RIGHT_ARROW: '/static/play-back-controls/right-arrow.svg',
    LEFT_ARROW: '/static/play-back-controls/left-arrow.svg',
    FORWARD_SEEK: '/static/player-forward-new.svg',
    DISABLED_FORWARD_SEEK: '/static/play-back-controls/disabled-forward-seek.svg',
    BACKWARD_SEEK: '/static/player-backward-new.svg',
    DISABLED_BACKWARD_SEEK: '/static/play-back-controls/disabled-backward-seek.svg',
    PAUSE: '/static/play-back-controls/pause.svg',
    PLAY: '/static/player-play-new.svg',
    USER_PROFILE_ICON: '/static/youpage/you_profile.svg',
    USER_PROFILE_CHEVRON: '/static/youpage/you_cheveron_small.svg',
    YOU_PAGE_WATCHLIST: '/static/youpage/playlist_add.svg',
    YOU_PAGE_VEDIO_LANGUAGE: '/static/youpage/contentlanguage.svg',
    BOLD_BACK_ARROW: '/static/UpdateProfile_assets/Back-arrow.svg',
    PIP_BUTTON: '/static/play-back-controls/PiPButton.svg',
    DTH_ASSET_VIDEO_ICON: '/static/largescreen/plan-list-assets/movies.png',
    DTH_ASSET_SUBTITLE: '/static/largescreen/plan-list-assets/multi-audio.png',
    DTH_ASSET_PLAY_ICON: '/static/largescreen/plan-list-assets/watchlist.png',
    DTH_EROS_NOW_ICON: '/static/largescreen/plan-list-assets/eros.png',
    DTH_HUNGAMA_ICON: '/static/largescreen/plan-list-assets/Hungama.png',
    DTH_SHEMAROO_ICON: '/static/largescreen/plan-list-assets/Shemaroo.png',
    DTH_HOICHOI_ICON: '/static/largescreen/plan-list-assets/hich.png',
    DTH_ULTRA_ICON: '/static/largescreen/plan-list-assets/Ultra.png',
    DTH_VOOT_ICON: '/static/largescreen/plan-list-assets/voot.png',
    PIP_ICON: '/static/pip-new-player.svg',
    SUBSCRIBED_TICK: '/static/double-ticks.svg',
    REPLAY_BUTTON: '/static/channel_partner/replay.svg',
    SUBSCRIBED_CHANNEL_TICKMARK: '/static/channel_partner/subscribedTickmark.svg',
    SHARE_ICON: '/static/share-icon.svg',
    WHATSAPP_ICON: '/static/whatsapp-icon.svg',
    SHARE_BUTTON: '/static/channel_partner/shareButton.svg',
    LOCK_ICON: '/static/overlay/lock.svg',
    GIFT_ICON: '/static/more-menu/gift-icon.svg',
    ERROR_MARKER: '/static/Happy-code-assets/error-marker.svg',
    SUCCESS: '/static/Happy-code-assets/sucess.svg',
    MODALPOPUP_CROSS: '/static/modalpopup_asset/modal-cross.svg',
    GIFT_BOX: '/static/modalpopup_asset/Gift_box.svg',
    PP_UNLOCK: '/static/modalpopup_asset/unlock.svg',
    PP_BACK: '/static/modalpopup_asset/back-arrow.svg',
    PP_BANNER: '/static/modalpopup_asset/offer-banner.svg',
    PP_GIFT_CLOSE: '/static/modalpopup_asset/gift-box-close.svg',
    SAD_EMOJI: '/static/gratification-asset/sad-emoji.svg',
    GRATIFICATION_CROSS: '/static/gratification-asset/gratification-cross.svg',
    OFFER_CHEV_LEFT: '/static/pp_assets/chevron_left.svg',
    OFFER_CHEV_RIGHT: '/static/pp_assets/chevron_right.svg',
    PREMIUM_SLIDER_CHEV: '/static/slider-chevron.svg',
    PROFILE_SECOND: '/static/profile-2.svg',
    PROFILE_BACK: '/static/profile-back.svg',
    BACKGROUND_IMAGE: '/static/background-gradient.png',
    XMP_N_TICK: '/static/xmp&Tick.svg',
    BLACK_HOLE: '/static/astronaut.svg',
    ERROR_YELLOW: '/static/error_yellow.svg',
    TICK_YELLOW: '/static/tick_yellow.svg',
    APPSTORE_BLACK: '/static/appstore_black.png',
    PLAYSTORE_BLACK: '/static/playstore_black.png',
    THREE_DOTS_VERTICAL: '/static/dots_three_vertical.svg',
    CROSS: '/static/cross.svg',
    XSTREAM_PREMIUM_CIRCULAR: '/static/xstream_circular.png',
    SETTIINGS_NEW_CHEV: '/static/settings-chev-new.svg',
    SIDE_CHEVRON: '/static/cdp-assets/chev-sside.svg',
    SUB_AIRTEL_LOGO: '/static/subscription-air-logo.svg',
    PLAY_AGAIN_TRAILER: '/static/play-again-trailer.svg',
    TRAILER_XSTREAM_ICON: '/static/trailler-xstream-icon.svg',
    MUTE_ICON: '/static/mute.svg',
    UNMUTE_ICON: '/static/unmute.svg',
    ADD_TO_WATCHLIST_BLACK: '/static/black_plus.svg',
    REMOVE_TO_WATCHLIST_BLACK: '/static/black_tick.svg',
    REMOVE_TO_WATCHLIST_GREEN: '/static/green_tick.svg',
};

export const CACHE_CONFIGS = {
    LOW_PRIORITY_CACHE: 'LOW_PRIORITY',
    HIGH_PRIORITY_CACHE: 'HIGH_PRIORITY',
    MEDIUM_PRIORITY_CACHE: 'MEDIUM_PRIORITY',
    CRITICAL_PRIORITY_CACHE: 'CRITICAL_PRIORITY',
    LIFE_TIME_CACHE: 'LIFE_TIME',
};

export const CP_DESCRIPTION_LIMIT = {
    DESKTOP: 400,
    MOBILE: 180,
};

export const ACTION_MODALS_TYPES = {
    HELP_DESK: 'HELP_DESK',
    BUY_SUBSCRIPTION: 'BUY_SUBSCRIPTION',
    MANAGE_DEVICES: 'MANAGE_DEVICES',
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
};

export const INTRODUCTION_MODALS_TYPES = {
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
};

export const REF_LINKS = {
    ABOUT_US: 'https://wynk.in/corporate/index.html',
    SWITCH_TO_AIRTEL_KNOW_MORE: 'https://airtel.in/',
    PLAY_STORE_LINK: 'https://open.airtelxstream.in/XFYAvSDfcgb',
    ITUNES_LINK: 'https://open.airtelxstream.in/9pnP8MJfcgb',
    WYNK_MUSIC_LINK: 'https://wynk.in/music',
    FEEDBACK_FORM: 'https://www.airtelxstream.in/more/help/contentfeedback',
};

export const QUERY = {
    WEB_SHARE: '?appId=Web',
};

export const QUERY_PARAMS = {
    MSISDN: 'msisdn',
    CHANNEL_PARTNER: 'cp',
    APP_ID: 'appId',
    DEVICE_ID: 'deviceId',
    DEVICE_TYPE: 'deviceType',
    OS: 'os',
    OS_VERSION: 'osVersion',
    APP_VERSION: 'appVersion',
    BUILD_NUMBER: 'buildNo',
    UID: 'uid',
    TOKEN: 'token',
    UTM_SOURCE: 'utm_source',
    UTM_PLATFORM: 'utm_platform',
};

export const CAPTCHA_LENGTH = 6;

export const KEY_CODES = {
    NUM0: 48,
    NUM1: 49,
    NUM2: 50,
    NUM3: 51,
    NUM4: 52,
    NUM5: 53,
    NUM6: 54,
    NUM7: 55,
    NUM8: 56,
    NUM9: 57,
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAUSE_BREAK: 19,
    CAPS_LOCK: 20,
    ESCAPE: 27,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    INSERT: 45,
    DELETE: 46,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
    LEFT_WINDOW_KEY: 91,
    RIGHT_WINDOW_KEY: 92,
    SELECT_KEY: 93,
    NUMPAD_0: 96,
    NUMPAD_1: 97,
    NUMPAD_2: 98,
    NUMPAD_3: 99,
    NUMPAD_4: 100,
    NUMPAD_5: 101,
    NUMPAD_6: 102,
    NUMPAD_7: 103,
    NUMPAD_8: 104,
    NUMPAD_9: 105,
    MULTIPLY: 106,
    ADD: 107,
    SUBTRACT: 109,
    DECIMAL_POINT: 110,
    DIVIDE: 111,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    NUM_LOCK: 144,
    SCROLL_LOCK: 145,
    SEMI_COLON: 186,
    EQUAL_SIGN: 187,
    COMMA: 188,
    DASH: 189,
    PERIOD: 190,
    FORWARD_SLASH: 191,
    GRAVE_ACCENT: 192,
    OPEN_BRACKET: 219,
    BACK_SLASH: 220,
    CLOSE_BRAKET: 221,
    SINGLE_QUOTE: 222,
    SPACE: 32,
};

export const TITLE_SITE_LOGO = 'site-logo';

export const INVALID_URL = 'invalid url';


export const DEFAULT_STRING = {
    SERVER_FAILED_MESSAGE: 'Oops! Something went wrong.',
    NETWORK_FAILED_MESSAGE: 'Please check your network connection',
    BUTTON_RETRY: 'RETRY',
};

export const SCREEN_ORENTATIONS = {
    LANDSCAPE: 'landscape-primary',
    LANDSCAPE_UPSIDE_DOWN: 'landscape-secondary',
    PORTRAIT: 'portrait-primary',
    PORTRAIT_UPSIDE_DOWN: 'portrait-secondary',
    NATURAL: 'natural',
};

export const APP_CONFIGS = {
    TOASTR_TIMEOUT: 2000,
};

export const UI_CONFIGS = {
    MIN_WIDTH_ARTIST_DESCRIPTION: 50,
};

export const LIST_TYPE = {
    ARTIST: 'ARTIST CONTENT',
    RELATED_CONTENT: 'RELATED CONTENT',
};

export const SHARABLE_CONTENT = {
    FB_LINK: 'https://www.facebook.com/sharer/sharer.php',
    TWITTER_LINK: 'https://twitter.com/intent/tweet',
    WHATSAPP_LINK: 'whatsapp://send',
    CONTENT_RIGHTS_DEEP_LINK: 'https://open.airtelxstream.in/0AtgV6C8bgb',
    DEEP_LINK: 'https://open.airtelxstream.in/glkUS8Y7bgb',
    DOWNLOAD_APP_DEEP_LINK: 'https://open.airtelxstream.in/rw2mrrp8bgb',
};

export const DOWNLOAD_APP_ACTIONS = {
    COMMON_APP_DOWNLOAD: 'COMMON_APP_DOWNLOAD',
    DOWNLOAD_APP_STORE: 'DOWNLOAD_APP_STORE',
    DOWNLOAD_PLAY_STORE: 'DOWNLOAD_PLAY_STORE',
    APP_HOOK_INSTALL: 'Install',
    CP_UNSUPPORTED_REDIRECT: 'CP_UNSUPPORTED_REDIRECT',
};

export const LIST_TYPE_CLASS = {
    ARTIST: 'cards-portrait--grid-small',
    RELATED_CONTENT: 'cards-portrait--grid-large',
};

export const MAILING_INFORMATIONS = {
    SUPPORT_MAIL_ID: 'support@airtelxstream.in',
};

export const ERROR_PAGE_ACTIONS = {
    RELOAD: 'RELOAD',
    GOTO_HOME: 'GOTO_HOME',
};

export const TAGS = {
    PREMIUM_TAG: 'ATVPLUS',
    NON_PREMIUM_TAG: 'ATV',
};

export const SETTING_PAGES = {
    ACCOUNT: 'ACCOUNT',
};

export const SEARCH_CONFIG = {
    DEFAULT_COUNT: 30,
    DEFAULT_RAIL_ITEMS_COUNT: 8,
    MAX_RECENT_SEARCH_COUNT: 5,
    SUGGESTION_LIMIT: 10,
    SEARCH_TOTAL_RESULT_LIMIT: 30,
};

export const CONTINUE_WATCHING_CONSTANTS = {
    DELAY_TIME: 180,
    MIN_SYNC_TIME: 300000.0,
};

export const DVR_DURATION = {
    MIN_DURATION_SEC: 300.0,
};

export const SORT_TYPES = {
    A2Z: 'A2Z',
    Z2A: 'Z2A',
    DATE: 'DATE',
};

export const SERVER_SORT_TYPES = {
    IMDB: 'IMDB',
    DATE_DESC: 'DATE_DESC',
    REL_YEAR_DESC: 'REL_YEAR_DESC',
};

export const MOBILE_WIDTH = Number(variables.mobileWidth?.slice(
    0,
    variables.mobileWidth.length - 2,
));
export const MOBILE_LANDSCAPE_HEIGHT = Number(variables.mediaLandscapeHeight?.slice(
    0,
    variables.mediaLandscapeHeight.length - 2,
));
export const MOBILE_LANDSCAPE_WIDTH = Number(variables.mediaLandscapeWidth?.slice(
    0,
    variables.mediaLandscapeWidth.length - 2,
));

export const TABLET_LARGE_WIDTH = Number(variables.tabletLargeWidth?.slice(
    0,
    variables.tabletLargeWidth.length - 2,
));

export const MAX_SEARCH_INPUT_CHARS = 30;
export const MAX_PHONE_LENGTH = 10;

export const VALUE_TYPES = {
    OBJECT: 'object',
    STRING: 'string',
};

export const COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS = 60;

export const DESCRIPTION_MAX_LENGTH = 40;

export const CONTINUE_WATCHING_LIMIT = 50;

export const APP_TYPE = 'website';

export const SITE_NAME = 'Airtel Xstream Play';

export const APP_ENVIRONMENTS = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    LOCAL: 'local',
    UAT: 'uat',
};


export const FOOTER_CONFIG_CATEGORY = {
    LIVE_TV_CHANNELS: 'Live TV Channels',
};

export const INTERSECTION_OBSERVER_THRESHOLD = {
    NOT_LOADED: [
        0,
    ],
    FULLY_LOADED: [
        1,
    ],
    ON_ENTRY_AND_EXIT: [
        0,
        1,
    ],
};

export const PAGE_TYPE = {
    LIST: 'LIST',
};

export const CHAR_LIMIT = {
    DESKTOP_TAGLINE_LIMIT: 76,
    MOBILE_TAGLINE_LIMIT: 42,
};


export const BREADCRUMBS_NAME = {
    HOME: 'Home',
    LIVE_TV: 'Live TV',
    TV_SHOWS: 'TV Shows',
    MOVIES: 'Movies',
    VIDEO: 'Videos',
    ARTISTS: 'Artists',
    LAYOUT: 'Layout',
    DIMENSION_PAGES: 'Dimension Pages',
    SCHEDULE_PAGE: 'Schedule',
    CHANNEL: 'Channel',
};

export const BREADCRUMBS_MAP = {
    HOME: BREADCRUMBS_NAME.HOME,
    LIVE_TV: BREADCRUMBS_NAME.LIVE_TV,
    TV_SHOWS: BREADCRUMBS_NAME.TV_SHOWS,
    [PROGRAM_TYPES.TVSHOW]: BREADCRUMBS_NAME.TV_SHOWS,
    MOVIES: BREADCRUMBS_NAME.MOVIES,
    [PROGRAM_TYPES.LIVETVCHANNEL]: BREADCRUMBS_NAME.LIVE_TV,
    [PROGRAM_TYPES.MOVIE]: BREADCRUMBS_NAME.MOVIES,
    VIDEO: BREADCRUMBS_NAME.VIDEO,
    ARTISTS: BREADCRUMBS_NAME.ARTISTS,
    CHANNEL: BREADCRUMBS_NAME.CHANNEL,
};

export const BREADCRUMBS_LINKS = {
    [BREADCRUMBS_NAME.HOME]: '/',
    [BREADCRUMBS_NAME.TV_SHOWS]: '/tv-shows',
    [BREADCRUMBS_NAME.MOVIES]: '/movies',
    [BREADCRUMBS_NAME.LIVE_TV]: '/livetv-channels',
    [BREADCRUMBS_NAME.VIDEO]: '/video',
    [BREADCRUMBS_NAME.ARTISTS]: '/',
    [BREADCRUMBS_NAME.CHANNEL]: '/channel',
};

export const CHANNEL_SCHEDULE_TIME_GAP = {
    DAILY: 4,
};

export const CHANNEL_SCHEDULE_TILE_SIZE = {
    DESKTOP: 416,
    MOBILE_MEDIUM: 324,
};

export const CHANNEL_PARTNER_PLAYER_WIDGET_TYPE = {
    GIF: 'GIF',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
};

export const RESIZE_OPTION = {
    MOBILE: 'mobile',
    TAB_LARGE: 'tab-large',
};

export const DEFAULT_GENRE = {
    ALL_CHANNEL: 'all_channel',
};

export const FALLBACK_THEME = {
    brandColor: '#D8272C',
    cardColor: '#343434',
    cardColorRGB: 'rgb(52,52,52)',
    gradientEndColor: '#1F1F1F',
    gradientStartColor: '#343434',
    navIconColor: '#FFFFFF',
    statusBarStyle: 'dark',
    subtitleAlpha: '1',
    textColor: '#FFFFFF',
};

export const MODAL_POPUP = {
    PREFERRED_PARTNER_MODAL: 'PreferredPartnerModal',
    PREFERRED_PARTNER_GRATIFICATION: 'PreferredPartnerGratification',
    PREFERRED_PARTNER_SUBSCRIPTION: 'PreferredPartnerSubscription',
    AGE_CLASSIFICATION_POPUP: 'AgeClassificationPopup',
    XMP_CLAIM_POPUP: 'XmpClaimPopup',
    NON_AIRTEL_USER_POPUP: 'NonAirtelUserPopup',
    XMP_CLAIM_MODAL_POPUP: 'XmpClaimModalPopup',
    TRAILER_POPUP: 'TrailerPopUp',
    MORE_INFO_POPUP: 'MoreInfoPopUp',
    SINGLE_CLAIM_SUCCESS_POPUP: 'SingleClaimSuccessPopUp',
    MULTIPLE_CLAIM_SUCCESS_POPUP: 'MultipleClaimSuccessPopUp',
    CANCEL_AUTO_RENEW_PLAN_POPUP: 'CancelAutoRenewPopup',
    CANNOT_PLAY_VIDEO_POPUP: 'CannotPlayVideoPopup',
    BUY_PLAN_AFTER_TRAILER_POPUP: 'BuyPlanPopupAfterTrailer',
    CONTINUE_WATCHING_OPTION: 'ContinueWatchingOptionModal',
    OFFERS_CHECK_MODAL: 'OffersCheckModal',
    RECHARGE_PLAN_DETAIL: 'RechargePlanDetail',
    FMF_MODAL: 'FirstMonthFreeModal',
    PILLS: 'PILLS',
    DEVICE_LIMIT_REACHED: 'DEVICE_LIMIT_REACHED',
    DOWNLOAD_XSTREAM_APP_POPUP: 'DownloadXstreamAppPopup',
    COMMON_MODAL: 'COMMON_MODAL',
};

export const GTAG_SIGN_IN_ID = 'AW-934825417/GtUQCPPatpUDEMmb4b0D';

export const GTAG_SUBSCRIBE_NOW_BEFORE_LOGIN = 'AW-934825417/sbmjCN3op5oDEMmb4b0D';

export const GTAG_SUBSCRIBE_NOW_ID = 'AW-934825417/onSsCK7y4ZoDEMmb4b0D';

export const GTAG_GET_APP_ID = 'AW-934825417/GfuVCOvLvZUDEMmb4b0D';

export const EMAIL_REGEX = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

export const WEB_HEADER_HEIGHT = 102;

export default {
    WEB_HEADER_HEIGHT,
    ELEMENT_ID,
    APP_ENVIRONMENTS,
    APP_TYPE,
    SITE_NAME,
    CONTINUE_WATCHING_LIMIT,
    COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS,
    DOWNLOAD_APP_ACTIONS,
    LANGUAGES,
    ROTE_TYPES: ROLE_TYPES,
    ACTION_MODALS_TYPES,
    INTRODUCTION_MODALS_TYPES,
    REF_LINKS,
    QUERY_PARAMS,
    KEY_CODES,
    DEFAULT_STRING,
    CACHE_CONFIGS,
    SCREEN_ORENTATIONS,
    APP_CONFIGS,
    UI_CONFIGS,
    PROGRAM_TYPES,
    LIST_TYPE,
    LIST_TYPE_CLASS,
    MAILING_INFORMATIONS,
    ERROR_PAGE_ACTIONS,
    TAGS,
    SETTING_PAGES,
    SEARCH_CONFIG,
    SORT_TYPES,
    SERVER_SORT_TYPES,
    MOBILE_WIDTH,
    MAX_SEARCH_INPUT_CHARS,
    MAX_PHONE_LENGTH,
    VALUE_TYPES,
    PROGRAM_TYPE_TITLE_MAPPING,
    PROGRAM_TYPES_CATEGORY,
    PROGRAM_TYPE_CATGEGORY_MAP,
    FOOTER_CONFIG_CATEGORY,
    DIMENSION_PAGE_MAP,
    DIMENSION_PAGE_CONTENT_LIMIT,
    DIMENSION_SORT_OPTIONS,
    DIMENSION_SORT_DISPLAY,
    DIMENSION_SORT_API_OPTION,
    DROP_DOWN_LIMIT,
    TITLE_SITE_LOGO,
    INVALID_URL,
    PAGE_TYPE,
    DROPDOWN_NAMES_LIMIT,
    SCREEN_SIZES,
    DROPDOWN_CONTENT_NAME_LIMIT,
    ICON_POSITION,
    TAB_POSITION,
    BREADCRUMBS_NAME,
    BREADCRUMBS_MAP,
    BREADCRUMBS_LINKS,
    DIMENSIONS_FOR_CALL_MAP,
    CHANNEL_SCHEDULE_TIME_GAP,
    CHANNEL_SCHEDULE_TILE_SIZE,
    CHANNEL_PARTNER_PLAYER_WIDGET_TYPE,
    RESIZE_OPTION,
    CP_DESCRIPTION_LIMIT,
    DIMENSION_URL_TO_TYPE,
    FALLBACK_THEME,
    CHAR_LIMIT,
    MAX_LANGUAGE_SELECTION_LIMIT,
    LANGUAGE_CONTAINER_TYPE,
    GTAG_SIGN_IN_ID,
    GTAG_GET_APP_ID,
    CAPTCHA_LENGTH,
    EMAIL_REGEX,
};

export const OTP_TIMER = 30;

export const THEME_COLORS = {
    application: {
        background: '#191919',
        tint: '#FFD566',
        primaryLabel: '#eeeeee',
        secondaryLabel: '#8c8d8c',
        title: '#FFFFFF',
        error: '#F40000',
        link: '#1354AF',
    },
    login: {
        background: '#ffffff',
        headingText: '#333333',
        textfieldIcon: '#EEEEEE46',
        textfieldText: '#EEEEEE',
        textfieldPrefix: '#FFFFFF1E',
        textfieldPlaceholder: '#FFFFFF',
        textfieldBackground: '#666666',
        textfieldFooter: '#FFFFFF',
        bottomText: '#999999',
        disableButtonBackground: '#EEEEEE',
        buttonText: '#000000',
        activeButtonBackground: '#FFD566',
        linkText: '#1354AF',
        errorText: '#F40000',
    },
    languageSelection: {
        background: '#191919',
        title: '#cccccc',
        subtitle: '#666666',
        langTitle: '#eeeeee',
        primaryButtonTitle: '#202020',
        primaryButtonBackground: '#ffd566',
        itemBorder: '#FFFFFF',
        itemTitle: '#FFFFFF',
    },
    settings: {
        background: '#191919',
        title: '#FFFFFF',
        tabBackground: '#333333',
        tabActiveColor: '#eeeeee',
        tabNormal: '#999999',
        dismissButton: '#FFFFFF',
        accordionNormal: '#999999',
        accordianActive: '#eeeeee',
    },
    homepage: {
        layout: {
            headerBackground: '#212121',
            gradientBlack: '#000000',
            headerBoxShadow: 'rgba(0, 0, 0, 0.5)',
            background: '#191919',
            navItemActiveBorder: '#ffd566',
            navItemActiveColor: '#ffffff',
            navItemNormalColor: '#8c8d8c',
            dropdownNavBackground: 'rgba(33, 33, 33, 0.8)',
            dropdownNavShadow: 'rgba(0, 0, 0, 0.25)',
            carouselNormal: 'grey',
            carouselHighlighter: '#ffd566',
            footerBackground: '#222222',
            footerTitle: '#eee',
            footerLinks: '#999999',
            footerSettingsLink: '#9e9e9e',
            breadCrumb: '#999999',
            profileCardBackground: '#222222',
            profileItem: '#999999',
        },
    },
    search: {
        clearAll: '#FFD566',
        recentSearchTagBackground: '#414042',
        recentSearchTagColor: '#eeeeee',
        loader: 'FFD566',
        placeholder: '#757575',
        bottomBorder: '#939598',
        recentSearchtext: '#eeeeee',
    },
    rail: {
        title: '#eeeeee',
        overlayTag: '#cccccc',
        more: '#ffd566',
    },
    liveTV: {
        title: '#bbbbbb',
        background: '#191919',
        languageBox: '#8c8d8c',
        channeltitle: '#ffffff',
        showTitle: '#aaaaaa',
        highlighter: '#e32e2d',
        showBackground: '#262626',
        filterSection: {
            primaryLabel: '#aaaaaa',
            selectedFilterTitle: '#FFD566',
            activeTab: '#404040',
        },
        languageSelection: {
            title: '#cccccc',
            done: '#191919',
            doneBackground: '#ffd566',
        },
    },
    detailPage: {
        background: '#222222',
        title: '#FFFFFF',
        year: '#9e9e9e',
        links: '#FFd566',
        descriptionHeading: '#9e9e9e',
        description: '#ffffff',
        subtitle: '#FFFFFF',
        cardBG: '#333333',
        navigationTitle: '#EEEEEE',
        moreTitle: '#FFD566',
        staringTitle: 'FFD566',
        trailerTitle: '#FFFFFF',
        shareText: '#cccccc',
        watchListText: '#cccccc',
        castName: '#999999',
        separator: '000000',
        progressBackground: '00000000',
        videoDetailBackground: '333333',
        comingUpShowsPopupBackground: '191919',
        actorDetailPage: {
            background: '#222222',
            titleBackground: '#2a2a2a',
            title: '#ffffff',
            birthPlace: '#999999',
            moreButton: '#ffd566',
            watchBackground: '#222222',
        },
    },
    errorPage: {
        description: '#FFFFFF',
        errorTitle: '#707070',
        primaryButtonBackground: '#33333300',
        primaryButtonBorder: '#FFD566',
        primaryButtonTitle: '#FFFFFF',
    },
};

export const CDP_EVENT_CONST = {
    TRAILER_CLICK: 'trailer_click',
    WATCH_CLICK: 'watch_click',
    WATCHLIST_CLICK: 'watchlist_click',
    TRAILER_VIEW: 'trailer_view',
    MUTE_CLICK: 'mute_click',
    SHARE_CLICK: 'share_click',
    EPISODE_TAB_CLICK: 'episodes_tab_click',
    SEASON_CLICK: 'seasons_click',
    EPISODE_CLICK: 'episode_click',
    TILE_CLICK: 'tile_click',
    PLAY_CLICK: 'resume_click',
    PAUSE_CLICK: 'pause_click',
    NEXT_EP_CLICK: 'nextepisode_click',
    SUBTITLE_CLICK: 'subtitle_click',
    STARNAME_CLICK: 'starname_click',
    TRAILER_END: 'trailer_end',
    TRAILER_MUTE: 'trailer_mute',
    TRAILER_UNMUTE: 'trailer_unmute',
    TRAILER_RELOAD: 'trailer_reload',
};

export const BLOG_DATA = {
    BLOGS_PER_PAGE: 6,
};

export const TILE_WIDTH = {
    CHANNEL: 150,
    LANDSCAPE: 264,
};

import cookie from 'cookie';
import React from 'react';
import lodashGet from 'lodash/get';
import lodashCamelCase from 'lodash/camelCase';
import lodashStartCase from 'lodash/startCase';
import { CryptoUtil, DateTime } from '@airtel-tv/utils';
import {
    LOCAL_TILE_TYPES, RELATION_TYPES, LAZY_TILE_LIMIT_PORTRAIT, TILES_LIMIT, TILE_LAZY_TILE_LIMIT_TYPE_MAP, TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import {
    clearBrowserStorage, decodeForUrl, encodeForUrl, redirectToInNewTab, decodeBase64, checkWindowExist,
} from '@airtel-tv/utils/WindowUtil';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import LanguageProvider from '../providers/LanguageProvider';
import {
    DOWNLOAD_APP_ACTIONS, PROGRAM_TYPES, PROGRAM_TYPE_TITLE_MAPPING, PROGRAM_TYPES_CATEGORY, PROGRAM_TYPE_CATGEGORY_MAP,
} from '../constants/AppConst';
import LanguageCodesProvider from '../providers/LanguageCodesProvider';
// import {
//     downloadAppItunesGaEvent, downloadAppPlayStoreGaEvent, downloadAppMobileHeaderGaEvent,
// } from './GaEvents';
import env from '../config';
import { NETWORK_QUALITY } from '../constants/NetworkConst';
import LinkComponent from '../modules/shared/components/link/LinkComponent';
import { getStore } from '../redux/Store';

export const replaceAll = (targetString, search, replacement) => targetString.replace(new RegExp(search, 'g'), replacement);

export function stringPadding(value, length = 2, placeholder = 0) {
    if (!value) {
        return value;
    }
    return value.toString().padStart(length, placeholder);
}

export function getDate(date = new Date(), format = 'YYYY-MM-DD HH:mm:ss') {
    // return moment(date).format(format);
    return new DateTime(date).format(format);
}

export const getShowTime = timestamp => new DateTime(timestamp).format('hh:mm a');

export const getTimeStamp = () => new Date().getTime();

export const getStartTime = (date = new Date(), timeGap = 0) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(tempDate.getHours() - timeGap);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate.getTime();
};

export const getEndTime = (date = new Date(), timeGap = 1) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(tempDate.getHours() + timeGap);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate.getTime();
};

// Convert seconds to HH:mm:ss for player

function pad(num) {
    return (`0${num}`).slice(-2);
}

export function secondsTohhmmss(secs) {
    const playerTime = new DateTime('2015-01-01');
    playerTime.addUTCSeconds(secs);
    return playerTime.format('H:mm:ss');
}

export function secondToHours(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds / 3600; // eslint-disable-line no-mixed-operators
}

export function secondToMinutes(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds % 3600 / 60; // eslint-disable-line no-mixed-operators
}

export function getFullScreen(docElm) {
    if (!docElm) {
        return;
    }
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    }
    else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
    }
    else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
    }
    else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
    }
}

export function hrefEscape() {
    return null;
}

export function removeFromArray(arr, target) {
    const targetIndex = arr.indexOf(target);
    arr.splice(targetIndex, 1);
}

export function getSourceName(value) {
    return value.split('/')[1];
}

export function isNumber(value) {
    const n = Number(value);
    return (value || value === 0) && !Number.isNaN(n);
}

export function isValidEmail(email) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line no-useless-escape
    return re.test(String(email).toLowerCase());
}

export function objectToArray(obj) {
    return Array.from(Object.keys(obj), k => obj[k]);
}

export function getSeasonTitle(seasonNum) {
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.SEASON_TITLE.replace(/{seasonNum}/g, seasonNum);
}

export const getMetaDescriptionGenre = ({ genreForMeta = '', actorForMeta = '' }) => {
    if (!genreForMeta || !actorForMeta) {
        return '';
    }
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.META_GENRE_MOVIE_DESCRIPTION.replace('{genres}', genreForMeta)
        .replace('{actors}', actorForMeta);
};

export const getPageSeoMeta = ({
    programType,
    contentTitle,
    contentDescription,
    tvShowDetails = {
        showName: '',
        seasonNumber: '',
        episodeNumber: '',
        episodeName: '',
        channelId: '',
        channelName: '',
        episodeDate: '',
    },
    genreForMeta = '',
    actorForMeta = '',
    releaseYear = '',
}) => {
    const LANGUAGES = LanguageProvider();

    let title = contentTitle;
    let description = contentDescription;
    let keywords = '';

    const {
        showName,
        seasonNumber,
        episodeNumber,
        episodeName,
        channelId,
        channelName,
        episodeDate,
    } = tvShowDetails;

    switch (programType) {
        case PROGRAM_TYPES.LIVETVCHANNEL: {
            title = LANGUAGES.META_TITLE_LIVE
                .replace(/{title}/g, contentTitle);

            description = LANGUAGES.META_LIVE_DESCRIPTION.replace(/{title}/g, contentTitle);

            keywords = LANGUAGES.META_LIVE_KEYWORDS.replace(/{title}/g, contentTitle);
            break;
        }
        case PROGRAM_TYPES.EPISODE:

        // has channel id : yes then it is a live tv episode
        {
            if (!channelId) {
                title = LANGUAGES.META_EPISODE_TITLE
                    .replace(/{showName}/g, showName)
                    .replace(/{seasonNum}/g, seasonNumber)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeName}/g, episodeName);

                description = LANGUAGES.META_EPISODE_DESCRIPTION
                    .replace(/{showName}/g, showName)
                    .replace(/{seasonNum}/g, seasonNumber)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeName}/g, episodeName);

                keywords = LANGUAGES.META_EPISODE_KEYWORDS
                    .replace(/{episodeName}/g, episodeName);
            }
            else {
                title = LANGUAGES.META_CATCHUP_EPISODE_TITLE
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{channelName}/g, channelName)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeDate}/g, episodeDate);


                description = LANGUAGES.META_CATCHUP_EPISODE_DESCRIPTION
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{episodeDate}/g, episodeDate);

                description = LANGUAGES.META_CATCHUP_EPISODE_KEYWORDS
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{episodeDate}/g, episodeDate);
            }


            break;
        }
        case PROGRAM_TYPES.SEASON: {
            title = LANGUAGES.META_SEASON_TITLE
                .replace(/{showName}/g, showName)
                .replace(/{seasonNum}/g, seasonNumber);

            description = LANGUAGES.META_SEASON_DESCRIPTION
                .replace(/{showName}/g, showName)
                .replace(/{seasonNum}/g, seasonNumber);


            break;
        }

        case PROGRAM_TYPES.TVSHOW: {
            title = LANGUAGES.META_TV_SHOW_TITLE
                .replace(/{showName}/g, showName);

            description = LANGUAGES.META_TV_SHOW_DESCRIPTION
                .replace(/{showName}/g, showName);

            keywords = LANGUAGES.META_TV_SHOW_KEYWORDS
                .replace(/{showName}/g, showName);

            break;
        }
        case PROGRAM_TYPES.LIVETVSHOW: {
            title = LANGUAGES.META_CATCHUP_SHOW_TITLE
                .replace(/{showName}/g, contentTitle)
                .replace(/{channelName}/g, channelName);

            description = LANGUAGES.META_CATCHUP_SHOW_DESCRIPTION
                .replace(/{showName}/g, contentTitle);

            keywords = LANGUAGES.META_CATCHUP_SHOW_KEYWORDS
                .replace(/{showName}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.LIVETVMOVIE: {
            title = LANGUAGES.META_CATCHUP_MOVIE_TITLE
                .replace(/{title}/g, contentTitle)
                .replace(/{channelName}/g, channelName);

            description = LANGUAGES.META_CATCHUP_MOVIE_DESCRPTION
                .replace(/{title}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.MOVIE: {
            const modifiedTitle = releaseYear ? `${contentTitle} ${releaseYear}` : contentTitle;
            title = LANGUAGES.META_MOVIES_SEO_TITLE.replace('{title}', modifiedTitle);
            description = replaceAll(LANGUAGES.META_MOVIES_DESCRIPTION, '{title}', contentTitle) + getMetaDescriptionGenre({
                genreForMeta,
                actorForMeta,
            });
            keywords = replaceAll(LANGUAGES.META_MOVIE_KEYWORDS, '{title}', contentTitle);
            break;
        }


        default:
            title = LANGUAGES.META_TITLE.replace('{title}', contentTitle).replace(
                '{type}',
                PROGRAM_TYPE_TITLE_MAPPING[programType] || '',
            );
            description = replaceAll(LANGUAGES.META_MOVIES_DESCRIPTION, '{title}', contentTitle);
            keywords = replaceAll(LANGUAGES.META_MOVIE_KEYWORDS, '{title}', contentTitle);
    }

    return {
        title,
        description,
        keywords,
    };
};

export const getMetaTitleArtist = (artistTitle = '') => {
    if (artistTitle !== '') {
        const LANGUAGES = LanguageProvider();
        return LANGUAGES.META_TITLE_ARTIST.replace('{title}', artistTitle);
    }
    return null;
};

export const getMetaDescriptionArtist = (artistTitle = '') => {
    if (artistTitle !== '') {
        const LANGUAGES = LanguageProvider();
        return LANGUAGES.META_DESCRIPTION_ARTIST.replace(/{title}/g, artistTitle);
    }
    return null;
};

export const getMetaLayout = (title = '') => {
    const LANGUAGES = LanguageProvider();
    if (title !== '') {
        return LANGUAGES.META_LAYOUT_PAGE.replace('{title}', title);
    }
    return LANGUAGES.META_DEFAULT_PAGE;
};

export const getMetaTitleSchedulePage = (title = '') => {
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.META_SCHEDULE_TITLE.replace('{title}', title);
};

export const getMetaDescriptionSchedulePage = (title = '') => {
    const LANGUAGES = LanguageProvider();
    return LANGUAGES.META_SCHEDULE_DESCRIPTION.replace('{title}', title);
};

export const removeConsecutiveRepeatedChar = (target, char) => target.filter((item, pos, arr) => pos === 0 || (arr[pos - 1] !== char && char) || item !== arr[pos - 1]).join('');

export const trimCaptionsForUrl = (targetString = '', separator = '-', defaultString = 'default') => {
    const specialCharacters = [
        '!',
        '"',
        '#',
        '$',
        ' % ',
        ' & ',
        "'",
        '(',
        ')',
        '*',
        '+',
        ',',
        '\\-',
        '.',
        '/',
        ':',
        ';',
        '<',
        '=',
        '>',
        '?',
        '@',
        '\\[',
        '\\]',
        '^',
        '_',
        '`',
        '{',
        '|',
        '}',
        '~',
        "'",
        '–',
    ];

    const regexEscapeCharacters = new RegExp(`[${specialCharacters.join('|')}]`);

    let encoded = encodeForUrl(
        removeConsecutiveRepeatedChar(
            targetString
                .trim()
                .toLowerCase()
                // .split((/\.|\/| |\(|\)|:|,|!|+|,|'|<|>|#|%|{|}|^|~|[|]|$|_|(|)|;|?|@|=|/))
                .split(regexEscapeCharacters) // src: https://stackoverflow.com/a/25266512/4221433
                .join(separator)
                .split(''),
            separator,
        ),
    ) || defaultString;
    if (encoded[encoded.length - 1] === separator) {
        encoded = encoded.slice(0, encoded.length - 1); // remove last '-'
    }
    if (encoded[0] === separator) {
        encoded = encoded.slice(1, encoded.length); // remove first '-'
    }
    return encoded;
};

export const encodeContentId = (contentId) => {
    if (!contentId) {
        return '';
    }

    if (contentId[0] === contentId[0].toLowerCase()) {
        return contentId; // already lowercase
    }

    const splittedContentId = contentId.split('_');

    if (splittedContentId.length < 2) {
        return contentId;
    }

    splittedContentId[0] = splittedContentId[0].toLowerCase();
    splittedContentId[1] = splittedContentId[1].toLowerCase();

    return (
        splittedContentId
            .join('_')
            // replace first 2 _ with -
            .replace('_', '-') // replace first underscore to '-'
            .replace('_', '-')
    ); // replace first underscore to '-'
};

export const decodeContentId = (contentId) => {
    if (!contentId) {
        return '';
    }

    if (contentId[0] === contentId[0].toUpperCase()) {
        return contentId; // already uppercase
    }

    const splittedContentId = contentId.split('_');

    if (splittedContentId.length < 1) {
        return contentId;
    }

    const splittedEncodeContentId = splittedContentId[0].split('-');

    if (splittedEncodeContentId.length < 2) {
        return contentId;
    }

    splittedEncodeContentId[0] = splittedEncodeContentId[0].toUpperCase();
    splittedEncodeContentId[1] = splittedEncodeContentId[1].toUpperCase();
    splittedContentId[0] = splittedEncodeContentId.join('_');
    const result = splittedContentId.join('_');
    return result;
};

export const urlStringToCaption = str => decodeForUrl(replaceAll(str, '-', ' '));

export const convertToNumber = (str) => {
    const num = Number(str);
    return Number.isNaN(num) ? 0 : num;
};

export const clearAllBrowserStorage = () => {
    clearBrowserStorage();
    browserStore.clearAll();
};

export const toTitleCase = str => lodashStartCase(lodashCamelCase(str));

export const firstLetterUpper = (str) => {
    if (!str || str.length < 1) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getWeek = (airDate) => {
    const date = new Date(airDate);
    return date.toString().split(' ')[0];
};

export const getDayMonth = (airDate) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const date = new Date(airDate);
    return `${date.getDate()} ${monthNames[date.getMonth()]}`;
};

export const handlePasteNumericData = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const clipboardText = clipboardData.getData('Text');

    const pattern = new RegExp(/^\d+$/);
    const isTextNumber = pattern.test(clipboardText);

    if (!isTextNumber) {
        e.preventDefault();
    }
};

export const cookieStringToObject = cookieString => cookie.parse(cookieString);

export const convertToJSON = (value) => {
    try {
        return JSON.parse(value);
    }
    catch (error) {
        return value;
    }
};

export const decryptSecretKey = ({ secretKey, authToken }) => {
    if (!secretKey || !authToken) {
        return null;
    }


    const decryptionKey = authToken.slice(0, 16);

    const decryptedKey = CryptoUtil.AESDecrypt({
        key: decryptionKey,
        value: secretKey,
    });

    return decryptedKey;
};


export const isItemObject = item => (typeof (item) === 'object');

export const onInstallClick = ({ clickItem, isPlayerError = false }) => {
    // const isMobile = DeviceUtil.isMobile();
    const store = getStore();
    const refLinks = lodashGet(store.getState(), 'appConfig.refLinks', {});
    const sharableContent = lodashGet(store.getState(), 'appConfig.sharableContent', {});
    switch (clickItem) {
        case DOWNLOAD_APP_ACTIONS.COMMON_APP_DOWNLOAD:
            // if (isMobile) {
            //     redirectTo(SHARABLE_CONTENT.DOWNLOAD_APP_DEEP_LINK);
            // }
            //  else {
            redirectToInNewTab(sharableContent.DOWNLOAD_APP_DEEP_LINK);
            //  }

            if (!isPlayerError) {
                // downloadAppMobileHeaderGaEvent();
            }

            // if (isPlayerError) {
            //     downloadAppPlayBackErrorGaEvent();
            // }
            // else {
            //     downloadAppMobileHeaderGaEvent();
            // }
            break;

        case DOWNLOAD_APP_ACTIONS.DOWNLOAD_APP_STORE:
            // if (isMobile) {
            //     redirectTo(REF_LINKS.ITUNES_LINK);
            // }
            // else {
            redirectToInNewTab(refLinks.ITUNES_LINK);
            // }

            // downloadAppItunesGaEvent();
            break;

        case DOWNLOAD_APP_ACTIONS.DOWNLOAD_PLAY_STORE:
            redirectToInNewTab(refLinks.PLAY_STORE_LINK);
            // downloadAppPlayStoreGaEvent();
            break;

        default:
    }
};

export const isObjEmpty = (obj) => {
    let isEmpty = false;
    const type = typeof obj;

    isEmpty = isEmpty || !obj;
    isEmpty = isEmpty || (type === 'undefined'); // if it is undefined
    isEmpty = isEmpty || (obj === null); // if it is null
    isEmpty = isEmpty || (type === 'string' && (obj === '')); // if the string is empty
    isEmpty = isEmpty || (obj === false || obj === 0); // if boolean value returns false
    isEmpty = isEmpty || (Array.isArray(obj) && obj.length === 0); // if array is empty
    isEmpty = isEmpty || (type === 'object' && Object.keys(obj).length === 0); // if object is empty

    return isEmpty;
};

export const disableScrollOnWheel = (e) => {
    if (e && e.target) {
        e.target.blur();
    }
};

export const getDaysFromMilliseconds = (ms) => {
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    return `${days}`;
};


export const getCurrentProgram = (programs, time = getTimeStamp()) => {
    const program = programs.find(item => item.startTime <= time && item.endTime >= time);

    return program || null;
};

export const getCurrentProgramIndex = (programs, time = getTimeStamp()) => {
    const programIndex = programs.findIndex(item => item.startTime <= time && item.endTime >= time);

    return programIndex !== -1 ? programIndex : null;
};

export const createMockEPG = (epgList, epgStartTime, epgEndTime) => {
    const finalEpglist = [];

    let currentStartTime = epgStartTime;
    if (epgList.length) {
        currentStartTime = epgList[0].startTime < currentStartTime ? epgList[0].startTime : currentStartTime;
    }


    for (let index = 0; index < epgList.length;) {
        const { startTime, endTime } = epgList[index];

        const result = { ...epgList[index] };

        if (currentStartTime < startTime) {
            result.startTime = currentStartTime;
            result.endTime = startTime;
            result.mocked = true;
        }
        else {
            result.startTime = startTime;
            result.endTime = endTime;
            index += 1;
        }

        currentStartTime = result.endTime;

        finalEpglist.push(result);
    }

    if (
        finalEpglist
        && finalEpglist.length >= 1
        && finalEpglist[finalEpglist.length - 1].endTime < epgEndTime
    ) {
        finalEpglist.push({
            mocked: true,
            startTime: finalEpglist[finalEpglist.length - 1].endTime,
            endTime: epgEndTime,
        });
    }
    else if (finalEpglist.length < 1) {
        finalEpglist.push({
            mocked: true,
            startTime: epgStartTime,
            endTime: epgEndTime,
        });
    }

    return finalEpglist;
};

export const getTitleByProgramType = (contentDetails) => {
    if (contentDetails.programType) {
        switch (contentDetails.programType) {
            case PROGRAM_TYPES.EPISODE:
                return {
                    title: contentDetails.tvShowName || '',
                    episodeTitle: contentDetails.title || '',
                };
            case PROGRAM_TYPES.PROGRAM:
                return {
                    episodeTitle: contentDetails.tvChannelName || '',
                    title: contentDetails.title || '',
                };

            default:
                return {
                    title: contentDetails.title || '',
                    episodeTitle: null,
                };
        }
    }
    return null;
};


export const truncateWithEllipses = (text = '', max = 0) => text.slice(0, max - 1) + (text.length > max ? '...' : '');

export const parseLiveTvCooke = (cookieString) => {
    if (cookieString) {
        const cookies = cookieStringToObject(cookieString);
        const playbackUrlCookies = cookies;
        let cloudFrontPolicy = cookies['CloudFront-Policy'];
        if (cloudFrontPolicy) {
            cloudFrontPolicy = replaceAll(cloudFrontPolicy, '_', '=');

            const decodedCloudFrontPolicy = decodeBase64(cloudFrontPolicy);
            const cloudFrontPolicyJSON = convertToJSON(decodedCloudFrontPolicy);
            const cookieExpire = lodashGet(cloudFrontPolicyJSON, 'Statement[0].Condition.DateLessThan.AWS:EpochTime', Infinity);

            return {
                cookieExpire,
                playbackUrlCookies,
            };
        }
    }

    return {
        cookieExpire: null,
        playbackUrlCookies: null,
    };
};

export const getTileFromRelationType = (relationType) => {
    switch (relationType) {
        case RELATION_TYPES.PEOPLE:
            return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
        case RELATION_TYPES.TVSHOW:
            return LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
        default:
            return LOCAL_TILE_TYPES.PORTRAIT_LARGE;
    }
};

export const getTileFromProgramType = (programType, relationType) => {
    switch (programType) {
        case PROGRAM_TYPES.TVSHOW:
            switch (relationType) {
                case RELATION_TYPES.PEOPLE:
                    return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
                default:
                    return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
            }

        default:
            switch (relationType) {
                case RELATION_TYPES.PEOPLE:
                    return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
                default:
                    return LOCAL_TILE_TYPES.PORTRAIT_SMALL;
            }
    }
};


export function getClosestElement(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector
            || Element.prototype.mozMatchesSelector
            || Element.prototype.msMatchesSelector
            || Element.prototype.oMatchesSelector
            || Element.prototype.webkitMatchesSelector
            || function matcheSelector(s) {
                // eslint-disable-next-line react/no-this-in-sfc
                const matches = (this.document || this.ownerDocument).querySelectorAll(
                    s,
                );

                let i = matches.length - 1;

                while (i >= 0 && matches.item(i) !== this) {
                    i -= 1;
                }
                return i > -1;
            };
    }

    // Get the closest matching element
    let elemProp = elem;
    for (elemProp; elemProp && elemProp !== document; elemProp = elemProp.parentNode) {
        if (elemProp.matches(selector)) {
            return elemProp;
        }
    }
    return null;
}

export function removeFalsy(obj) {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== '') {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
}

export const getHostFromUrl = (url = '') => {
    const arr = url.split('/');
    if (arr.length > 2) {
        return `${arr[0]}//${arr[2]}`;
    }
    return null;
};

/**
 * Get a random integer between `min` and `max`.
 *
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);


export const percentCalculation = (total, value) => parseFloat((parseFloat(total) * parseFloat(value)) / 100);

export const subtractSmallFromBig = (n, n2) => {
    if (n > n2) {
        return n - n2;
    }

    return n2 - n;
};

export const getUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0; // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-bitwise, no-mixed-operators
    return v.toString(16);
});

export const getAppVersion = () => {
    const { maj, min, patch } = env.appVersion;
    return `${maj}.${min}.${patch}`;
};

export const getBuildNumber = () => env.buildNumber;

export const getNetworkQuality = () => NETWORK_QUALITY.AWFUL;

export const getDeviceType = () => env.deviceType;

export const getAppId = () => env.appId;

export const getosType = () => env.osType;


export const getLangfromCode = ({ lang = '' }) => LanguageCodesProvider()[lang];


export const getSocialShareInfo = ({
    programType,
    showName,
    channelName,
    airDate,
    currentUrl,
    baseUrl,
    shortDesc,
}) => {
    let emailShareText = '';
    let date = '';
    const pageUrl = baseUrl + currentUrl;
    const LANGUAGE = LanguageProvider();
    const store = getStore();
    const sharableContent = lodashGet(store.getState(), 'appConfig.sharableContent', {});
    switch (programType) {
        case PROGRAM_TYPES.LIVETVSHOW:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY_CATCHUP_SHOW
                .replace(/{showName}/g, showName)
                .replace(/{channelName}/g, channelName);
            break;
        case PROGRAM_TYPES.EPISODE:
            if (channelName) {
                date = new DateTime(airDate).format('DD MMMM YYYY');
                emailShareText = LANGUAGE.CONTENT_SHARE_BODY_CATCHUP_EPISODE
                    .replace(/{showName}/g, showName)
                    .replace(/{channelName}/g, channelName)
                    .replace(/{date}/g, date);
            }
            else {
                emailShareText = LANGUAGE.CONTENT_SHARE_BODY
                    .replace(/{title}/g, showName);
            }
            break;
        case PROGRAM_TYPES.LIVETVCHANNEL:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY
                .replace(/{title}/g, channelName);
            break;
        case PROGRAM_TYPES.PACKAGE:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY_CHANNEL_PARTNER
                .replace(/{channelName}/g, channelName)
                .replace(/{channelUrl}/g, currentUrl)
                .replace(/{shortDesc}/g, shortDesc);
            break;
        default:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY
                .replace(/{title}/g, showName);
    }
    const emailShareBody = emailShareText.replace(/{documentUrl}/g, pageUrl);
    const facebookUrl = `${sharableContent.FB_LINK}?u=${pageUrl}&text=${emailShareText.replace(/{documentUrl}/g, '')}`;
    const twitterUrl = `${
        sharableContent.TWITTER_LINK
    }?url=${pageUrl}&text=${emailShareText.replace(/{documentUrl}/g, '')}&hashtags=AirtelTv`;
    const whatsAppUrl = `${sharableContent.WHATSAPP_LINK}?text=${emailShareText.replace(/{documentUrl}/g, pageUrl)}`;
    const windowsShareText = emailShareText.replace(/{documentUrl}/g, '');
    return {
        emailShareBody,
        facebookUrl,
        twitterUrl,
        whatsAppUrl,
        windowsShareText,
    };
};

export const isStandalone = () => {
    if (checkWindowExist()) {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
    return false;
};

export const calculateProgressPercentage = ({
    continueWatchingData,
    id,
}) => {
    let progressPercentage = 0;
    if (continueWatchingData && continueWatchingData[id]) {
        const { contentResponse: { durSec }, lastWatchedPosition } = continueWatchingData[id];

        if (lastWatchedPosition && durSec) {
            progressPercentage = (lastWatchedPosition / durSec) * 100;
        }
    }

    return progressPercentage;
};

export const findTileLimit = ({
    windowWidth,
    tileType,
}) => {
    const tileMap = TILE_LAZY_TILE_LIMIT_TYPE_MAP[tileType] || LAZY_TILE_LIMIT_PORTRAIT;
    const widthKey = Object.keys(tileMap);
    for (let i = 0; i < widthKey.length; i++) {
        const mapWidth = widthKey[i];
        if (mapWidth > windowWidth) {
            return tileMap[mapWidth];
        }
    }

    return TILES_LIMIT;
};
export const inDoubleDigits = (str) => {
    const padString = '0';
    const length = 2;
    let newStr = '';
    while (str.length < length) {
        newStr = padString + str;
        return newStr;
    }
    return str;
};

export const tvShowTitle = (contentDetails) => {
    const LANGUAGE = LanguageProvider();
    const { tvShowName, title } = contentDetails;
    const seasonId = lodashGet(contentDetails, 'seasonId', '');
    let episodeTitle = '';
    if (seasonId) {
        const { episodeNum, episodeSeasonNum } = contentDetails;
        episodeTitle = (episodeNum && episodeSeasonNum) ? `S${inDoubleDigits(episodeSeasonNum.toString())}E${inDoubleDigits(episodeNum.toString())}` : '';
    }
    else {
        const { airDate } = contentDetails;
        episodeTitle = new DateTime(airDate).format('DD MMMM');
    }

    return tvShowName ? LANGUAGE.CONTINUE_WATCHING_TITLE
        .replace(/{tvShowName}/g, tvShowName)
        .replace(/{episodeDetails}/g, episodeTitle) : title;
};

export const continueWatchingTileTitle = (contentDetails) => {
    const { programType, title } = contentDetails;
    if (programType !== PROGRAM_TYPES.EPISODE) {
        return title;
    }

    switch (programType) {
        case PROGRAM_TYPES.EPISODE:
            return tvShowTitle(contentDetails);
        default:
            return title;
    }
};

export const isArrayofObjectsEqual = (a, b) => {
    if (a === b) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; ++i) {
        if (a[i].key !== b[i].key) {
            return false;
        }
    }
    return true;
};

export const packageIdForListingPage = id => `${id}LISTING`;

export const stringifyInfinityValuesObject = (key, val) => {
    if (val === Infinity) {
        return 'Infinity';
    }
    if (val === -Infinity) {
        return '-Infinity';
    }
    return val;
};

export const parseInfinityValuesObject = (key, val) => {
    if (val === 'Infinity') {
        return Infinity;
    }
    if (val === '-Infinity') {
        return -Infinity;
    }
    return val;
};

export const getTileType = ({
    pageTitle, explicitTileType, cpId = null, preferredArtwork = null,
}) => {
    let tileType = null;
    if (preferredArtwork) {
        switch (preferredArtwork) {
            case CONTENT_IMAGE_TYPES.PORTRAIT:
            case CONTENT_IMAGE_TYPES.PORTRAIT_HD:
            case TILE_TYPES.MOVIE_NOLOGO:
                tileType = LOCAL_TILE_TYPES.PORTRAIT_SMALL;
                break;
            case CONTENT_IMAGE_TYPES.LANDSCAPE:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_43:
            case TILE_TYPES.TVSHOW_NOLOGO_169:
            case TILE_TYPES.TVSHOW_NOLOGO_43:
                tileType = LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
                break;
            default:
                tileType = LOCAL_TILE_TYPES.PORTRAIT_LARGE;
        }
        return tileType;
    }
    switch (pageTitle) {
        case PROGRAM_TYPES.MOVIE:
        case PROGRAM_TYPES.LIVETVMOVIE:
        case PROGRAM_TYPES.LIVETVCHANNEL:
            tileType = LOCAL_TILE_TYPES.PORTRAIT_LARGE;
            break;
        case PROGRAM_TYPES.LIVETVSHOW:
        case PROGRAM_TYPES.TVSHOW:
        case PROGRAM_TYPES.SEASON:
        case PROGRAM_TYPES.EPISODE:
        case PROGRAM_TYPES.VIDEO:
            tileType = LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
            break;
        case PROGRAM_TYPES.PACKAGE:
            // for distinguishing between explore by rail and partner channel rail
            if (explicitTileType === TILE_TYPES.CONTENT_PARTNER_RAIL || cpId) {
                tileType = LOCAL_TILE_TYPES.CHANNEL_PARTNER_RAIL;
            }
            else {
                tileType = LOCAL_TILE_TYPES.CUSTOM_EXPLORE;
            }
            break;
        default:
            tileType = LOCAL_TILE_TYPES.PORTRAIT_LARGE;
    }
    return tileType;
};

// Find language rn from languageFilters key in appConfig
export const findLanguageFromCode = (code, languageFilters) => {
    const key = Object.keys(languageFilters).find(lan => lan === code);
    return key && languageFilters[key].rn ? languageFilters[key].rn : null;
};

export const getListOfLinks = ({ list, separator }) => {
    if (!list || list.length < 1) {
        return null;
    }

    return list.map((listItem, index) => (
        <LinkComponent
            to={lodashGet(listItem, 'link', '')}
            key={lodashGet(listItem, 'genre', '')}
            title={lodashGet(listItem, 'title', '')}
            className="link-theme-secondary"
        >
            {` ${firstLetterUpper(lodashGet(listItem, 'genre', ''))}`}
            {index === list.length - 1 ? '' : separator}
        </LinkComponent>
    ));
};

export const getContentType = ({ contentDetails }) => {
    const { programType, channelId } = contentDetails;
    if (channelId && programType === PROGRAM_TYPES.EPISODE) {
        return PROGRAM_TYPES_CATEGORY.CATCHUP;
    }
    return PROGRAM_TYPE_CATGEGORY_MAP[programType];
};

export const addOpacityToRGB = ({ colorInRGB, opacity = 1 }) => colorInRGB.slice(0, -1).concat(`, ${opacity}`).concat(colorInRGB.slice(-1));

export default {
    isStandalone,
    getTimeStamp,
    percentCalculation,
    getRandomInt,
    removeFalsy,
    getClosestElement,
    parseLiveTvCooke,
    convertToJSON,
    toTitleCase,
    stringPadding,
    secondToHours,
    secondToMinutes,
    hrefEscape,
    removeFromArray,
    isValidEmail,
    getSourceName,
    objectToArray,
    pad,
    getMetaTitleArtist,
    getMetaDescriptionArtist,
    getMetaLayout,
    removeConsecutiveRepeatedChar,
    trimCaptionsForUrl,
    replaceAll,
    urlStringToCaption,
    convertToNumber,
    clearAllBrowserStorage,
    handlePasteNumericData,
    decryptSecretKey,
    getWeek,
    getDayMonth,
    isItemObject,
    onInstallClick,
    isObjEmpty,
    disableScrollOnWheel,
    getCurrentProgram,
    createMockEPG,
    getTitleByProgramType,
    truncateWithEllipses,
    getTileFromRelationType,
    getPageSeoMeta,
    encodeContentId,
    decodeContentId,
    getHostFromUrl,
    getUuid,
    getAppVersion,
    getBuildNumber,
    getNetworkQuality,
    getDeviceType,
    getAppId,
    getSocialShareInfo,
    calculateProgressPercentage,
    continueWatchingTileTitle,
    isArrayofObjectsEqual,
    findTileLimit,
    getMetaDescriptionGenre,
    findLanguageFromCode,
    packageIdForListingPage,
    getTileType,
    stringifyInfinityValuesObject,
    parseInfinityValuesObject,
    getListOfLinks,
    firstLetterUpper,
    getLangfromCode,
    getMetaTitleSchedulePage,
    getMetaDescriptionSchedulePage,
    getContentType,
    addOpacityToRGB,
};

/* eslint-disable camelcase */
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { LocationUtil, DateTime } from '@airtel-tv/utils';
import { SIDE_BAR_CONSTS } from '@airtel-tv/constants';
import { PROGRAM_TYPE_TITLE_MAPPING } from '../constants/AppConst';
import { encodeForUrl } from '@airtel-tv/utils/WindowUtil';
import { DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { trimCaptionsForUrl } from './CommonUtil';
import { LISTING_PAGE_ID_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { matchRoutes } from 'react-router';

export default class RoutingUtil {
    static get404Route() {
        return '/404';
    }

    static getPreferredPartnerRoute() {
        return '/select/partner';
    }

    static getEPGSchedulePage(id = '') {
        const queryParams = LocationUtil.objectToQueryParams({
            id,
        });
        return `/live-tv/channel?${queryParams}&&viewType=webview`;
    }

    static getSearchListingUrl({
        category, genre, lang, title, sortBy, query,
    }) {
        const trimmedTitle = trimCaptionsForUrl(title);

        const queryParams = LocationUtil.objectToQueryParams({
            category: (category || '').toLowerCase(),
            genre,
            lang,
            sortBy,
            query,
        });

        return `/search/list/${encodeForUrl(trimmedTitle)}?${queryParams}`;
    }

    static getSearchResultUrl({ title, query }) {
        const searchUrlWithViewType = this.getSearchPage('default');
        const queryParams = LocationUtil.objectToQueryParams({
            q: query,
        });
        return `${searchUrlWithViewType}&&${queryParams}`;
    }

    static getPackageListingUrl({
        title, pageUrlName, railId, packageId,
    }) {
        const pageTitle = pageUrlName || DEFAULT_NAV.HOME;
        const trimmedTitle = trimCaptionsForUrl(title);

        if (railId) {
            return `/${pageTitle}/${trimmedTitle}/list/${railId}`;
        }
        if (packageId) {
            return `/${pageTitle}/${trimmedTitle}/list/${packageId}?type=${LISTING_PAGE_ID_TYPES.PACKAGE}`;
        }

        return null;
    }

    static getSettingsURL({ settingPage }) {
        return `/settings/${settingPage}`;
    }

    static getHomePage() {
        return '/';
    }

    static getSearchPage(viewType = 'default') {
        return `/search?viewType=${viewType}`;
    }

    static getChannelPage() {
        return '/channel';
    }

    static getPlansOffersPage({
        cpId = '', contentId = '', viewType = 'default', redirectToContent = false, id = '', source_tab = '', source_button = '',
    }) {
        return `${ROUTE_PATHS?.SUBSCRIPTION_PAGE || ''}?viewType=${viewType}&&${cpId ? LocationUtil.objectToQueryParams({
            cpId,
            id,
            redirectToContent,
            contentId,
            source_tab,
            source_button,
        }) : LocationUtil.objectToQueryParams({
            source_tab,
            source_button,
        })}`;
    }

    static getCpSubscriptionPage({ cpId = '', viewType = 'default' }) {
        return `/plans/subscription?viewType=${viewType}&&${LocationUtil.objectToQueryParams({ cpId })}`;
    }

    static getOneHubPageCDP({ ingressIntent, cpId }) {
        if (!ingressIntent) {
            return ROUTE_PATHS.ONEHUB.replace('/:ingressIntent?', '').replace('/:cpId?', '');
        }
        const cp = cpId ? `/${cpId}` : '';
        return ROUTE_PATHS.ONEHUB.replace(':ingressIntent?', ingressIntent).replace('/:cpId?', cp);
    }

    static getCPActivationPage({ cpId = '' }) {
        return (cpId ? ROUTE_PATHS.PLAN_ACTIVATION?.replace(':cpId', cpId) : RoutingUtil.getOneHubPageCDP({}));
    }

    static getPlansPaymentsPage({
        planId = '', redirectToContent = false, planCpId: cpId = '', source_button = '', source_tab = '',
    }) {
        return `/plans/payments?viewType=webview&&${LocationUtil.objectToQueryParams({
            redirectToContent,
            planId,
            cpId,
            source_button,
            source_tab,
        })}`;
    }

    static getContentPlaybackUrl({
        category = 'VIDEO', contentTitle = 'unknown', contentId, sourceTab = '', addtionalQueryParams,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;

        const trimmedCategory = trimCaptionsForUrl(pageTitle);

        const trimmedTitle = trimCaptionsForUrl(contentTitle);

        const pathParams = [
            trimmedCategory,
            trimmedTitle,
            contentId,
            // encodeContentId(contentId),
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview&&source_tab=${sourceTab}${addtionalQueryParams}`;
    }

    static getArtistUrl({ name = 'unknown', id }) {
        const trimmedName = trimCaptionsForUrl(name);
        return `/artist/${trimmedName}/${id}?viewType=webview`;
    }

    static getCustomLayoutUrlWithViewType({ urlName, viewType = 'default', id = '' }) {
        if (id === SIDE_BAR_CONSTS.SUBSCRIPTION_PAGE) {
            return RoutingUtil.getPlansOffersPage({
                viewType: 'webview',
                source_tab: SIDE_BAR_CONSTS.SUBSCRIPTION_PAGE,
            });
        }
        const nav = urlName === DEFAULT_NAV.HOME ? '' : urlName;
        const navViewType = (viewType === 'default' || viewType === '') ? null : viewType;
        return `/${nav}${navViewType ? `?viewType=${navViewType}` : ''}`;
    }

    static getCustomLayoutUrl({ urlName }) {
        const nav = urlName === DEFAULT_NAV.HOME ? '' : urlName;
        return `/${nav}`;
    }

    static getTvEpisodeUrl({
        programType, title, episodeNumber, episodeName, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle),
            trimCaptionsForUrl(title),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            trimCaptionsForUrl(episodeNumber ? `episode-${episodeNumber}` : '') + (episodeName ? `-${trimCaptionsForUrl(episodeName)}` : ''),
            contentId,
            // encodeContentId(contentId),
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview&nextEpisode=true`;
    }

    static getTvEpisodePlaybackUrl({
        programType, title, episodeNumber, episodeName, seasonName, contentId, cpId = '',
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            // trimCaptionsForUrl('playback'),
            trimCaptionsForUrl(pageTitle),
            trimCaptionsForUrl(title),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            trimCaptionsForUrl(episodeNumber ? `episode-${episodeNumber}` : '') + (episodeName ? `-${trimCaptionsForUrl(episodeName)}` : ''),
            contentId,
            // encodeContentId(contentId),
        ];
        return `/playback/${pageTitle}/${cpId}/${title}/${contentId}?viewType=webview`;
    }

    static getTvSeasonUrl({
        programType, title, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(title || ''),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            contentId,
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getLiveTvShowUrl({ channelName, showName, contentId }) {
        // backend sending ProgramType : EPISODE instead of LIVETVEPISODE : with regrets, have to hard code
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(channelName || ''),
            trimCaptionsForUrl(showName ? `${showName}` : ''),
            contentId,
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getLiveEpisodeShowUrl({
        channelName,
        showName,
        episodeReleaseDT,
        episodeNumber,
        contentId,
        tvShowId,
    }) {
        // backend sending ProgramType : EPISODE instead of LIVETVEPISODE : with regrets, have to hard code
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;

        let episodeTitle = '';

        if (episodeReleaseDT) {
            episodeTitle = new DateTime(episodeReleaseDT).format('DD-MMMM-YYYY');
        }

        if (episodeNumber) {
            episodeTitle = `${episodeTitle}-episode-${episodeNumber}`;
        }

        const pathParams = [
            (pageTitle),
            trimCaptionsForUrl(channelName || ''),
            trimCaptionsForUrl(showName ? `${showName}` : 'default'),
            trimCaptionsForUrl(episodeTitle || ''),
            tvShowId,
            contentId,
        ];
        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getMovieCatchupPlaybackUrl({
        category = 'VIDEO', contentTitle = 'unknown', contentId, channelName,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;
        const trimmedCategory = trimCaptionsForUrl(pageTitle || '');
        const trimmedTitle = trimCaptionsForUrl(contentTitle || '');
        const trimmedChannelName = trimCaptionsForUrl(channelName || '');

        const pathParams = [
            trimmedCategory,
            trimmedChannelName,
            trimmedTitle,
            contentId,
        ];


        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static isCdpRoute(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const isLinkToCdp = (matchedRoutes || []).find(r => (!!r?.route?.isCDP));
        return isLinkToCdp;
    }

    static isSearchPage(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const isSearchPage = (matchedRoutes || []).find(r => (!!r?.route?.isSearchPage));
        return isSearchPage;
    }

    static getDimensionPageUrl({
        pageTitle = 'movies',
        lang = '',
        genres = '',
        queryParams,
    }) {
        const trimmedPageTitle = trimCaptionsForUrl(pageTitle || '');

        const trimmedLang = lang && lang.length > 0 ? trimCaptionsForUrl(lang) : '';

        const trimmedGenres = genres && genres.length > 0 ? trimCaptionsForUrl(genres) : '';

        const pathParams = [
            trimmedPageTitle,
        ];
        if (trimmedLang) {
            pathParams.push(trimmedLang);
        }
        if (trimmedGenres) {
            pathParams.push(trimmedGenres);
        }
        pathParams.push(`collection${queryParams}`);
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getSchedulePageUrl({ channelName = 'unknown', channelId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;
        const trimmedTitle = trimCaptionsForUrl(channelName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            'schedule',
            channelId,
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getChannelPartnerPageUrl({ channelPartnerName = 'unknown', pageLink }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.CHANNEL_PARTNER_PAGE;
        const trimmedTitle = trimCaptionsForUrl(channelPartnerName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            pageLink,
            'detail',
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getEventsPageUrl({ eventName, eventId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.PROMOTION_PAGE;
        const trimmedTitle = trimCaptionsForUrl(eventName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            eventId,
            'detail',
        ];
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getPlaybackRoute(contentDetails) {
        const title = (contentDetails.title || '').replace('#', '');
        return `/playback/${contentDetails.programType}/${contentDetails.cpId}/${title} /${contentDetails.id}?viewType=webview`;
    }

    static getDeeplink({ deepLink }) {
        if (deepLink.includes('type=OTT_PURCHASE')) {
            return `${ROUTE_PATHS.SUBSCRIPTION_PAGE}?viewType=webview&&source_button=XPP_BANNER&&source_tab=HOME`;
        }
        return null;
    }
}

import packageJson from '../../package.json';
import { AppEnv } from '@airtel-tv/utils/GetEnv';

let reactAppEnv = 'uat'; // default env is development

const REACT_APP_ENV = process.env.REACT_APP_ENV;
const osType = process.env.osType;
if (REACT_APP_ENV) {
    reactAppEnv = REACT_APP_ENV;
}
const env = require(`./${reactAppEnv}.json`); // eslint-disable-line import/no-dynamic-require
env.endpoints = env[`endpoints${env.orgId}`];
env.osType = osType;

AppEnv.setEnv(env, packageJson.version, packageJson.buildNumber);

export default AppEnv.getEnv();

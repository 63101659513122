import { AppEnv } from '@airtel-tv/utils/GetEnv';
import packageJson from '../../package.json';

let reactAppEnv = 'uat'; // default env is development

const REACT_APP_ENV = process.env.REACT_APP_ENV;

if (REACT_APP_ENV) {
    reactAppEnv = REACT_APP_ENV;
}

const env = require(`./${reactAppEnv}.json`); // eslint-disable-line import/no-dynamic-require

AppEnv.setEnv(env, packageJson.version);

export default AppEnv.getEnv();

import React, { lazy, Suspense } from 'react';
import Loadable from 'react-loadable';


const AsyncFooterTileComponent = Loadable({
    loader: () => import('../../modules/shared/components/tile/FooterTileComponent' /* webpackChunkName: "AsyncFooterTileComponent" */),
    loading: () => <div/>,
});

const AsyncHeaderTileComponent = Loadable({
    loader: () => import('../../modules/shared/components/tile/HeaderTileComponent' /* webpackChunkName: "AsyncHeaderTileComponent" */),
    loading: () => <div/>,
})

const AsyncContinueWatchingTileComponent = Loadable({
    loader: () => import('../../modules/shared/components/tile/ContinueWatchingTileComponent' /* webpackChunkName: "AsyncContinueWatchingTileComponent" */),
    loading: () => <div/>,
})

export const LazyFooterTileComponent = props => (
        <AsyncFooterTileComponent {...props} />
);

export const LazyHeaderTileComponent = props => (
        <AsyncHeaderTileComponent {...props} />
);

export const LazyContinueWatchingTileComponent = props => (
        <AsyncContinueWatchingTileComponent {...props} />
);

export default {
    LazyFooterTileComponent,
    LazyHeaderTileComponent,
    LazyContinueWatchingTileComponent,
};

export const LIVE_CHANNEL_FILTER_IMAGE_PATHS = {
    MOVIES: '/static/live-channel-filter/movies.svg',
    LANGUAGE: '/static/live-channel-filter/language.svg',
    ADD_LANGUAGE: '/static/live-channel-filter/add.svg',
    LEFT_ARROW: '/static/live-channel-filter/left-arrow.svg',
    RIGTH_ARROW: '/static/live-channel-filter/rigth-arrow.svg',
    CHANNEL_NDTV: '/static/live-channel-filter/ndtv-logo.png',
    CANCEL_ARROW: '/static/live-channel-filter/cancel-arrow.svg',
    CHECK_ARROW: '/static/live-channel-filter/black-check-arrow.svg',
    POPUP_CROSS: '/static/modalpopup_asset/modal-cross.svg',
    DOWN_ARROW: '/static/live-channel-filter/down-arrow.svg',
};

export const LIVE_CHANNEL_FILTER_OPTION = {

};

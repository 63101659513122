const subscribers = {};

export const publish = (event, data) => {
    if (!subscribers[event]) {
        return;
    }
    subscribers[event].forEach(subscriberCallback => subscriberCallback(data));
};
export const subscribe = (event, callback) => {
    let index;
    if (!subscribers[event]) {
        subscribers[event] = [];
    }
    // eslint-disable-next-line prefer-const
    index = subscribers[event].push(callback) - 1;
    return {
        unsubscribe() {
            subscribers[event].splice(index, 1);
        },
    };
};

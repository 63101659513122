export const CONTENT_IMAGE_TYPES = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE_169: 'LANDSCAPE_169',
    LANDSCAPE_43: 'LANDSCAPE_43',
    LOGO: 'LOGO',
    LOGOS_TNPT: 'LOGOS_TNPT',
    FEATURE_BANNER: 'FEATURE_BANNER',
    FEATURE_BANNER_HD: 'FEATURE_BANNER_HD',
    RECTANGULAR: 'RECTANGULAR',
    CIRCULAR: 'CIRCULAR',
    SQUARE: 'SQUARE',
};

export const IMAGE_PATHS = {
    CHEV_LEFT: '/static/left-chev.svg',
    XSTREAMPREMIUM: '/static/xstream-premium.svg',
};

export default {
    CONTENT_IMAGE_TYPES,
    IMAGE_PATHS,
};

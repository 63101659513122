export const CONTENT_IMAGE_TYPES = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE_169: 'LANDSCAPE_169',
    LANDSCAPE_43: 'LANDSCAPE_43',
    LOGO: 'LOGO',
    LOGOS_TNPT: 'LOGOS_TNPT',
    FEATURE_BANNER: 'FEATURE_BANNER',
    RECTANGULAR: 'RECTANGULAR',
    CIRCULAR: 'CIRCULAR',
    SQUARE: 'SQUARE',
    PORTRAIT_HD: 'PORTRAIT_HD',
    LANDSCAPE_169_HD: 'LANDSCAPE_169_HD',
    LANDSCAPE_43_HD: 'LANDSCAPE_43_HD',
    LOGO_HD: 'LOGO_HD',
    LOGOS_TNPT_HD: 'LOGOS_TNPT_HD',
    FEATURE_BANNER_HD: 'FEATURE_BANNER_HD',
    RECTANGULAR_HD: 'RECTANGULAR_HD',
    CIRCULAR_HD: 'CIRCULAR_HD',
    SQUARE_HD: 'SQUARE_HD',
};

export default {
    CONTENT_IMAGE_TYPES,
};

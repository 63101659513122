
export const MARKUP_CONSTS = {
    CONTEXT: 'http://schema.org',
    RELATED_CACHE: 'RELATED_CACHE',
    CHANNEL_CACHE: 'CHANNEL_CACHE',
    EPG_CACHE: 'EPG_CACHE',
};
export const MARKUP_TYPES = {
    VIDEO_OBJECT: 'VideoObject',
    MOVIE: 'Movie',
    ENTRY_POINT: 'EntryPoint',
    WATCH_ACTION: 'WatchAction',
    TVSEASON: 'TVSeason',
    TVSERIES: 'TVSeries',
    TVSHOW: 'TVShow',
    TVEPISODE: 'TVEpisode',
    ITEM_LIST: 'ItemList',
    LIST_ITEM: 'ListItem',
    LIVETV: 'TelevisionChannel',
    BREADCRUMBLIST: 'BreadcrumbList',
    THING: 'Thing',
    WEBSITE: 'Website',
    SEARCH_ACTION: 'SearchAction',
    ORGANIZATION: 'Organization',
    VIEW_ACTION: 'ViewAction',
};
export const MARKUP_EXTRA_CONSTS = {
    ACTOR: 'actor',
    DIRECTOR: 'director',
    PERSON: 'Person',

    TVSHOW: 'TVSeries',
    TVSHOW_SEASON: 'TVSeason',
};
export const MARKUP_CONTEXT = {
    URL: 'http://schema.org',
};
export default {
    MARKUP_CONSTS,
    MARKUP_TYPES,
    MARKUP_CONTEXT,
    MARKUP_EXTRA_CONSTS,
};

import { DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import variables from '../modules/shared/scss/abstracts/_variables.scss';

export const LANGUAGES = {
    ENGLISH: 'en',
    HINDI: 'hi',
    THAILAND: 'th',
};

export const MAX_LANGUAGE_SELECTION_LIMIT = 4;

export const maxClubbedPartnerCpImagesLimit = 4;

export const LANGUAGE_CONTAINER_TYPE = {
    OLD: 'OLD',
    NEW: 'NEW',
};


export const LANGUAGES_CODES = {
    th: 'Thai',
    en: 'English',
    eng: 'English',
    hi: 'Hindi',
    as: 'Assamese',
    bn: 'Bengali',
    bho: 'Bhojpuri',
    gu: 'Gujrati',
    ha: 'Haryanvi',
    kn: 'Kannada',
    ml: 'Malayalam',
    mr: 'Marathi',
    or: 'Oriya',
    ph: 'Pahadi',
    pa: 'Punjabi',
    ra: 'Rajasthani',
    ta: 'Tamil',
    te: 'Telugu',
    ur: 'Urdu',
    bh: 'Bhojpuri',
    id: 'Indonesian',
    ru: 'Russian',
    fil: 'Filipino',
    tl: 'Tagalog',
    vi: 'Vietnamese',
};

export const DROPDOWN_NAMES = {
    LANGUAGE: 'Language',
    TYPE: 'Type',
    GENRE: 'Genres',
    SORT: 'Sort',
    NONE: 'None',
    ALL: 'All',
};

export const LOCAL_STORE_KEYS = {
    TOKEN: 'token',
    UID: 'uid',
    MSISDN_DETECTED: 'msisdnDetected',
    LAST_WATCHED: 'lw',
    SEARCH_QUERIES: 'SEARCH_QUERIES',
    ACCESS_TOKEN: 'authToken',
    VISIT_COUNT: 'visit_count',
    LOGIN_COUNT: 'login_count',
    SHOW_APP_HOOK: 'show_app_hook',
    VISIT_COUNT_AFTER_HIDE_APP_HOOK: 'v_after_hide_app_hook',
    USER_DATA: 'user_data',
    FINGER_PRINT: 'finger_print',
    TEMP_LANG: 'temp_lang',
    USER_LANG: 'user_lang',
    PREFERRED_PARTNER_PLANS_REFIDS: 'preferredPartnerPlanRefIds',
    XPP_CLAIM_DISPLAYED: 'XPP_CLAIM_DISPLAYED',
    SEARCH_SESSION_CODE: 'SEARCH_SESSION_CODE',
    ROOT_FONT_SIZE: 'ROOT_FONT_SIZE',

    // player keys
    VOLUME_LEVEL: 'x-player-volume',
    BIT_RATE: 'x-player-quality',
    STORED_LAYOUT_DATA: 'STORED_LAYOUT_DATA',
    UDID: 'UDID',
    IS_INSTALLED: 'isInstalled',
    brand: 'brand',
    dname: 'dname',
    model: 'model',
    osVersion: 'osVersion',
    SUBSCRIPTION_DETAILS: 'SUBSCRIPTION_DETAILS',
    ACTIVATION_PENDING: 'ACTIVATION_PENDING',
};

export const APP_HOOK_VISIBLE_AFTER_VIS_COUNT = 3;

export const ROLE_TYPES = {
    DIRECTOR: 'director',
    ACTOR: 'actor',
};

export const PROGRAM_TYPES = {
    CHANNEL: 'CHANNEL',
    MOVIE: 'MOVIE',
    SEASON: 'SEASON',
    SHORTMOVIE: 'SHORTMOVIE',
    VIDEO: 'VIDEO',
    EPISODE: 'EPISODE',
    SERIES: 'SERIES',
    SPORTS: 'SPORTS',
    TVSHOW: 'TVSHOW',
    OTHER: 'OTHER',
    PEOPLE: 'PEOPLE',
    LIVE: 'LIVE',
    LIVETVSHOW: 'LIVETVSHOW',
    LIVETVCHANNEL: 'LIVETVCHANNEL',
    LIVETVMOVIE: 'LIVETVMOVIE',
    TRAILER: 'TRAILER',
    AD: 'AD',
    NEWS: 'NEWS',
    MATCH: 'MATCH',
    PROGRAM: 'PROGRAM',
    CATCHUP_EPISODE: 'CATCHUP_EPISODE',
    PACKAGE: 'PACKAGE',
    EVENT: 'EVENT',
    PROMO: 'PROMO',
};

export const PROGRAM_TYPES_CATEGORY = {
    VOD: 'vod',
    CATCHUP: 'catchup',
    LIVE: 'live',
};

export const DIMENSION_PAGE_MAP = {
    MOVIE: 'movies',
    LIVETVMOVIE: 'movies',

    TVSHOW: 'tv-shows',
    EPISODE: 'tv-shows',

    LIVETVSHOW: 'tv-shows',
    LIVETVCHANNEL: 'livetv-channels',

    VIDEO: 'video',
};

export const DIMENSION_URL_TO_TYPE = {
    live: 'LIVETVCHANNEL',
    tv_shows: 'TVSHOW',
    movies: 'MOVIE',
    videos: 'VIDEO',
};

export const DIMENSIONS_FOR_CALL_MAP = {
    MOVIE: `${PROGRAM_TYPES.MOVIE},${PROGRAM_TYPES.LIVETVMOVIE}`,
    LIVETVMOVIE: `${PROGRAM_TYPES.MOVIE},${PROGRAM_TYPES.LIVETVMOVIE}`,
    VIDEO: `${PROGRAM_TYPES.VIDEO}`,
    TVSHOW: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    EPISODE: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    SEASON: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,

    LIVETVSHOW: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    LIVETVCHANNEL: PROGRAM_TYPES.LIVETVCHANNEL,
};

export const DIMENSIONS_SUPPORTED_MAP = {
    MOVIE: PROGRAM_TYPES.MOVIE,
    LIVETVMOVIE: PROGRAM_TYPES.MOVIE,
    VIDEO: PROGRAM_TYPES.VIDEO,

    TVSHOW: PROGRAM_TYPES.TVSHOW,
    EPISODE: PROGRAM_TYPES.TVSHOW,
    SEASON: PROGRAM_TYPES.TVSHOW,

    LIVETVSHOW: PROGRAM_TYPES.TVSHOW,
    LIVETVCHANNEL: PROGRAM_TYPES.LIVETVCHANNEL,
    PROGRAM: PROGRAM_TYPES.LIVETVCHANNEL,
};

export const DIMENSION_PAGE_CONTENT_LIMIT = {
    GOOGLE_BOT: 200,
    USER: 30,
};

export const DROPDOWN_CONTENT_NAME_LIMIT = 15;

export const DROPDOWN_NAMES_LIMIT = {
    MOBILE: 10,
    DESKTOP: 15,
};

export const SCREEN_SIZES = {
    IPAD_SIZE: 768,
    MOBILE_MEDIUM: 577,
    MOBILE_SMALL: 481,
    MOBILE_MINI: 321,
};

export const DROP_DOWN_LIMIT = 6;

export const DIMENSION_SORT_OPTIONS = {
    NONE: 'NONE',
    ALPHABETIC_ASCENDING: 'ALPHABETIC_ASCENDING',
    ALPHABETIC_DESCENDING: 'ALPHABETIC_DESCENDING',
    NEWLY_ADDED: 'NEWLY_ADDED',
};

export const DIMENSION_SORT_DISPLAY = {
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING]: 'A-Z',
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING]: 'Z-A',
    [DIMENSION_SORT_OPTIONS.NEWLY_ADDED]: 'Recent',
    [DIMENSION_SORT_OPTIONS.NONE]: 'None',
    [DROPDOWN_NAMES.ALL]: 'All',
};

export const PLAYBACK_CHECK = {
    BROWSER: 'BROWSER',
    TV: 'TV',
};

export const DIMENSION_SORT_API_OPTION = {
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING]: 'asc',
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING]: 'desc',
    [DIMENSION_SORT_OPTIONS.NEWLY_ADDED]: 'desc',
};

export const DIMENSION_PROGRAMTYPE_MAP = {
    [PROGRAM_TYPES.MOVIE]: 'Movies',
    [PROGRAM_TYPES.TVSHOW]: 'TV Shows',
    [PROGRAM_TYPES.LIVETVCHANNEL]: 'Channels',
    [PROGRAM_TYPES.LIVETVSHOW]: 'Live TV',
    [PROGRAM_TYPES.VIDEO]: 'Video',
};

export const PROGRAM_TYPE_CATGEGORY_MAP = {
    [PROGRAM_TYPES.CHANNEL]: 'live',
    [PROGRAM_TYPES.MOVIE]: 'vod',
    [PROGRAM_TYPES.SEASON]: 'vod',
    [PROGRAM_TYPES.SHORTMOVIE]: 'vod',
    [PROGRAM_TYPES.VIDEO]: 'vod',
    [PROGRAM_TYPES.EPISODE]: 'vod',
    [PROGRAM_TYPES.SERIES]: 'vod',
    [PROGRAM_TYPES.SPORTS]: 'vod',
    [PROGRAM_TYPES.TVSHOW]: 'vod',
    [PROGRAM_TYPES.OTHER]: 'vod',
    [PROGRAM_TYPES.PEOPLE]: 'vod',
    [PROGRAM_TYPES.LIVE]: 'live',
    [PROGRAM_TYPES.LIVETVSHOW]: 'catchup',
    [PROGRAM_TYPES.LIVETVCHANNEL]: 'live',
    [PROGRAM_TYPES.LIVETVMOVIE]: 'catchup',
    [PROGRAM_TYPES.TRAILER]: 'vod',
    [PROGRAM_TYPES.NEWS]: 'live',
    [PROGRAM_TYPES.MATCH]: 'live',
    [PROGRAM_TYPES.PROGRAM]: 'live',
    [PROGRAM_TYPES.CATCHUP_EPISODE]: 'catchup',
};

export const PROGRAM_TYPE_TITLE_MAPPING = {
    MOVIE: 'movies',

    LIVE: 'live',
    LIVETVSHOW: 'livetv-channels',
    LIVETVCHANNEL: 'livetv-channels',
    LIVETVMOVIE: 'livetv-channels',
    CATCHUP_EPISODE: 'livetv-channels',

    SEASON: 'tv-shows',
    EPISODE: 'tv-shows',
    TVSHOW: 'tv-shows',

    CHANNEL: 'channel',
    CHANNEL_PARTNER_PAGE: 'channel',

    TRAILER: 'trailer',
    AD: 'ad',
    NEWS: 'news',
    MATCH: 'match',
    PROGRAM: 'program',
    SHORTMOVIE: 'short-movies',
    VIDEO: 'videos',
    SERIES: 'series',
    SPORTS: 'sports',
    OTHER: 'videos',
    PEOPLE: 'people',
    PROMOTION_PAGE: 'event',
};

export const COMPONENT_TYPE = {
    ACCORDIAN: 'accordian',
    HTML: 'html',
    ANCHOR: 'a',
    LINK: 'link',
    ANALYTIC: 'analytic',
    PROFILE: 'profile',
};

export const IMAGE_PATHS = {
    DEFAULT_CAST: './static/image-icon-default.svg',
    BUY_SUBSCRIPTION: './static/error/renew-subscription.svg',
    HELP_DESK: './static/error/notify-helpdesk.svg',
    MANAGE_DEVICES: './static/error/manage-your-devices.svg',
    AIRTEL_LOGO: './static/splash_logo.png',
    AIRTEL_DARK_LOGO: './static/splash_logo.png',
    UNLIMITED_ENTERTAINMENT: './static/introduction-slider/slider-1.svg',
    BEST_IN_CLASS_VIEWING_EXPERIENCE: './static/introduction-slider/slider-2.svg',
    AIRTEL_TV_ANYTIME_ANYWHERE: './static/introduction-slider/slider-3.svg',
    PLAYER_ERROR: './static/error/player-error.svg',
    NETWORK_ERROR: './static/connection-problem.svg',
    ERROR_PAGE_OPSS: './static/error-page/error-loading.svg',
    ERROR_PAGE_404: './static/error-page/error-404.svg',
    DEFAULT_PLAYER_BACKGROUND: 'https://image.airtel.tv/grandslam/content/2024_10_3/placeholder-player.jpg',
    [ERROR_CODES.ATV262]: './static/error/not-found.svg',
    FOOTER_PLAY_STORE: './static/button-playstore.svg',
    FOOTER_APP_STORE: './static/button-appstore.svg',
    PLAYER_PLACEHODER: './static/placeholder-player.jpg',
    APP_FLAYER_CLOSE_BTN: './static/ic_actionbar_close.svg',
    UPGRADE_TO_PREMIUM: 'static/upgrade-to-premium.svg',
    [DEFAULT_NAV.HOME]: './static/og-images/general.png',
    [PROGRAM_TYPE_TITLE_MAPPING.LIVETVCHANNEL]: './static/og-images/livetv.png',
    [PROGRAM_TYPE_TITLE_MAPPING.MOVIE]: './static/og-images/movie.png',
    [PROGRAM_TYPE_TITLE_MAPPING.TVSHOW]: './static/og-images/tvshow.png',
    WHITE_CLOSE_BUTTON: './static/close.svg',
    WATCHLIST_ICONS_ADD: './static/add-watchlist-icon.svg',
    WATCHLIST_ICON_LOGO: './static/webtv-watchlist-logo.svg',
    WATCHLIST_ICONS_REMOVE: './static/remove-watchlist-icon.svg',
    HELP_SUPPORT_ICONS_ADD: './static/more-menu/help-icon.svg',
    LOGOUT_ICONS_ADD: './static/more-menu/logout-icon.svg',
    DOWNLOAD_ICONS_ADD: './static/more-downloads.svg',
    FEEDBACK_ICONS_ADD: './static/more-menu/feedback-icon.svg',
    WATCHLIST_EMPTY_STATE_ICONS: './static/watchlist-empty.svg',
    CHEVERON_ICONS: './static/cheveron.svg',
    CLOSE_BUTTON_OVERLAY: './static/dimensionPages/closeButton.svg',
    TICK_MARK: './static/dimensionPages/TickMark.svg',
    SORT_BUTTON: './static/dimensionPages/sortButton.svg',
    CEHVRON_DROPDOWN: './static/dimensionPages/DropDownChevron.svg',
    SELECTED_FIELD: './static/dimensionPages/selectedField.svg',
    BANNER_LEFT: './static/chevron-left.svg',
    BANNER_RIGHT: './static/chevron-right.svg',
    LIVE_TAG: './static/schedule_rails_asset/live.svg',
    RAIL_TITLE_ARROW: './static/schedule_rails_asset/title-link.svg',
    TILE_LINK_ARROW: './static/schedule_rails_asset/white-arrow.svg',
    RIGHT_BUTTON: './static/schedule_rails_asset/next-button.svg',
    LEFT_BUTTON: './static/schedule_rails_asset/prev-button.svg',
    ALERT_BELL: './static/schedule_page_asset/alertbell.svg',
    LANGUAGE_ICON: './static/nav-icons/top-nav-language-icon.svg',
    PROFILE_ICON: './static/nav-icons/profile-icon.svg',
    APP_HOOK_INSTALL: './static/nav-icons/top-nav-download-icon.svg',
    SEARCH_ICON: './static/nav-icons/top-nav-search-icon.svg',
    HOME_ICON: './static/nav-icons/home-icon.svg',
    BOTTOM_NAV_SEARCH_ICON: './static/nav-icons/bottom-nav-search-icon.svg',
    MENU_ICON: './static/nav-icons/menu-icon.svg',
    YOU_ICON: './static/nav-icons/you-icon.svg',
    CANCEL_ICON: './static/search/cancel.svg',
    RECENT_SEARCH_ICON: './static/search/recent.svg',
    TRENDING_SEARCH_ICON: './static/search/trending-search.svg',
    VOLUME_ZERO_ICON: './static/volume-button/volume-zero-kc.svg',
    VOLUME_LOW_ICON: './static/volume-button/volume-low-kc.svg',
    VOLUME_HIGH_ICON: './static/volume-button/volume-high-kc.svg',
    BACK_ARROW: './static/back-arrow.svg',
    NEXT_EPISODE: './static/play-back-controls/skip_next.svg',
    SETTINGS: './static/play-back-ui/settings-top.svg',
    RIGHT_ARROW: './static/play-back-controls/right-arrow.svg',
    LEFT_ARROW: './static/play-back-controls/left-arrow.svg',
    FORWARD_SEEK: './static/play-back-controls/forward-seek.svg',
    DISABLED_FORWARD_SEEK: './static/play-back-controls/disabled-forward-seek.svg',
    BACKWARD_SEEK: './static/play-back-controls/backward-seek.svg',
    DISABLED_BACKWARD_SEEK: './static/play-back-controls/disabled-backward-seek.svg',
    PAUSE: '/static/play-back-controls/pause.svg',
    PLAY: '/static/play-back-controls/play.svg',
    PIP_BUTTON: './static/play-back-controls/PiPButton.svg',
    DTH_ASSET_VIDEO_ICON: './static/largescreen/plan-list-assets/movies.png',
    DTH_ASSET_SUBTITLE: './static/largescreen/plan-list-assets/multi-audio.png',
    DTH_ASSET_PLAY_ICON: './static/largescreen/plan-list-assets/watchlist.png',
    DTH_EROS_NOW_ICON: './static/largescreen/plan-list-assets/eros.png',
    DTH_HUNGAMA_ICON: './static/largescreen/plan-list-assets/Hungama.png',
    DTH_SHEMAROO_ICON: './static/largescreen/plan-list-assets/Shemaroo.png',
    DTH_HOICHOI_ICON: './static/largescreen/plan-list-assets/hich.png',
    DTH_ULTRA_ICON: './static/largescreen/plan-list-assets/Ultra.png',
    DTH_VOOT_ICON: './static/largescreen/plan-list-assets/voot.png',
    PIP_ICON: './static/pip_assets/pip-icon.svg',
    SUBSCRIBED_TICK: './static/double-ticks.svg',
    REPLAY_BUTTON: './static/channel_partner/replay.svg',
    SUBSCRIBED_CHANNEL_TICKMARK: './static/channel_partner/subscribedTickmark.svg',
    SHARE_ICON: './static/share-icon.svg',
    WHATSAPP_ICON: './static/whatsapp-icon.svg',
    SHARE_BUTTON: './static/channel_partner/shareButton.svg',
    LOCK_ICON: './static/overlay/lock.svg',
    GIFT_ICON: './static/more-menu/gift-icon.svg',
    ERROR_MARKER: './static/Happy-code-assets/error-marker.svg',
    SUCCESS: './static/Happy-code-assets/sucess.svg',
    MODALPOPUP_CROSS: './static/modalpopup_asset/modal-cross.svg',
    GIFT_BOX: './static/modalpopup_asset/Gift_box.svg',
    PP_UNLOCK: './static/modalpopup_asset/unlock.svg',
    PP_BACK: './static/modalpopup_asset/back-arrow.svg',
    PP_BANNER: './static/modalpopup_asset/offer-banner.svg',
    PP_GIFT_CLOSE: './static/modalpopup_asset/gift-box-close.svg',
    SAD_EMOJI: './static/gratification-asset/sad-emoji.svg',
    GRATIFICATION_CROSS: './static/gratification-asset/gratification-cross.svg',
    OFFER_CHEV_LEFT: './static/pp_assets/chevron_left.svg',
    OFFER_CHEV_RIGHT: './static/pp_assets/chevron_right.svg',
    //* ** used in login ***/
    OTP_CONFIRMED: './static/confirmed.svg',
    DELETE_ONE: './static/ic_delete.svg',
    DELETE_ALL: './static/ic_remove.svg',
    LOGIN_RIGHT_ARROW: './static/right-cheveron.svg',
    EMPTY_SEARCH_ICON: './static/search/ic_search_empty.svg',
    SPACE_BAR_ICON: './static/search/space_icon.svg',
    EXIT_ICON: './static/ostv/ostv-exit.svg',
    WEBTV_PLAY_ICON: './static/webtv-play.svg',
    BACK_TOP_BUTTON: './static/play-back-ui/back-svg-top.svg',
    LEFT_SKIP: './static/play-back-ui/left-skip.svg',
    RIGHT_SKIP: './static/play-back-ui/right-skip.svg',
    SUB_TITLE_ICON: './static/play-back-ui/sub-title-icon.svg',
    SUB_TITLE_TICK: './static/play-back-ui/sub-title-tick.svg',
    WEBTV_PLANS_TICK: './static/browseplan-assets/tick.svg',
    ACCOUNT_INFO_ICON: './static/managesettings-icons/account-info.svg',
    MANAGE_PLAN_ICON: './static/managesettings-icons/manage-plan.svg',
    CONTENT_LANGUAGE_ICON: './static/managesettings-icons/content-language.svg',
    WEB_TV_HELP_ICON: './static/managesettings-icons/help.svg',
    WEB_TV_LOGOUT_ICON: './static/logout-icon-wtv.svg',
    WEB_TV_LOGIN_ICON: './static/login-icon.svg',
    PAYMENTS_BACK_ARROW: './static/payments-assets/payments-back.svg',
    EXPIRE_TAG: './static/browseplan-assets/expire.svg',
    RED_TICK: './static/browseplan-assets/red-tick.svg',
    PAYMENT_LOADER: './static/payment-loader.svg',
    LIVE_SCHEDULE: './static/live-schedule.svg',

    SPLASH_LOGO: './static/splash_logo.png',
    INPROGRESS_LOGO: './static/inprogress-subscription.svg',
    FAILURE_POPUP_LOGO: './static/payment-unscucess.svg',
    APP_LOGO: './static/splash_logo.png',
    START_STREAMING: './static/popcorn.svg',
    TICK_MARK1: './static/red-language-tick.svg',
    INFO_ICON: './static/info.svg',
    BACK_BUTTON: './static/back-button-image.png',
    PORT_AIRTEL: './static/port_airtel.svg',
    LOGIN_BACKGROUND: './static/login-background.png',
    ARROW: '/static/arrow-left.png',
    BLACK_HOLE: './static/astronaut.svg',
    DEVICE_ICON: './static/device-icon.svg',
    QR_ICON: './static/qr-icon.svg',
    SUCCESS_CHECK: './static/success-check.svg',
    GRADIENT_BACKGROUND: './static/gradient-background.svg',
    QR_LOADER: './static/qr-loader.svg',
};

export const IMAGE_SETTINGS = { 300: 15 };

export const CACHE_CONFIGS = {
    LOW_PRIORITY_CACHE: 'LOW_PRIORITY',
    HIGH_PRIORITY_CACHE: 'HIGH_PRIORITY',
    MEDIUM_PRIORITY_CACHE: 'MEDIUM_PRIORITY',
    CRITICAL_PRIORITY_CACHE: 'CRITICAL_PRIORITY',
    LIFE_TIME_CACHE: 'LIFE_TIME',
};

export const CP_DESCRIPTION_LIMIT = {
    DESKTOP: 400,
    MOBILE: 180,
};

export const ACTION_MODALS_TYPES = {
    HELP_DESK: 'HELP_DESK',
    BUY_SUBSCRIPTION: 'BUY_SUBSCRIPTION',
    MANAGE_DEVICES: 'MANAGE_DEVICES',
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
};

export const INTRODUCTION_MODALS_TYPES = {
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
};

export const REF_LINKS = {
    ABOUT_US: 'https://wynk.in/corporate/index.html',
    SWITCH_TO_AIRTEL_KNOW_MORE: 'https://airtel.in/',
    PLAY_STORE_LINK: 'https://open.airtelxstream.in/XFYAvSDfcgb',
    ITUNES_LINK: 'https://open.airtelxstream.in/9pnP8MJfcgb',
    WYNK_MUSIC_LINK: 'https://wynk.in/music',
    FEEDBACK_FORM: 'https://wynkproduct.typeform.com/to/Kmj3UA',
};

export const QUERY = {
    WEB_SHARE: '?appId=Web',
};

export const QUERY_PARAMS = {
    MSISDN: 'msisdn',
    PREFERRED_CP: 'preferredCp',
};

export const KEY_CODES = {
    NUM0: 48,
    NUM1: 49,
    NUM2: 50,
    NUM3: 51,
    NUM4: 52,
    NUM5: 53,
    NUM6: 54,
    NUM7: 55,
    NUM8: 56,
    NUM9: 57,
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAUSE_BREAK: 19,
    CAPS_LOCK: 20,
    ESCAPE: 27,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    INSERT: 45,
    DELETE: 46,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
    LEFT_WINDOW_KEY: 91,
    RIGHT_WINDOW_KEY: 92,
    SELECT_KEY: 93,
    NUMPAD_0: 96,
    NUMPAD_1: 97,
    NUMPAD_2: 98,
    NUMPAD_3: 99,
    NUMPAD_4: 100,
    NUMPAD_5: 101,
    NUMPAD_6: 102,
    NUMPAD_7: 103,
    NUMPAD_8: 104,
    NUMPAD_9: 105,
    MULTIPLY: 106,
    ADD: 107,
    SUBTRACT: 109,
    DECIMAL_POINT: 110,
    DIVIDE: 111,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    NUM_LOCK: 144,
    SCROLL_LOCK: 145,
    SEMI_COLON: 186,
    EQUAL_SIGN: 187,
    COMMA: 188,
    DASH: 189,
    PERIOD: 190,
    FORWARD_SLASH: 191,
    GRAVE_ACCENT: 192,
    OPEN_BRACKET: 219,
    BACK_SLASH: 220,
    CLOSE_BRAKET: 221,
    SINGLE_QUOTE: 222,
    SPACE: 32,
};

export const TITLE_SITE_LOGO = 'site-logo';

export const INVALID_URL = 'invalid url';


export const DEFAULT_STRING = {
    SERVER_FAILED_MESSAGE: 'Oops! Something went wrong.',
    NETWORK_FAILED_MESSAGE: 'Please check your network connection',
    BUTTON_RETRY: 'RETRY',
};

export const SCREEN_ORENTATIONS = {
    LANDSCAPE: 'landscape-primary',
    LANDSCAPE_UPSIDE_DOWN: 'landscape-secondary',
    PORTRAIT: 'portrait-primary',
    PORTRAIT_UPSIDE_DOWN: 'portrait-secondary',
    NATURAL: 'natural',
};

export const APP_CONFIGS = {
    TOASTR_TIMEOUT: 2000,
};

export const UI_CONFIGS = {
    MIN_WIDTH_ARTIST_DESCRIPTION: 50,
};

export const LIST_TYPE = {
    ARTIST: 'ARTIST CONTENT',
    RELATED_CONTENT: 'RELATED CONTENT',
};

export const SHARABLE_CONTENT = {
    FB_LINK: 'https://www.facebook.com/sharer/sharer.php',
    TWITTER_LINK: 'https://twitter.com/intent/tweet',
    WHATSAPP_LINK: 'whatsapp://send',
    CONTENT_RIGHTS_DEEP_LINK: 'https://open.airtelxstream.in/0AtgV6C8bgb',
    DEEP_LINK: 'https://open.airtelxstream.in/glkUS8Y7bgb',
    DOWNLOAD_APP_DEEP_LINK: 'https://open.airtelxstream.in/rw2mrrp8bgb',
};

export const DOWNLOAD_APP_ACTIONS = {
    COMMON_APP_DOWNLOAD: 'COMMON_APP_DOWNLOAD',
    DOWNLOAD_APP_STORE: 'DOWNLOAD_APP_STORE',
    DOWNLOAD_PLAY_STORE: 'DOWNLOAD_PLAY_STORE',
    APP_HOOK_INSTALL: 'Install',
};

export const LIST_TYPE_CLASS = {
    ARTIST: 'cards-portrait--grid-small',
    RELATED_CONTENT: 'cards-portrait--grid-large',
};

export const MAILING_INFORMATIONS = {
    SUPPORT_MAIL_ID: 'support@airtel.tv',
};


export const TAGS = {
    PREMIUM_TAG: 'ATVPLUS',
    NON_PREMIUM_TAG: 'ATV',
};

export const SETTING_PAGES = {
    ACCOUNT: 'ACCOUNT',
};

export const SEARCH_CONFIG = {
    DEFAULT_COUNT: 30,
    DEFAULT_RAIL_ITEMS_COUNT: 8,
    MAX_RECENT_SEARCH_COUNT: 5,
    SUGGESTION_LIMIT: 10,
    SEARCH_TOTAL_RESULT_LIMIT: 30,
};

export const CONTINUE_WATCHING_CONSTANTS = {
    DELAY_TIME: 180,
    MIN_SYNC_TIME: 300000.0,
};

export const DVR_DURATION = {
    MIN_DURATION_SEC: 300.0,
};

export const SORT_TYPES = {
    A2Z: 'A2Z',
    Z2A: 'Z2A',
    DATE: 'DATE',
};

export const SERVER_SORT_TYPES = {
    IMDB: 'IMDB',
    DATE_DESC: 'DATE_DESC',
    REL_YEAR_DESC: 'REL_YEAR_DESC',
};

export const MOBILE_WIDTH = Number(variables?.mobileWidth?.slice(
    0,
    variables?.mobileWidth?.length - 2,
));
export const MOBILE_LANDSCAPE_HEIGHT = Number(variables?.mediaLandscapeHeight?.slice(
    0,
    variables?.mediaLandscapeHeight?.length - 2,
));
export const MOBILE_LANDSCAPE_WIDTH = Number(variables?.mediaLandscapeWidth?.slice(
    0,
    variables?.mediaLandscapeWidth?.length - 2,
));

export const TABLET_LARGE_WIDTH = Number(variables?.tabletLargeWidth?.slice(
    0,
    variables?.tabletLargeWidth?.length - 2,
));

export const MAX_SEARCH_INPUT_CHARS = 30;
export const MAX_PHONE_LENGTH = 10;
export const MAX_EMAIL_LENGTH = 320;

export const VALUE_TYPES = {
    OBJECT: 'object',
    STRING: 'string',
};

export const COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS = 60;

export const DESCRIPTION_MAX_LENGTH = 10000;

export const CONTINUE_WATCHING_LIMIT = 50;

export const PARTIAL_EPISODE_TILE_LIMIT = 10;

export const APP_TYPE = 'website';

export const SITE_NAME = 'Airtel Xstream (Airtel TV)';

export const APP_ENVIRONMENTS = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    LOCAL: 'local',
    UAT: 'uat',
};

export const REQUEST_METHODS = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
};

export const FOOTER_CONFIG_CATEGORY = {
    LIVE_TV_CHANNELS: 'Live TV Channels',
};

export const INTERSECTION_OBSERVER_THRESHOLD = {
    NOT_LOADED: [
        0,
    ],
    FULLY_LOADED: [
        1,
    ],
    ON_ENTRY_AND_EXIT: [
        0,
        1,
    ],
};

export const PAGE_TYPE = {
    LIST: 'LIST',
};

export const CHAR_LIMIT = {
    DESKTOP_TAGLINE_LIMIT: 76,
    MOBILE_TAGLINE_LIMIT: 42,
};


export const BREADCRUMBS_NAME = {
    HOME: 'Home',
    LIVE_TV: 'Live TV',
    TV_SHOWS: 'TV Shows',
    MOVIES: 'Movies',
    VIDEO: 'Videos',
    ARTISTS: 'Artists',
    LAYOUT: 'Layout',
    DIMENSION_PAGES: 'Dimension Pages',
    SCHEDULE_PAGE: 'Schedule',
    CHANNEL: 'Channel',
};

export const BREADCRUMBS_MAP = {
    HOME: BREADCRUMBS_NAME.HOME,
    LIVE_TV: BREADCRUMBS_NAME.LIVE_TV,
    TV_SHOWS: BREADCRUMBS_NAME.TV_SHOWS,
    [PROGRAM_TYPES.TVSHOW]: BREADCRUMBS_NAME.TV_SHOWS,
    MOVIES: BREADCRUMBS_NAME.MOVIES,
    [PROGRAM_TYPES.LIVETVCHANNEL]: BREADCRUMBS_NAME.LIVE_TV,
    [PROGRAM_TYPES.MOVIE]: BREADCRUMBS_NAME.MOVIES,
    VIDEO: BREADCRUMBS_NAME.VIDEO,
    ARTISTS: BREADCRUMBS_NAME.ARTISTS,
    CHANNEL: BREADCRUMBS_NAME.CHANNEL,
};

export const BREADCRUMBS_LINKS = {
    [BREADCRUMBS_NAME.HOME]: '/',
    [BREADCRUMBS_NAME.TV_SHOWS]: '/tv-shows',
    [BREADCRUMBS_NAME.MOVIES]: '/movies',
    [BREADCRUMBS_NAME.LIVE_TV]: '/livetv-channels',
    [BREADCRUMBS_NAME.VIDEO]: '/video',
    [BREADCRUMBS_NAME.ARTISTS]: '/',
    [BREADCRUMBS_NAME.CHANNEL]: '/channel',
};

export const CHANNEL_SCHEDULE_TIME_GAP = {
    DAILY: 4,
};

export const CHANNEL_SCHEDULE_TILE_SIZE = {
    DESKTOP: 416,
    MOBILE_MEDIUM: 324,
};

export const CHANNEL_PARTNER_PLAYER_WIDGET_TYPE = {
    GIF: 'GIF',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
};

export const RESIZE_OPTION = {
    MOBILE: 'mobile',
    TAB_LARGE: 'tab-large',
};

export const SETTINGS_NAVIGATION_MENU_COMPONENT_NAME = {
    ACCOUNT_INFO_COMPONENT: 'AccountInfoComponent',
    MANAGE_PLANS_COMPONENT: 'ManagePlansComponent',
    LANGUAGE_SETTINGS_COMPONENT: 'LanguageSettingsContainer',
    HELP_SUPPORT_COMPONENT: 'HelpAndSupportComponent',
};

export const SETTINGS_LOGIN_LOGOUT_COMPONENT = 'SettingsLoginLogoutScreen';

export const FALLBACK_THEME = {
    brandColor: '#D8272C',
    cardColor: '#343434',
    cardColorRGB: 'rgb(52,52,52)',
    gradientEndColor: '#1F1F1F',
    gradientStartColor: '#343434',
    navIconColor: '#FFFFFF',
    statusBarStyle: 'dark',
    subtitleAlpha: '1',
    textColor: '#FFFFFF',
};

export const DEFAULT_GENRE = {
    ALL_CHANNEL: 'all_channel',
};

export const MODAL_POPUP = {
    PREFERRED_PARTNER_MODAL: 'PreferredPartnerModal',
    PREFERRED_PARTNER_GRATIFICATION: 'PreferredPartnerGratification',
    PREFERRED_PARTNER_SUBSCRIPTION: 'PreferredPartnerSubscription',
    AGE_CLASSIFICATION_POPUP: 'AgeClassificationPopup',
    PLAN_PURCHASE_SUBSCRIPTION_POPUP: 'PlanPurchaseSubscriptionPopup',
    PLAN_PURCHASE_INPROGRESS_POPUP: 'InProgressSubscriptionPopup',
    PLAN_PURCHASE_PAYMENT_UPDATE_POPUP: 'PlanPurchaseNotificationPopup',
    AIRTEL_CONNECTION_POPUP: 'AirtelConnectionPopup',
    RECHARGE_PLAN_DETAIL: 'RechargePlanDetail',
    NON_AIRTEL_USER_POPUP: 'NonAirtelUserPopup',
    ABOUT_CONTENT_POPUP: 'AboutContentPopup',
    ADD_TO_BILL_POPUP: 'AddToBillPopup',
    XPP_SINGLE_CLAIM_MODAL: 'xppSingleClickClaim',
    XPP_CLAIM_MODAL: 'xppClaimOnThanks',
    XPP_PURCHASE_POP_UP_ID: 'FMFSIPopUp',
    XPP_FREE_TRIAL_POP_UP_ID: 'FMFSIPopUp',
    CONCURRENT_PLAY_LIMIT_REACHED: 'ConcurrentPlayLimitReached',
    DEVICE_LIMIT_REACHED: 'DeviceLimitReached',
    WAITING_POPUP: 'WAITING_POPUP',
    RECHARGES_XPP_INFO_POPUP: 'RECHARGES_XPP_INFO_POPUP',
    STATUS_POPUP: 'STATUS_POPUP',
};

const branchKey = 'key_live_kc5hbJYeDTvc99K81cvQrfbkzrbvQtEW';
const encodedBranchKey = () => {
    const binaryBuffer = Buffer.from(branchKey, 'utf8');
    const encryptedBranchKey = binaryBuffer.toString('base64');
    return encryptedBranchKey;
};

export const WEB_RETURN_KEYCODE = 8;

export const TIZEN_KEYCODES = {
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    OK_BUTTON: 13,
    RETURN_BUTTON: 10009,
    MEDIA_STOP_BUTTON: 413,
    MEDIA_PLAY_BUTTON: 415,
    MEDIA_PAUSE_BUTTON: 19,
    MEDIA_REWIND_BUTTON: 412,
    MEDIA_FORWARD_BUTTON: 417,
    EXIT: 10182,
    NUM0: 48,
    NUM1: 49,
    NUM2: 50,
    NUM3: 51,
    NUM4: 52,
    NUM5: 53,
    NUM6: 54,
    NUM7: 55,
    NUM8: 56,
    NUM9: 57,
};
export const WEBOS_KEYCODES = {
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    OK_BUTTON: 13,
    RETURN_BUTTON: 461,
    CURSOR_SHOW: 1536,
    CURSOR_HIDE: 1537,
};

export const PUBLISH_EVENTS = {
    LEFT_ARROW: 'LEFT_ARROW',
    UP_ARROW: 'UP_ARROW',
    RIGHT_ARROW: 'RIGHT_ARROW',
    DOWN_ARROW: 'DOWN_ARROW',
    OK_BUTTON: 'OK_BUTTON',
    RETURN_BUTTON: 'RETURN_BUTTON',
    MEDIA_STOP_BUTTON: 'MEDIA_STOP_BUTTON',
    MEDIA_PLAY_BUTTON: 'MEDIA_PLAY_BUTTON',
    MEDIA_PAUSE_BUTTON: 'MEDIA_PAUSE_BUTTON',
    MEDIA_REWIND_BUTTON: 'MEDIA_REWIND_BUTTON',
    MEDIA_FORWARD_BUTTON: 'MEDIA_FORWARD_BUTTON',
    SKIP_AD: 'SKIP_AD',
    TOGGLE_PLAYBACK: 'TOGGLE_PLAYBACK',
    CURSOR_SHOW: 'CURSOR_SHOW',
    CURSOR_HIDE: 'CURSOR_HIDE',
    SET_FOCUS_ELEMENT: 'SET_FOCUS_ELEMENT',
    NUM_KEY_PRESSED: 'NUM_KEY_PRESSED',
};

export const LOCALOVERRIDEPOLLINGLISTS = {
    QrCodePolling: 30000,
    regularPolling: 600000,
    playbackPagePolling: -1,
};
export const QR_CODE_POLLING = 'QrCodePolling';

export default {
    REQUEST_METHODS,
    APP_ENVIRONMENTS,
    APP_TYPE,
    SITE_NAME,
    CONTINUE_WATCHING_LIMIT,
    PARTIAL_EPISODE_TILE_LIMIT,
    COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS,
    DOWNLOAD_APP_ACTIONS,
    LANGUAGES,
    LOCAL_STORE_KEYS,
    ROTE_TYPES: ROLE_TYPES,
    LANGUAGES_CODES,
    ACTION_MODALS_TYPES,
    INTRODUCTION_MODALS_TYPES,
    REF_LINKS,
    QUERY_PARAMS,
    KEY_CODES,
    LOCALOVERRIDEPOLLINGLISTS,
    DEFAULT_STRING,
    CACHE_CONFIGS,
    SCREEN_ORENTATIONS,
    APP_CONFIGS,
    UI_CONFIGS,
    PROGRAM_TYPES,
    LIST_TYPE,
    LIST_TYPE_CLASS,
    MAILING_INFORMATIONS,
    TAGS,
    SETTING_PAGES,
    SEARCH_CONFIG,
    SORT_TYPES,
    SERVER_SORT_TYPES,
    MOBILE_WIDTH,
    MAX_SEARCH_INPUT_CHARS,
    MAX_PHONE_LENGTH,
    VALUE_TYPES,
    PROGRAM_TYPE_TITLE_MAPPING,
    PROGRAM_TYPES_CATEGORY,
    PROGRAM_TYPE_CATGEGORY_MAP,
    FOOTER_CONFIG_CATEGORY,
    DIMENSION_PAGE_MAP,
    DIMENSION_PAGE_CONTENT_LIMIT,
    DIMENSION_SORT_OPTIONS,
    DIMENSION_SORT_DISPLAY,
    DIMENSION_SORT_API_OPTION,
    DROP_DOWN_LIMIT,
    TITLE_SITE_LOGO,
    INVALID_URL,
    PAGE_TYPE,
    DROPDOWN_NAMES_LIMIT,
    SCREEN_SIZES,
    DROPDOWN_CONTENT_NAME_LIMIT,
    BREADCRUMBS_NAME,
    BREADCRUMBS_MAP,
    BREADCRUMBS_LINKS,
    DIMENSIONS_FOR_CALL_MAP,
    CHANNEL_SCHEDULE_TIME_GAP,
    CHANNEL_SCHEDULE_TILE_SIZE,
    CHANNEL_PARTNER_PLAYER_WIDGET_TYPE,
    RESIZE_OPTION,
    CP_DESCRIPTION_LIMIT,
    DIMENSION_URL_TO_TYPE,
    FALLBACK_THEME,
    CHAR_LIMIT,
    MAX_LANGUAGE_SELECTION_LIMIT,
    LANGUAGE_CONTAINER_TYPE,
    SETTINGS_NAVIGATION_MENU_COMPONENT_NAME,
    SETTINGS_LOGIN_LOGOUT_COMPONENT,
    encodedBranchKey,
    LOCAL_STORE_KEYS,
};

export const QUICK_VIEW_CONTENT_BANNER = 'tile-content-banner';
export const QUICK_VIEW_BANNER_IMG = 'quick-view-banner-img';
export const QUICK_VIEW_FULL_IMAGE_CONTAINER = 'quick-view-full-image-container';

export const PAYMENT_MODES = {
    ADD_TO_BILL: 'add_to_bill',
    QR_CODE: 'qr_code',
    SMS_LINK: 'sms_link',
};

export const LOGIN_MODE = {
    OTP_LOGIN: 'otp_login',
    QR_LOGIN: 'qr_login',
};

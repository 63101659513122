export const CONTENT_PROVIDERS = {
    ALT_BALAJI: 'ALTBALAJI',
    HOICHOI: 'HOICHOI',
    HOOQ: 'HOOQ',
    ZEE5: 'ZEE5',
    EUROS: 'EROSNOW',
    LIVETV: 'LIVETV',
    FAST_FILMS: 'FASTFILMZ',
    MWTV: 'MWTV',
    HUNGAMA: 'HUNGAMA',
    SHEMAROOME: 'SHEMAROOME',
    CURIOSITYSTREAM: 'CURIOSITYSTREAM',
    ULTRA: 'ULTRA',
    LIONSGATEPLAY: 'LIONSGATEPLAY',
    DEVILS_CIRCUIT: 'DEVILS_CIRCUIT',
    NODWIN: 'NODWIN',
    VOOT: 'VOOT',
    MUBI: 'MUBI',
};

export const DEFAULT_CHANNEL_PRODUCT_ID = '200292';

export const CP_CONFIG = {
    
};

export default {
    CONTENT_PROVIDERS,
    DEFAULT_CHANNEL_PRODUCT_ID,
    CP_CONFIG,
};

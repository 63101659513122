import { PROGRAM_TYPE_TITLE_MAPPING } from '../constants/AppConst';
import { encodeForUrl } from './WindowUtil';
import { ROUTE_PATHS, DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { trimCaptionsForUrl } from './CommonUtil';
import { LISTING_PAGE_ID_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { DateTime } from '@airtel-tv/utils/DateTime';
import getAllRouters from '../routes/routes';
import { matchRoutes } from 'react-router';

export default class RoutingUtil {

    static STATUS_CODES = {
        PERMANENT_REDIRECT: 301,
        TEMPORARY_REDIRECT: 302
    }
    static get404Route() {
        return '/404';
    }

    static getPreferredPartnerRoute() {
        return '/select/partner';
    }

    static getXmpClaimRoute() {
        return ROUTE_PATHS.XMP_CLAIM_PAGE
    }

    static getSearchListingUrl({
        category, genre, lang, title, sortBy, query,
    }) {
        const trimmedTitle = trimCaptionsForUrl(title);

        const queryParams = LocationUtil.objectToQueryParams({
            category: (category || '').toLowerCase(),
            genre,
            lang,
            sortBy,
            query,
        });

        return `/search/list/${encodeForUrl(trimmedTitle)}?${queryParams}`;
    }

    static getSearchResultUrl({ title, query }) {
        const trimmedTitle = encodeForUrl(trimCaptionsForUrl(title));

        const queryParams = LocationUtil.objectToQueryParams({
            q: query,
        });

        return `/search/${trimmedTitle}?${queryParams}`;
    }

    static getPackageListingUrl({
        title, pageUrlName, railId, packageId,
    }) {
        const pageTitle = pageUrlName || DEFAULT_NAV.HOME;
        const trimmedTitle = trimCaptionsForUrl(title);

        if (railId) {
            return `/${pageTitle}/${trimmedTitle}/list/${railId}`;
        }
        if (packageId) {
            return `/${pageTitle}/${trimmedTitle}/list/${packageId}?type=${LISTING_PAGE_ID_TYPES.PACKAGE}`;
        }

        return null;
    }

    static getPackageListingUrlV2({
        title, railId, packageId,
        titleL2 = '',
    }) {
        const trimmedTitle = trimCaptionsForUrl(title);
        if (railId && !titleL2) {
            return `/${trimmedTitle}/list/${railId}`;
        }
        if (packageId) {
            return `/${trimmedTitle}/list/${packageId}?type=${LISTING_PAGE_ID_TYPES.PACKAGE}${titleL2? '&titleL2=y': ''}`;
        }

        return null;
    }

    static getSettingsURL({ settingPage }) {
        return `/settings/${settingPage}`;
    }

    static getHomePage() {
        return '/';
    }

    static getXmpClaim() {
        return '/xmp-claim';
    }

    static getChannelPage() {
        return '/channel';
    }

    static getSubscriptionPage() {
        return ROUTE_PATHS?.SUBSCRIPTION_PAGE || '';
    }

    static getSingleClaimSuccessPage() {
        return ROUTE_PATHS.SINGLE_CLAIM_SUCCESS_PAGE;
    }

    static getMultipleClaimSuccessPage() {
        return ROUTE_PATHS.MULTIPLE_CLAIM_SUCCESS_PAGE;
    }

    static getSubscriptionSuccessPage() {
        return ROUTE_PATHS.SUBSCRIPTION_SUCCESS;
    }

    static getSubscriptionFailurePage() {
        return ROUTE_PATHS.SUBSCRIPTION_FAILURE;
    }

    static getUserPlanPage() {
        return ROUTE_PATHS.MY_PLANS;
    }

    static getContentPlaybackUrl({ category = 'VIDEO', contentTitle = 'unknown', contentId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;

        const trimmedCategory = trimCaptionsForUrl(pageTitle);

        const trimmedTitle = trimCaptionsForUrl(contentTitle);

        const pathParams = [
            trimmedCategory,
            trimmedTitle,
            contentId,
            // encodeContentId(contentId),
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getArtistUrl({ name = 'unknown', id }) {
        const trimmedName = trimCaptionsForUrl(name);
        return `/artist/${trimmedName}/${id}`;
    }

    static getCustomLayoutUrl({ urlName }) {
        const nav = urlName === DEFAULT_NAV.HOME ? '' : urlName;
        return `/${nav}`;
    }

    static getTvEpisodeUrl({
        programType, title, episodeNumber, episodeName, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle),
            trimCaptionsForUrl(title),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            trimCaptionsForUrl(episodeNumber ? `episode-${episodeNumber}` : '') + (episodeName ? `-${trimCaptionsForUrl(episodeName)}` : ''),
            contentId,
            // encodeContentId(contentId),
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getTvSeasonUrl({
        programType, title, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(title || ''),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            contentId,
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getLiveTvShowUrl({ channelName, showName, contentId }) {
        // backend sending ProgramType : EPISODE instead of LIVETVEPISODE : with regrets, have to hard code
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(channelName || ''),
            trimCaptionsForUrl(showName ? `${showName}` : ''),
            contentId,
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getLiveEpisodeShowUrl({
        channelName,
        showName,
        episodeReleaseDT,
        episodeNumber,
        contentId,
        tvShowId,
    }) {
        // backend sending ProgramType : EPISODE instead of LIVETVEPISODE : with regrets, have to hard code
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;

        let episodeTitle = '';

        if (episodeReleaseDT) {
            episodeTitle = new DateTime(episodeReleaseDT).format('DD-MMMM-YYYY');
        }

        if (episodeNumber) {
            episodeTitle = `${episodeTitle}-episode-${episodeNumber}`;
        }

        const pathParams = [
            (pageTitle),
            trimCaptionsForUrl(channelName || ''),
            trimCaptionsForUrl(showName ? `${showName}` : 'default'),
            trimCaptionsForUrl(episodeTitle || ''),
            tvShowId,
            contentId,
        ];
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getMovieCatchupPlaybackUrl({
        category = 'VIDEO', contentTitle = 'unknown', contentId, channelName,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;
        const trimmedCategory = trimCaptionsForUrl(pageTitle || '');
        const trimmedTitle = trimCaptionsForUrl(contentTitle || '');
        const trimmedChannelName = trimCaptionsForUrl(channelName || '');

        const pathParams = [
            trimmedCategory,
            trimmedChannelName,
            trimmedTitle,
            contentId,
        ];


        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getDimensionPageUrl({
        pageTitle = 'movies',
        lang = '',
        genres = '',
        queryParams = {},
    }) {
        const trimmedPageTitle = trimCaptionsForUrl(pageTitle || '');

        const trimmedLang = lang && lang.length > 0 ? trimCaptionsForUrl(lang) : '';

        const trimmedGenres = genres && genres.length > 0 ? trimCaptionsForUrl(genres) : '';

        const pathParams = [
            trimmedPageTitle,
        ];
        if (trimmedLang) {
            pathParams.push(trimmedLang);
        }
        if (trimmedGenres) {
            pathParams.push(trimmedGenres);
        }
        pathParams.push('collection');
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getDimensionPageUrlV2({
        pageTitle = 'movies',
        lang = '',
        genres = '',
        queryParams = {},
    }) {
        const trimmedPageTitle = trimCaptionsForUrl(pageTitle || '');

        const trimmedLang = lang && lang.length > 0 ? trimCaptionsForUrl(lang) : '';

        const trimmedGenres = genres && genres.length > 0 ? trimCaptionsForUrl(genres) : '';

        const pathParams = [
            trimmedPageTitle,
        ];
        let categoryArr = []
        if (trimmedLang) {
            categoryArr.push(trimmedLang);
        }
        if (trimmedGenres) {
            categoryArr.push(trimmedGenres);
        }
        if(categoryArr.length) {
            categoryArr.push(trimmedPageTitle);
            categoryArr = categoryArr.join('-');
        } else {
            categoryArr.push('collection');
        }
        pathParams.push(categoryArr);
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getSchedulePageUrl({ channelName = 'unknown', channelId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;
        const trimmedTitle = trimCaptionsForUrl(channelName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            'schedule',
            channelId,
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getChannelPartnerPageUrl({ channelPartnerName = 'unknown', pageLink }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.CHANNEL_PARTNER_PAGE;
        const trimmedTitle = trimCaptionsForUrl(channelPartnerName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            pageLink,
            'detail',
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getEventsPageUrl({ eventName, eventId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.PROMOTION_PAGE;
        const trimmedTitle = trimCaptionsForUrl(eventName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            eventId,
            'detail',
        ];
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getXstreamPremiumPage({ xstreamUrl = '', viewType = 'default' }) {
        return `/${xstreamUrl}?viewType=${viewType}`;
    }

    static getContentDetailsLandingPage({ cdpUrl = '', viewType = 'default' }) {
        return `/${cdpUrl}?viewType=${viewType}`;
    }

    static transformListingUrl(match) {
        const { title, id } = match.params;
        return `/${title}/list/${id}`;
    }

    static isCdpRoute(pathname) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        let isLinkToCdp = (matchedRoutes || []).find(r => {
            return r?.route?.isCDP ? true: false;
        })
        return isLinkToCdp;
    }
}

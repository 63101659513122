

export const THEME_COLORS = {
    WIDGET_COLOR_DESKTOP: 'linear-gradient(180deg, {brandColor} 0%, #1f1f1f 100%)',
    WIDGET_COLOR_POPUP: 'linear-gradient(180deg, {brandColor} 0%, #191919 100%)',
    WDIGET_COLOR_MOBILE: 'linear-gradient(180deg, {brandColor} 0%, #191919 25%)',
    PREFERRED_PARTNER_TILE: 'linear-gradient(91.23deg, {gradientStart} 5.24%,  {gradientEnd} 102.67%)',
};

export const THEME_OPACITY = {
    WIDGET_GRADIENT: 0.2,
};

export const DEFAULT_THEME = {
    BRAND_COLOR_THEME: 'rgb(52, 52, 52)',
};

export default {
    THEME_COLORS,
    THEME_OPACITY,
    DEFAULT_THEME,
};

export const EVENT_SOURCE_NAME = {
    CONTENT_DETAIL_PAGE: 'content_detail_page',
    DETAIL_PAGE: 'detail_page',
    LISTING_PAGE: 'listing_page',
    LANGUAGE_PAGE: 'language_page',
    SEARCH_PAGE: 'search_page',
    LAYOUT_PAGE: 'layout_page',
    MORE_PAGE: 'more_page',
    ROOT: 'root',
    NOT_FOUND: '404',
    PEOPLE_PAGE: 'people_page',
    SEARCH_RESULT: 'search_result',
    UID: 'uid',
    MSISDN_DETECTED: 'msisdnDetected',
    MSISDN: 'msisdn',
    DTH_RECHARGE: 'dth',
    SCHEDULE_PAGE: 'schedule_page',
    PROMOTION_PAGE: 'PROMOTION_PAGE',
    HAPPYCODE_PAGE: 'happycode_page',
    PREFERRED_PARTNER: 'preferred_pc_page',
};

export const LOGIN_SOURCE = {
    WATCHLIST_BUTTON: 'watchlist_button',
    WATCHLIST_PAGE: 'watchlist_page',
    PLAY_CLICK: 'play_button',
    SIGN_IN_BUTTON: 'sign_in_button',
    DTH_LOGIN: 'dth_login',
    SUBSCRIBE_BUTTON: 'subscribe_button',
    PREFERRED_PARTNER_PAGE: 'preferred_partner_page',
    SUBSCTIPTION_DEEPLINK: 'subscription_deeplink',
    TRAILER_XSTREAM_SUBSCRIPTION: 'trailer_xstream_subscription',
    OFFERS_OTP: 'offers_OTP',
    DEFAULT_LOGIN: 'default_login',
};

export const SUBSCRIPTION_SOURCE = {
    PLAYER: 'player',
    TILE: 'tile',
    CHANNEL_PARTNER_PAGE: 'channel_partner_page',
    EXPLORE_PLANS_PAGE: 'explore_plans_page',
    CONTENT_DETAIL_PAGE: 'content_detail_page',
    SINGLE_CLAIM_MODAL_SCREEN: 'single_claim_modal_screen',
    HOME_PAGE: 'home_page',
    CONTENT_DETAIL_PAGE_XMP_MODAL: 'content_detail_page_xmp_modal',
    PLANS_AND_OFFER_PAGE: 'plans_and_offer_page',
    PLAN_DETAIL_PAGE: 'plan_detail_page',
    PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE: 'preffered_partner_channel_selection_page',
    TRAILER_PLAY: 'trailer_play'
};
export default {
    EVENT_SOURCE_NAME,
    SUBSCRIPTION_SOURCE,
};

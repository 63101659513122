export const HTML_IDS = {
    NAVBAR_APP_INSTALL: 'nav-app-install',
    NAVBAR_LANGUAGE: 'nav-Language',
    NAVBAR_LOGOUT: 'nav-LogOut',
    NAVBAR_SEARCH: 'nav-Search',
    NAVBAR_SIGN_IN: 'nav-SignIn',
    NAVBAR_WATCHLIST: 'nav-Watchlist',
    NAVBAR_HELP_AND_SUPPORT: 'nav-Help-and-Support',
    NAVBAR_FEEDBACK: 'nav-feedback',
    NAVBAR_PLANS_AND_OFFER: 'nav-Plan-And-Offer',
    OFFERS: 'nav-Offers',
};

export const POPULAR = 'Popular';

export default {
    HTML_IDS,
};

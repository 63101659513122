export const EVENT_SOURCE_NAME = {
    CONTENT_DETAIL_PAGE: 'content_detail_page',
    DETAIL_PAGE: 'detail_page',
    LISTING_PAGE: 'listing_page',
    LANGUAGE_PAGE: 'language_page',
    SEARCH_PAGE: 'search_page',
    LAYOUT_PAGE: 'layout_page',
    MORE_PAGE: 'more_page',
    ROOT: 'root',
    NOT_FOUND: '404',
    PEOPLE_PAGE: 'people_page',
    SEARCH_RESULT: 'search_result',
    UID: 'uid',
    MSISDN_DETECTED: 'msisdnDetected',
    MSISDN: 'msisdn',
    DTH_RECHARGE: 'dth',
    SCHEDULE_PAGE: 'schedule_page',
    PROMOTION_PAGE: 'PROMOTION_PAGE',
    HAPPYCODE_PAGE: 'happycode_page',
    PREFERRED_PARTNER: 'preferred_pc_page',
    SPLASH_SCREEN: 'splash_screen',
    LOGIN_PAGE: 'login_page',
    OTP_SCREEN: 'otp_screen',
    MSISDN_INPUT_SCREEN: 'msisdn_input_screen',
};

export const LOGIN_SOURCE = {
    WATCHLIST_BUTTON: 'watchlist_button',
    WATCHLIST_PAGE: 'watchlist_page',
    PLAY_CLICK: 'play_button',
    SIGN_IN_BUTTON: 'sign_in_button',
    DTH_LOGIN: 'dth_login',
    SUBSCRIBE_BUTTON: 'subscribe_button',
    PREFERRED_PARTNER_PAGE: 'preferred_partner_page',
    MANAGE_PLANS_SETTINGS_PAGE: 'manage_plans_settings_page',
};

export const SUBSCRIPTION_SOURCE = {
    PLAYER: 'player',
    TILE: 'tile',
    CHANNEL_PARTNER_PAGE: 'channel_detail_page',
    CONTENT_DETAIL_PAGE: 'content_detail_page',
};
export default {
    EVENT_SOURCE_NAME,
    SUBSCRIPTION_SOURCE,
};

/**
 * @param {Array} subjectList - list of strings to filter out
 * @param {Array} prefixList - list of prefixes to match
 */
export const filterStringWithPrefix = (subjectList, prefixList) => {
    if (!subjectList || !prefixList) {
        return subjectList;
    }
    const listOfStrings = Array.isArray(subjectList)
        ? subjectList : Array(subjectList);
    const listOfPrefix = Array.isArray(prefixList)
        ? prefixList : Array(prefixList);

    const filteredList = [];
    listOfStrings.forEach((str) => {
        function comparePrefix(prefix) {
            if (prefix.length > str.length) {
                return false;
            }
            if (String.prototype.substr.call(str, 0, prefix.length) === prefix) {
                filteredList.push(str);
                return true;
            }
            return false;
        }
        listOfPrefix.some(comparePrefix);
    });
    return filteredList;
};

export default {
    filterStringWithPrefix,
};

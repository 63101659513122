export const NETWORK_TYPE = {
    DEFAULT: '1',
    CELLULAR: '0',
    WIFI: '1',
};

export const NETWORK_QUALITY = {
    AWFUL: '0',
    INDIAN_POOR: '1',
    POOR: '2',
    MODERATE: '3',
    GOOD: '4',
    EXCELLENT: '5',
};

export const MAX_INT = 2147483647;

/* eslint-disable no-undef */
import reactEnv from '../config';

const initializeBranch = () => {
    const { branchKey } = reactEnv;
    if (typeof window !== 'undefined') {
        // load Branch
        const script = document.createElement('script');
        script.text = '!function (n) { "use strict"; n.loadCSS || (n.loadCSS = function () { }); var o = loadCSS.relpreload = {}; if (o.support = function () { var e; try { e = n.document.createElement("link").relList.supports("preload") } catch (t) { e = !1 } return function () { return e } }(), o.bindMediaToggle = function (t) { var e = t.media || "all"; function a() { t.addEventListener ? t.removeEventListener("load", a) : t.attachEvent && t.detachEvent("onload", a), t.setAttribute("onload", null), t.media = e } t.addEventListener ? t.addEventListener("load", a) : t.attachEvent && t.attachEvent("onload", a), setTimeout(function () { t.rel = "stylesheet", t.media = "only x" }), setTimeout(a, 3e3) }, o.poly = function () { if (!o.support()) for (var t = n.document.getElementsByTagName("link"), e = 0; e < t.length; e++) { var a = t[e]; "preload" !== a.rel || "style" !== a.getAttribute("as") || a.getAttribute("data-loadcss") || (a.setAttribute("data-loadcss", !0), o.bindMediaToggle(a)) } }, !o.support()) { o.poly(); var t = n.setInterval(o.poly, 500); n.addEventListener ? n.addEventListener("load", function () { o.poly(), n.clearInterval(t) }) : n.attachEvent && n.attachEvent("onload", function () { o.poly(), n.clearInterval(t) }) } "undefined" != typeof exports ? exports.loadCSS = loadCSS : n.loadCSS = loadCSS }("undefined" != typeof global ? global : this);'
     + ' (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);';
        window.document.body.appendChild(script);
    }
    if (branchKey && window.branch && branch.init) {
        // let binaryBuffer = Buffer.from(branchKey, 'utf8');
        // const encryptedBranchKey = binaryBuffer.toString('base64');
        // binaryBuffer = Buffer.from(encryptedBranchKey, 'base64');
        // branchKey = binaryBuffer.toString('utf8');

        branch.init(branchKey, (err, data) => {
            if (err) {
                console.error('error while integrating branch', err);
            }
        });
    }
};

const setBranchIdentity = (uid) => {
    if (window.branch && branch.setIdentity) {
        branch.setIdentity(uid);
    }
};

const logoutBranch = () => {
    if (window.branch && branch.logout) {
        branch.logout();
    }
};

const sendCustomDataToBranch = (EventName, EventMeta) => {
    if (window.branch && branch.logEvent) {
        branch.logEvent(EventName, EventMeta);
    }
};

export default {
    initializeBranch,
    setBranchIdentity,
    logoutBranch,
    sendCustomDataToBranch,
};
